import { colors } from '@/styles/colors'
import { css } from '@emotion/react'
import CircularProgress from '@mui/material/CircularProgress'

type LoadingType = {
  size: number | string
  thickness: number
}

export default function Loading({ size, thickness, ...props }: LoadingType) {
  return (
    <div css={styles.loadingWrap}>
      <CircularProgress
        variant="determinate"
        sx={{ color: '#E5E5E5' }}
        size={size}
        thickness={thickness}
        value={100}
        {...props}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: colors.COLOR_EA6077,
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
        }}
        size={size}
        thickness={thickness}
        {...props}
      />
    </div>
  )
}

const styles = {
  loadingWrap: css`
    position: relative;
    display: flex;
    height: fit-content;
  `,
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CoupleAnalyticsDataResponse } from './couple-analytics-data-response'

/**
 *
 * @export
 * @interface GetPartyToUpdateHoldDateResponse
 */
export interface GetPartyToUpdateHoldDateResponse {
  /**
   *
   * @type {string}
   * @memberof GetPartyToUpdateHoldDateResponse
   */
  holdDate: string
  /**
   *
   * @type {string}
   * @memberof GetPartyToUpdateHoldDateResponse
   */
  clientName: string
  /**
   *
   * @type {string}
   * @memberof GetPartyToUpdateHoldDateResponse
   */
  availabilityToUpdateHoldDate: GetPartyToUpdateHoldDateResponseAvailabilityToUpdateHoldDate
  /**
   *
   * @type {CoupleAnalyticsDataResponse}
   * @memberof GetPartyToUpdateHoldDateResponse
   */
  coupleAnalyticsDataResponse: CoupleAnalyticsDataResponse
}

/**
 * @export
 * @enum {string}
 */
export enum GetPartyToUpdateHoldDateResponseAvailabilityToUpdateHoldDate {
  updatableNoSideEffects = 'UPDATABLE_NO_SIDE_EFFECTS',
  updatableWithSideEffectOfSuspendingInvitation = 'UPDATABLE_WITH_SIDE_EFFECT_OF_SUSPENDING_INVITATION',
  notUpdatableShouldCancelGuestPayment = 'NOT_UPDATABLE_SHOULD_CANCEL_GUEST_PAYMENT',
  notUpdatableAlreadyPaymentRequested = 'NOT_UPDATABLE_ALREADY_PAYMENT_REQUESTED',
  notUpdatableAlreadyUpdatedResponseDeadlineByClientAfterResponseDeadline = 'NOT_UPDATABLE_ALREADY_UPDATED_RESPONSE_DEADLINE_BY_CLIENT_AFTER_RESPONSE_DEADLINE',
}

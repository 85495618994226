/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { AddResponseRequest } from '../model'
// @ts-ignore
import { AddResponseResponse } from '../model'
// @ts-ignore
import { CheckIsPasswordNeededRequest } from '../model'
// @ts-ignore
import { CheckIsPasswordNeededResponse } from '../model'
// @ts-ignore
import { GetInvitationDataRequest } from '../model'
// @ts-ignore
import { GetInvitationDataResponse } from '../model'
// @ts-ignore
import { IsSameGuestAlreadyRespondedRequest } from '../model'
// @ts-ignore
import { IsSameGuestAlreadyRespondedResponse } from '../model'
// @ts-ignore
import { ValidateResponseResponse } from '../model'
/**
 * GuestInvitationControllerApi - axios parameter creator
 * @export
 */
export const GuestInvitationControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {AddResponseRequest} addResponseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addResponse: async (
      addResponseRequest: AddResponseRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addResponseRequest' is not null or undefined
      assertParamExists('addResponse', 'addResponseRequest', addResponseRequest)
      const localVarPath = `/guest/api/addResponse`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        addResponseRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CheckIsPasswordNeededRequest} checkIsPasswordNeededRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkIsPasswordNeeded: async (
      checkIsPasswordNeededRequest: CheckIsPasswordNeededRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'checkIsPasswordNeededRequest' is not null or undefined
      assertParamExists(
        'checkIsPasswordNeeded',
        'checkIsPasswordNeededRequest',
        checkIsPasswordNeededRequest,
      )
      const localVarPath = `/guest/api/checkIsPasswordNeeded`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        checkIsPasswordNeededRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetInvitationDataRequest} getInvitationDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvitationData: async (
      getInvitationDataRequest: GetInvitationDataRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getInvitationDataRequest' is not null or undefined
      assertParamExists('getInvitationData', 'getInvitationDataRequest', getInvitationDataRequest)
      const localVarPath = `/guest/api/getInvitationData`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getInvitationDataRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {IsSameGuestAlreadyRespondedRequest} isSameGuestAlreadyRespondedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isSameGuestAlreadyResponded: async (
      isSameGuestAlreadyRespondedRequest: IsSameGuestAlreadyRespondedRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'isSameGuestAlreadyRespondedRequest' is not null or undefined
      assertParamExists(
        'isSameGuestAlreadyResponded',
        'isSameGuestAlreadyRespondedRequest',
        isSameGuestAlreadyRespondedRequest,
      )
      const localVarPath = `/guest/api/isSameGuestAlreadyResponded`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        isSameGuestAlreadyRespondedRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {AddResponseRequest} addResponseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateResponse: async (
      addResponseRequest: AddResponseRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addResponseRequest' is not null or undefined
      assertParamExists('validateResponse', 'addResponseRequest', addResponseRequest)
      const localVarPath = `/guest/api/validateResponse`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        addResponseRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * GuestInvitationControllerApi - functional programming interface
 * @export
 */
export const GuestInvitationControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GuestInvitationControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {AddResponseRequest} addResponseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addResponse(
      addResponseRequest: AddResponseRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddResponseResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addResponse(
        addResponseRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CheckIsPasswordNeededRequest} checkIsPasswordNeededRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkIsPasswordNeeded(
      checkIsPasswordNeededRequest: CheckIsPasswordNeededRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckIsPasswordNeededResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkIsPasswordNeeded(
        checkIsPasswordNeededRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetInvitationDataRequest} getInvitationDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInvitationData(
      getInvitationDataRequest: GetInvitationDataRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInvitationDataResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationData(
        getInvitationDataRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {IsSameGuestAlreadyRespondedRequest} isSameGuestAlreadyRespondedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async isSameGuestAlreadyResponded(
      isSameGuestAlreadyRespondedRequest: IsSameGuestAlreadyRespondedRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IsSameGuestAlreadyRespondedResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.isSameGuestAlreadyResponded(
        isSameGuestAlreadyRespondedRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {AddResponseRequest} addResponseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateResponse(
      addResponseRequest: AddResponseRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateResponseResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validateResponse(
        addResponseRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * GuestInvitationControllerApi - factory interface
 * @export
 */
export const GuestInvitationControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GuestInvitationControllerApiFp(configuration)
  return {
    /**
     *
     * @param {AddResponseRequest} addResponseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addResponse(
      addResponseRequest: AddResponseRequest,
      options?: any,
    ): AxiosPromise<AddResponseResponse> {
      return localVarFp
        .addResponse(addResponseRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CheckIsPasswordNeededRequest} checkIsPasswordNeededRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkIsPasswordNeeded(
      checkIsPasswordNeededRequest: CheckIsPasswordNeededRequest,
      options?: any,
    ): AxiosPromise<CheckIsPasswordNeededResponse> {
      return localVarFp
        .checkIsPasswordNeeded(checkIsPasswordNeededRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetInvitationDataRequest} getInvitationDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvitationData(
      getInvitationDataRequest: GetInvitationDataRequest,
      options?: any,
    ): AxiosPromise<GetInvitationDataResponse> {
      return localVarFp
        .getInvitationData(getInvitationDataRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {IsSameGuestAlreadyRespondedRequest} isSameGuestAlreadyRespondedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isSameGuestAlreadyResponded(
      isSameGuestAlreadyRespondedRequest: IsSameGuestAlreadyRespondedRequest,
      options?: any,
    ): AxiosPromise<IsSameGuestAlreadyRespondedResponse> {
      return localVarFp
        .isSameGuestAlreadyResponded(isSameGuestAlreadyRespondedRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {AddResponseRequest} addResponseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateResponse(
      addResponseRequest: AddResponseRequest,
      options?: any,
    ): AxiosPromise<ValidateResponseResponse> {
      return localVarFp
        .validateResponse(addResponseRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * GuestInvitationControllerApi - object-oriented interface
 * @export
 * @class GuestInvitationControllerApi
 * @extends {BaseAPI}
 */
export class GuestInvitationControllerApi extends BaseAPI {
  /**
   *
   * @param {AddResponseRequest} addResponseRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestInvitationControllerApi
   */
  public addResponse(addResponseRequest: AddResponseRequest, options?: AxiosRequestConfig) {
    return GuestInvitationControllerApiFp(this.configuration)
      .addResponse(addResponseRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CheckIsPasswordNeededRequest} checkIsPasswordNeededRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestInvitationControllerApi
   */
  public checkIsPasswordNeeded(
    checkIsPasswordNeededRequest: CheckIsPasswordNeededRequest,
    options?: AxiosRequestConfig,
  ) {
    return GuestInvitationControllerApiFp(this.configuration)
      .checkIsPasswordNeeded(checkIsPasswordNeededRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetInvitationDataRequest} getInvitationDataRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestInvitationControllerApi
   */
  public getInvitationData(
    getInvitationDataRequest: GetInvitationDataRequest,
    options?: AxiosRequestConfig,
  ) {
    return GuestInvitationControllerApiFp(this.configuration)
      .getInvitationData(getInvitationDataRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {IsSameGuestAlreadyRespondedRequest} isSameGuestAlreadyRespondedRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestInvitationControllerApi
   */
  public isSameGuestAlreadyResponded(
    isSameGuestAlreadyRespondedRequest: IsSameGuestAlreadyRespondedRequest,
    options?: AxiosRequestConfig,
  ) {
    return GuestInvitationControllerApiFp(this.configuration)
      .isSameGuestAlreadyResponded(isSameGuestAlreadyRespondedRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {AddResponseRequest} addResponseRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestInvitationControllerApi
   */
  public validateResponse(addResponseRequest: AddResponseRequest, options?: AxiosRequestConfig) {
    return GuestInvitationControllerApiFp(this.configuration)
      .validateResponse(addResponseRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

import { PageNotFoundErrorHead } from '@/components/common/commonHead'
import { loggingCoupleGuestListUpPageView } from '@/components/common/googleTagManager'
import { ErrorLayout } from '@/components/couple/cs_guest_list_up/ErrorLayout'
import CheckOnlyAuthentication from '@/components/couple/cs_guest_list_up/auth/CheckOnlyAuthentication'
import { styles } from '@/components/cs/errorLayout'
import { useGuestListUpCoupleUserContext } from '@/context/cs_guest_list_up/session'
import { useEffect } from 'react'

export default function PageError() {
  const { user } = useGuestListUpCoupleUserContext()
  useEffect(() => {
    loggingCoupleGuestListUpPageView({
      original_screen_name: 'cs_GL_error_404',
      guestlist_cs_userid: user?.coupleUUID,
    })
  }, [])
  return (
    <ErrorLayout>
      <PageNotFoundErrorHead />
      <h1 css={styles.head}>
        お探しのページが
        <br />
        見つかりませんでした
      </h1>

      <p css={styles.text}>
        URLが間違っているか、ページが移動、
        <br />
        もしくは削除されている可能性があります。
        <CheckOnlyAuthentication>
          <br />
          お手数ですが、ゲストリストアップトップ画面から
          <br />
          お探しください。
        </CheckOnlyAuthentication>
      </p>
      <CheckOnlyAuthentication>
        {/* ErrorContextなどの状態破棄のためwindow.location.assignを利用 */}
        <button css={styles.button} onClick={() => window.location.assign('/cs_guest_list_up')}>
          ゲストリストアップトップへ
        </button>
      </CheckOnlyAuthentication>
    </ErrorLayout>
  )
}

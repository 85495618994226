/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface GetCategoryGuestsRequest
 */
export interface GetCategoryGuestsRequest {
  /**
   *
   * @type {string}
   * @memberof GetCategoryGuestsRequest
   */
  guestBucketUUID: string
  /**
   *
   * @type {string}
   * @memberof GetCategoryGuestsRequest
   */
  communityCategory: GetCategoryGuestsRequestCommunityCategory
}

/**
 * @export
 * @enum {string}
 */
export enum GetCategoryGuestsRequestCommunityCategory {
  family = 'FAMILY',
  school = 'SCHOOL',
  workplace = 'WORKPLACE',
  others = 'OTHERS',
}

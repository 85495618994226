import { styles } from '@/components/cs/errorLayout'
import Layout from '@/components/cs_guest_list_up/layout/layout'

type ErrorLayoutProps = {
  children: React.ReactNode
}
export function ErrorLayout({ children }: ErrorLayoutProps) {
  return (
    <Layout headerType={'DefaultHeader'} shouldAuthentication={false}>
      <div css={styles.main}>
        <div css={styles.card}>{children}</div>
      </div>
    </Layout>
  )
}

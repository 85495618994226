/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface PartyResponseDeadlineEditingResponse
 */
export interface PartyResponseDeadlineEditingResponse {
  /**
   *
   * @type {string}
   * @memberof PartyResponseDeadlineEditingResponse
   */
  responseDeadlineEditingID: string
  /**
   *
   * @type {string}
   * @memberof PartyResponseDeadlineEditingResponse
   */
  responseDeadline: string
  /**
   *
   * @type {string}
   * @memberof PartyResponseDeadlineEditingResponse
   */
  editedAt: string
  /**
   *
   * @type {string}
   * @memberof PartyResponseDeadlineEditingResponse
   */
  editingType: PartyResponseDeadlineEditingResponseEditingType
}

/**
 * @export
 * @enum {string}
 */
export enum PartyResponseDeadlineEditingResponseEditingType {
  customerUpdate = 'CUSTOMER_UPDATE',
  clientUpdateAfterResponseDeadline = 'CLIENT_UPDATE_AFTER_RESPONSE_DEADLINE',
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { CreateNotificationPersonRequest } from '../model'
// @ts-ignore
import { DeleteNotificationPersonRequest } from '../model'
// @ts-ignore
import { ErrorResponse } from '../model'
// @ts-ignore
import { GetNotificationPersonsRequest } from '../model'
// @ts-ignore
import { GetNotificationPersonsResponse } from '../model'
// @ts-ignore
import { UpdateNotificationPersonInfoRequest } from '../model'
// @ts-ignore
import { UpdateNotificationPersonShouldNotifyPaymentEventRequest } from '../model'
// @ts-ignore
import { UpdateNotificationPersonShouldNotifyPaymentEventResponse } from '../model'
// @ts-ignore
import { UpdateNotificationPersonShouldNotifyUsageEventRequest } from '../model'
// @ts-ignore
import { UpdateNotificationPersonShouldNotifyUsageEventResponse } from '../model'
/**
 * ClientNotificationSettingControllerApi - axios parameter creator
 * @export
 */
export const ClientNotificationSettingControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CreateNotificationPersonRequest} createNotificationPersonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNotificationPerson: async (
      createNotificationPersonRequest: CreateNotificationPersonRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createNotificationPersonRequest' is not null or undefined
      assertParamExists(
        'createNotificationPerson',
        'createNotificationPersonRequest',
        createNotificationPersonRequest,
      )
      const localVarPath = `/cl/api/createNotificationPerson`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createNotificationPersonRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {DeleteNotificationPersonRequest} deleteNotificationPersonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNotificationPerson: async (
      deleteNotificationPersonRequest: DeleteNotificationPersonRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteNotificationPersonRequest' is not null or undefined
      assertParamExists(
        'deleteNotificationPerson',
        'deleteNotificationPersonRequest',
        deleteNotificationPersonRequest,
      )
      const localVarPath = `/cl/api/deleteNotificationPerson`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteNotificationPersonRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetNotificationPersonsRequest} getNotificationPersonsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificationPersons: async (
      getNotificationPersonsRequest: GetNotificationPersonsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getNotificationPersonsRequest' is not null or undefined
      assertParamExists(
        'getNotificationPersons',
        'getNotificationPersonsRequest',
        getNotificationPersonsRequest,
      )
      const localVarPath = `/cl/api/getNotificationPersons`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getNotificationPersonsRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateNotificationPersonInfoRequest} updateNotificationPersonInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNotificationPersonInfo: async (
      updateNotificationPersonInfoRequest: UpdateNotificationPersonInfoRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateNotificationPersonInfoRequest' is not null or undefined
      assertParamExists(
        'updateNotificationPersonInfo',
        'updateNotificationPersonInfoRequest',
        updateNotificationPersonInfoRequest,
      )
      const localVarPath = `/cl/api/updateNotificationPersonInfo`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateNotificationPersonInfoRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateNotificationPersonShouldNotifyPaymentEventRequest} updateNotificationPersonShouldNotifyPaymentEventRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNotificationPersonShouldNotifyPaymentEvent: async (
      updateNotificationPersonShouldNotifyPaymentEventRequest: UpdateNotificationPersonShouldNotifyPaymentEventRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateNotificationPersonShouldNotifyPaymentEventRequest' is not null or undefined
      assertParamExists(
        'updateNotificationPersonShouldNotifyPaymentEvent',
        'updateNotificationPersonShouldNotifyPaymentEventRequest',
        updateNotificationPersonShouldNotifyPaymentEventRequest,
      )
      const localVarPath = `/cl/api/updateNotificationPersonShouldNotifyPaymentEvent`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateNotificationPersonShouldNotifyPaymentEventRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateNotificationPersonShouldNotifyUsageEventRequest} updateNotificationPersonShouldNotifyUsageEventRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNotificationPersonShouldNotifyUsageEvent: async (
      updateNotificationPersonShouldNotifyUsageEventRequest: UpdateNotificationPersonShouldNotifyUsageEventRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateNotificationPersonShouldNotifyUsageEventRequest' is not null or undefined
      assertParamExists(
        'updateNotificationPersonShouldNotifyUsageEvent',
        'updateNotificationPersonShouldNotifyUsageEventRequest',
        updateNotificationPersonShouldNotifyUsageEventRequest,
      )
      const localVarPath = `/cl/api/updateNotificationPersonShouldNotifyUsageEvent`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateNotificationPersonShouldNotifyUsageEventRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ClientNotificationSettingControllerApi - functional programming interface
 * @export
 */
export const ClientNotificationSettingControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ClientNotificationSettingControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {CreateNotificationPersonRequest} createNotificationPersonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createNotificationPerson(
      createNotificationPersonRequest: CreateNotificationPersonRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createNotificationPerson(
        createNotificationPersonRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {DeleteNotificationPersonRequest} deleteNotificationPersonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteNotificationPerson(
      deleteNotificationPersonRequest: DeleteNotificationPersonRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNotificationPerson(
        deleteNotificationPersonRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetNotificationPersonsRequest} getNotificationPersonsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNotificationPersons(
      getNotificationPersonsRequest: GetNotificationPersonsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNotificationPersonsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationPersons(
        getNotificationPersonsRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateNotificationPersonInfoRequest} updateNotificationPersonInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateNotificationPersonInfo(
      updateNotificationPersonInfoRequest: UpdateNotificationPersonInfoRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationPersonInfo(
        updateNotificationPersonInfoRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateNotificationPersonShouldNotifyPaymentEventRequest} updateNotificationPersonShouldNotifyPaymentEventRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateNotificationPersonShouldNotifyPaymentEvent(
      updateNotificationPersonShouldNotifyPaymentEventRequest: UpdateNotificationPersonShouldNotifyPaymentEventRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateNotificationPersonShouldNotifyPaymentEventResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateNotificationPersonShouldNotifyPaymentEvent(
          updateNotificationPersonShouldNotifyPaymentEventRequest,
          options,
        )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateNotificationPersonShouldNotifyUsageEventRequest} updateNotificationPersonShouldNotifyUsageEventRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateNotificationPersonShouldNotifyUsageEvent(
      updateNotificationPersonShouldNotifyUsageEventRequest: UpdateNotificationPersonShouldNotifyUsageEventRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateNotificationPersonShouldNotifyUsageEventResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateNotificationPersonShouldNotifyUsageEvent(
          updateNotificationPersonShouldNotifyUsageEventRequest,
          options,
        )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ClientNotificationSettingControllerApi - factory interface
 * @export
 */
export const ClientNotificationSettingControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ClientNotificationSettingControllerApiFp(configuration)
  return {
    /**
     *
     * @param {CreateNotificationPersonRequest} createNotificationPersonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNotificationPerson(
      createNotificationPersonRequest: CreateNotificationPersonRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .createNotificationPerson(createNotificationPersonRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {DeleteNotificationPersonRequest} deleteNotificationPersonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNotificationPerson(
      deleteNotificationPersonRequest: DeleteNotificationPersonRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .deleteNotificationPerson(deleteNotificationPersonRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetNotificationPersonsRequest} getNotificationPersonsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificationPersons(
      getNotificationPersonsRequest: GetNotificationPersonsRequest,
      options?: any,
    ): AxiosPromise<GetNotificationPersonsResponse> {
      return localVarFp
        .getNotificationPersons(getNotificationPersonsRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateNotificationPersonInfoRequest} updateNotificationPersonInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNotificationPersonInfo(
      updateNotificationPersonInfoRequest: UpdateNotificationPersonInfoRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .updateNotificationPersonInfo(updateNotificationPersonInfoRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateNotificationPersonShouldNotifyPaymentEventRequest} updateNotificationPersonShouldNotifyPaymentEventRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNotificationPersonShouldNotifyPaymentEvent(
      updateNotificationPersonShouldNotifyPaymentEventRequest: UpdateNotificationPersonShouldNotifyPaymentEventRequest,
      options?: any,
    ): AxiosPromise<UpdateNotificationPersonShouldNotifyPaymentEventResponse> {
      return localVarFp
        .updateNotificationPersonShouldNotifyPaymentEvent(
          updateNotificationPersonShouldNotifyPaymentEventRequest,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateNotificationPersonShouldNotifyUsageEventRequest} updateNotificationPersonShouldNotifyUsageEventRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNotificationPersonShouldNotifyUsageEvent(
      updateNotificationPersonShouldNotifyUsageEventRequest: UpdateNotificationPersonShouldNotifyUsageEventRequest,
      options?: any,
    ): AxiosPromise<UpdateNotificationPersonShouldNotifyUsageEventResponse> {
      return localVarFp
        .updateNotificationPersonShouldNotifyUsageEvent(
          updateNotificationPersonShouldNotifyUsageEventRequest,
          options,
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ClientNotificationSettingControllerApi - object-oriented interface
 * @export
 * @class ClientNotificationSettingControllerApi
 * @extends {BaseAPI}
 */
export class ClientNotificationSettingControllerApi extends BaseAPI {
  /**
   *
   * @param {CreateNotificationPersonRequest} createNotificationPersonRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientNotificationSettingControllerApi
   */
  public createNotificationPerson(
    createNotificationPersonRequest: CreateNotificationPersonRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientNotificationSettingControllerApiFp(this.configuration)
      .createNotificationPerson(createNotificationPersonRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DeleteNotificationPersonRequest} deleteNotificationPersonRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientNotificationSettingControllerApi
   */
  public deleteNotificationPerson(
    deleteNotificationPersonRequest: DeleteNotificationPersonRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientNotificationSettingControllerApiFp(this.configuration)
      .deleteNotificationPerson(deleteNotificationPersonRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetNotificationPersonsRequest} getNotificationPersonsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientNotificationSettingControllerApi
   */
  public getNotificationPersons(
    getNotificationPersonsRequest: GetNotificationPersonsRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientNotificationSettingControllerApiFp(this.configuration)
      .getNotificationPersons(getNotificationPersonsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateNotificationPersonInfoRequest} updateNotificationPersonInfoRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientNotificationSettingControllerApi
   */
  public updateNotificationPersonInfo(
    updateNotificationPersonInfoRequest: UpdateNotificationPersonInfoRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientNotificationSettingControllerApiFp(this.configuration)
      .updateNotificationPersonInfo(updateNotificationPersonInfoRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateNotificationPersonShouldNotifyPaymentEventRequest} updateNotificationPersonShouldNotifyPaymentEventRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientNotificationSettingControllerApi
   */
  public updateNotificationPersonShouldNotifyPaymentEvent(
    updateNotificationPersonShouldNotifyPaymentEventRequest: UpdateNotificationPersonShouldNotifyPaymentEventRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientNotificationSettingControllerApiFp(this.configuration)
      .updateNotificationPersonShouldNotifyPaymentEvent(
        updateNotificationPersonShouldNotifyPaymentEventRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateNotificationPersonShouldNotifyUsageEventRequest} updateNotificationPersonShouldNotifyUsageEventRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientNotificationSettingControllerApi
   */
  public updateNotificationPersonShouldNotifyUsageEvent(
    updateNotificationPersonShouldNotifyUsageEventRequest: UpdateNotificationPersonShouldNotifyUsageEventRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientNotificationSettingControllerApiFp(this.configuration)
      .updateNotificationPersonShouldNotifyUsageEvent(
        updateNotificationPersonShouldNotifyUsageEventRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

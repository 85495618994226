import { Authentication } from '@/components/couple/cs_guest_list_up/auth/authentication'
import ForceAuthentication from '@/components/couple/cs_guest_list_up/auth/forceAuthentication'
import LoadingOverlay from '@/components/cs/loadingOverlay'
import Footer from '@/components/cs_guest_list_up/layout/Footer'
import Header from '@/components/cs_guest_list_up/layout/Header'
import { fontStyle } from '@/styles/cs/styleConstants'
import { assertNever } from '@/utils/common/assertions'
import { css, Global } from '@emotion/react'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import { createTheme, ThemeProvider } from '@mui/material/styles'

export type HeaderProps = {
  headerType: HeaderType
}
export type HeaderType = 'NoneHeader' | 'NoMenuHeader' | 'DefaultHeader'

const GuestListUpCoupleHeader = ({ headerType }: HeaderProps) => {
  switch (headerType) {
    case 'NoneHeader':
      return <></>
    case 'NoMenuHeader':
      return <Header showMenuButton={false} />
    case 'DefaultHeader':
      return <Header showMenuButton />
    default:
      assertNever(headerType)
      return <></>
  }
}

function AuthController({
  children,
  shouldAuthentication,
}: {
  children?: React.ReactNode
  shouldAuthentication: boolean
}): EmotionJSX.Element {
  if (shouldAuthentication) {
    return <ForceAuthentication>{children}</ForceAuthentication>
  }
  return <>{children}</>
}

export type LayoutType = {
  children?: React.ReactNode
  page?: React.ReactNode
  shouldAuthentication: boolean
  headerType: HeaderType
}

export default function Layout({
  children,
  page,
  headerType = 'NoneHeader',
  shouldAuthentication = true,
}: LayoutType) {
  const theme = createTheme({
    typography: fontStyle.ja,
  })

  return (
    <Authentication>
      <AuthController shouldAuthentication={shouldAuthentication}>
        <Global styles={[styles.font]} />
        <ThemeProvider theme={theme}>
          <div css={styles.wrapper}>
            <GuestListUpCoupleHeader headerType={headerType} />
            <main css={styles.main}>{page || children}</main>
            <Footer />
            <LoadingOverlay />
          </div>
        </ThemeProvider>
      </AuthController>
    </Authentication>
  )
}

const styles = {
  font: css`
    html,
    body,
    button,
    textarea,
    input,
    label,
    select,
    span,
    a {
      font-family: ${fontStyle.ja.fontFamily};
    }
  `,
  wrapper: css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  `,
  main: css`
    display: flex;
    flex: 1;
    flex-direction: column;
  `,
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface GetClientAuthUserResponse
 */
export interface GetClientAuthUserResponse {
  /**
   *
   * @type {string}
   * @memberof GetClientAuthUserResponse
   */
  userId: string
  /**
   *
   * @type {string}
   * @memberof GetClientAuthUserResponse
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof GetClientAuthUserResponse
   */
  lastName: string
  /**
   *
   * @type {string}
   * @memberof GetClientAuthUserResponse
   */
  role: GetClientAuthUserResponseRole
}

/**
 * @export
 * @enum {string}
 */
export enum GetClientAuthUserResponseRole {
  admin = 'ADMIN',
  member = 'MEMBER',
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface GetMajorBanksResponseBankInfo
 */
export interface GetMajorBanksResponseBankInfo {
  /**
   *
   * @type {string}
   * @memberof GetMajorBanksResponseBankInfo
   */
  code: string
  /**
   *
   * @type {string}
   * @memberof GetMajorBanksResponseBankInfo
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof GetMajorBanksResponseBankInfo
   */
  nameKanaColumn: GetMajorBanksResponseBankInfoNameKanaColumn
}

/**
 * @export
 * @enum {string}
 */
export enum GetMajorBanksResponseBankInfoNameKanaColumn {
  a = 'A',
  ka = 'KA',
  sa = 'SA',
  ta = 'TA',
  na = 'NA',
  ha = 'HA',
  ma = 'MA',
  ya = 'YA',
  ra = 'RA',
  wa = 'WA',
  others = 'OTHERS',
}

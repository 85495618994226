import { Configuration } from '@/data/gen'
import { ErrorResponse } from '@/data/gen/model/error-response'
import { MESSAGE } from '@/data/message'
import { AxiosResponse } from 'axios'

export type RPCError = ErrorResponse & { statusCode: number }

export interface ApiResult<T> {
  success: T
  error: RPCError
}

export async function invoke<T>(fn: () => Promise<AxiosResponse<T>>): Promise<ApiResult<T>> {
  const result = await callApi<T>(fn)
  if (result.error) {
    if (result.error.statusCode === 401 || result.error.statusCode === 403) {
      const { pathname } = window.location
      if (pathname.startsWith('/cl')) {
        window.location.href = `/cl/login?from=${encodeURIComponent(pathname)}`
      }
      // csは_error.tsxでハンドリング
    }
  }
  return result
}

async function callApi<T>(
  fn: () => Promise<AxiosResponse>,
): Promise<{ success: T; error: RPCError }> {
  try {
    const response = await fn()
    if (response.status / 100 === 2) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TS2322 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
      return { success: response.data as T, error: null }
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2322 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
    return { success: null, error: asApiError(response) }
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2322 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
    return { success: null, error: asApiError(e.response || { status: 502, data: null }) }
  }
}

export function asApiError(e: { status: number; data: ErrorResponse | null }): RPCError {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS2322 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  return {
    statusCode: e.status || 502,
    ...({ ...e.data } || { code: MESSAGE.NetworkTimeout, args: [] }),
  }
}

export const defaultConfiguration = (req?: { protocol: string; headers: { host: string } }) => {
  const configuration = new Configuration()
  configuration.basePath = process.env.NEXT_PUBLIC_STUB_SERVER
    ? process.env.NEXT_PUBLIC_STUB_SERVER
    : typeof window !== 'undefined'
    ? `${window.location.protocol}//${window.location.host}`
    : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TS2532 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
      `${req.protocol}://${req.headers.host}`
  return configuration
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { CreateGuestRequest } from '../model'
// @ts-ignore
import { CreateGuestResponse } from '../model'
// @ts-ignore
import { GetCategoryGuestsRequest } from '../model'
// @ts-ignore
import { GetCategoryGuestsResponse } from '../model'
// @ts-ignore
import { UpdateGuestInviteStatusRequest } from '../model'
// @ts-ignore
import { UpdateGuestInviteStatusResponse } from '../model'
// @ts-ignore
import { UpdateGuestNameRequest } from '../model'
// @ts-ignore
import { UpdateGuestNameResponse } from '../model'
/**
 * GuestControllerApi - axios parameter creator
 * @export
 */
export const GuestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateGuestRequest} createGuestRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGuest: async (
      createGuestRequest: CreateGuestRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createGuestRequest' is not null or undefined
      assertParamExists('createGuest', 'createGuestRequest', createGuestRequest)
      const localVarPath = `/cs_guest_list_up/api/createGuest`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createGuestRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetCategoryGuestsRequest} getCategoryGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategoryGuests: async (
      getCategoryGuestsRequest: GetCategoryGuestsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getCategoryGuestsRequest' is not null or undefined
      assertParamExists('getCategoryGuests', 'getCategoryGuestsRequest', getCategoryGuestsRequest)
      const localVarPath = `/cs_guest_list_up/api/getCategoryGuests`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getCategoryGuestsRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateGuestInviteStatusRequest} updateGuestInviteStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGuestInviteStatus: async (
      updateGuestInviteStatusRequest: UpdateGuestInviteStatusRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateGuestInviteStatusRequest' is not null or undefined
      assertParamExists(
        'updateGuestInviteStatus',
        'updateGuestInviteStatusRequest',
        updateGuestInviteStatusRequest,
      )
      const localVarPath = `/cs_guest_list_up/api/updateGuestInviteStatus`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateGuestInviteStatusRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateGuestNameRequest} updateGuestNameRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGuestName: async (
      updateGuestNameRequest: UpdateGuestNameRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateGuestNameRequest' is not null or undefined
      assertParamExists('updateGuestName', 'updateGuestNameRequest', updateGuestNameRequest)
      const localVarPath = `/cs_guest_list_up/api/updateGuestName`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateGuestNameRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * GuestControllerApi - functional programming interface
 * @export
 */
export const GuestControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GuestControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {CreateGuestRequest} createGuestRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createGuest(
      createGuestRequest: CreateGuestRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateGuestResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createGuest(
        createGuestRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetCategoryGuestsRequest} getCategoryGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCategoryGuests(
      getCategoryGuestsRequest: GetCategoryGuestsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCategoryGuestsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryGuests(
        getCategoryGuestsRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateGuestInviteStatusRequest} updateGuestInviteStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateGuestInviteStatus(
      updateGuestInviteStatusRequest: UpdateGuestInviteStatusRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateGuestInviteStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateGuestInviteStatus(
        updateGuestInviteStatusRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateGuestNameRequest} updateGuestNameRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateGuestName(
      updateGuestNameRequest: UpdateGuestNameRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateGuestNameResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateGuestName(
        updateGuestNameRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * GuestControllerApi - factory interface
 * @export
 */
export const GuestControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GuestControllerApiFp(configuration)
  return {
    /**
     *
     * @param {CreateGuestRequest} createGuestRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGuest(
      createGuestRequest: CreateGuestRequest,
      options?: any,
    ): AxiosPromise<CreateGuestResponse> {
      return localVarFp
        .createGuest(createGuestRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetCategoryGuestsRequest} getCategoryGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategoryGuests(
      getCategoryGuestsRequest: GetCategoryGuestsRequest,
      options?: any,
    ): AxiosPromise<GetCategoryGuestsResponse> {
      return localVarFp
        .getCategoryGuests(getCategoryGuestsRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateGuestInviteStatusRequest} updateGuestInviteStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGuestInviteStatus(
      updateGuestInviteStatusRequest: UpdateGuestInviteStatusRequest,
      options?: any,
    ): AxiosPromise<UpdateGuestInviteStatusResponse> {
      return localVarFp
        .updateGuestInviteStatus(updateGuestInviteStatusRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateGuestNameRequest} updateGuestNameRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGuestName(
      updateGuestNameRequest: UpdateGuestNameRequest,
      options?: any,
    ): AxiosPromise<UpdateGuestNameResponse> {
      return localVarFp
        .updateGuestName(updateGuestNameRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * GuestControllerApi - object-oriented interface
 * @export
 * @class GuestControllerApi
 * @extends {BaseAPI}
 */
export class GuestControllerApi extends BaseAPI {
  /**
   *
   * @param {CreateGuestRequest} createGuestRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestControllerApi
   */
  public createGuest(createGuestRequest: CreateGuestRequest, options?: AxiosRequestConfig) {
    return GuestControllerApiFp(this.configuration)
      .createGuest(createGuestRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetCategoryGuestsRequest} getCategoryGuestsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestControllerApi
   */
  public getCategoryGuests(
    getCategoryGuestsRequest: GetCategoryGuestsRequest,
    options?: AxiosRequestConfig,
  ) {
    return GuestControllerApiFp(this.configuration)
      .getCategoryGuests(getCategoryGuestsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateGuestInviteStatusRequest} updateGuestInviteStatusRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestControllerApi
   */
  public updateGuestInviteStatus(
    updateGuestInviteStatusRequest: UpdateGuestInviteStatusRequest,
    options?: AxiosRequestConfig,
  ) {
    return GuestControllerApiFp(this.configuration)
      .updateGuestInviteStatus(updateGuestInviteStatusRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateGuestNameRequest} updateGuestNameRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestControllerApi
   */
  public updateGuestName(
    updateGuestNameRequest: UpdateGuestNameRequest,
    options?: AxiosRequestConfig,
  ) {
    return GuestControllerApiFp(this.configuration)
      .updateGuestName(updateGuestNameRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

import { ArrowDownIcon, CalenderIcon, ClockIcon, BlindIcon } from '@/components/cs/icon'
import { colors } from '@/styles/colors'
import { css, SerializedStyles } from '@emotion/react'
import {
  TextareaAutosize as TextareaAutosizeMui,
  TextareaAutosizeProps as TextareaAutosizePropsMui,
} from '@mui/base/TextareaAutosize'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import 'react-datepicker/dist/react-datepicker.css'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker, MobileDatePickerProps } from '@mui/x-date-pickers/MobileDatePicker'
import { isSameDay } from 'date-fns'
import ja from 'date-fns/locale/ja'
import Link, { LinkProps } from 'next/link'
import React, { ReactElement, useState } from 'react'
import InputMask from 'react-input-mask'

type InputLayoutProps = {
  errorText?: string | null
  supplement?: string | JSX.Element
  icon?: JSX.Element
  /** 「人」などの単位 */
  unit?: JSX.Element
  customStyle?: SerializedStyles | Array<SerializedStyles>
  errorTextCustomStyle?: SerializedStyles
  children: React.ReactNode
}

function InputLayout({
  errorText,
  supplement,
  icon,
  customStyle,
  unit,
  errorTextCustomStyle,
  children,
}: InputLayoutProps) {
  return (
    <div css={[styles.inputLayout.inputLayout, customStyle]}>
      {unit ? (
        <div css={styles.inputLayout.inputWithUnit}>
          {children}
          <div css={styles.inputLayout.unit}>{unit}</div>
        </div>
      ) : (
        children
      )}
      {!!icon && icon}
      {!!errorText && (
        <div css={[styles.inputLayout.errorText, errorTextCustomStyle || css``]}>{errorText}</div>
      )}
      {!!supplement && <div css={styles.inputLayout.supplement}>{supplement}</div>}
    </div>
  )
}

type PullDownOption = {
  value: any
  text: string
}
type PullDownProps = JSX.IntrinsicElements['select'] &
  Omit<InputLayoutProps, 'children'> & {
    options: Array<PullDownOption>
  }

export function PullDown({ errorText, supplement, options, disabled, ...props }: PullDownProps) {
  return (
    <InputLayout
      errorText={errorText}
      supplement={supplement}
      icon={
        <ArrowDownIcon
          css={[styles.icon, styles.iconUnclickable, disabled ? styles.iconDisabled : css``]}
        />
      }
    >
      <select
        css={[styles.base, styles.select, errorText ? styles.error : css``]}
        disabled={disabled}
        {...props}
      >
        {options.map((option) => (
          <option key={option.value.toString()} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </InputLayout>
  )
}

type TextAreaAutoSizeProps = Omit<TextareaAutosizePropsMui, 'value' | 'onChange'> &
  Omit<InputLayoutProps, 'children'> & {
    value: string
    onChange: (value: string) => void
  }

export const TextAreaAutoSize = ({
  errorText,
  supplement,
  value,
  onChange,
  placeholder,
  maxLength,
  ...props
}: TextAreaAutoSizeProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // value の文字数を制限するために maxLength prop を設定したときに、ブラウザによって
    // value 内の改行を2文字として扱うか1文字として扱うかが異なるため、改行を2文字として扱う
    // ブラウザでは JavaScript 上の文字数が設定した maxLength の値に達していないのに
    // 文字が入力できなくなったりしてしまう。
    // この問題に対処するため、maxLength prop は使わず onChange 内で文字数を制限する。
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2532 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
    if (e.target.value.length <= maxLength) {
      onChange(e.target.value)
    } else {
      const rightPart = e.target.value.substring(e.target.selectionEnd)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TS2532 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
      const leftPart = e.target.value.substring(0, maxLength - rightPart.length)
      onChange(leftPart + rightPart)
    }
  }

  // iOS でも macOS でも Safari では placeholder の改行が反映されない（value の改行は反映される）
  // この問題に対処するため、
  // value が空のとき:
  //   - 入力用の textarea を placeholder 表示用の textarea の上に重ねる
  //   - 入力用の textarea の背景は透過させる
  //   - 入力用の textarea を placeholder 表示用の textarea の高さを揃えるため、
  //     入力用の textarea にも placeholder は設定するが、文字色は透明にする
  // value が空ではないとき:
  //   - 入力用の textarea だけを表示する
  return (
    <InputLayout errorText={errorText} supplement={supplement}>
      <TextareaAutosizeMui
        css={[
          styles.base,
          styles.textarea,
          errorText ? styles.error : css``,
          !value ? styles.textareaEmptyValue : css``,
        ]}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        {...props}
      />
      {!value && (
        <TextareaAutosizeMui
          css={[
            styles.base,
            styles.textarea,
            errorText ? styles.error : css``,
            styles.textareaPlaceholder,
          ]}
          value={placeholder}
          readOnly
          {...props}
        />
      )}
    </InputLayout>
  )
}
const theme = createTheme({
  palette: {
    primary: {
      light: '#ffe8ea',
      main: '#EA6077',
      dark: '#EA6077',
      contrastText: '#fff',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
  },
})

export type InputDateProps = Omit<MobileDatePickerProps<Date>, 'renderInput'> &
  Omit<InputLayoutProps, 'children'> & {
    renderInput?: (props: TextFieldProps) => ReactElement
    customStyle?: SerializedStyles | Array<SerializedStyles>
    okText?: string
    cancelText?: string
    toolbarTitle?: string
  }

export function InputDate({
  errorText,
  supplement,
  disabled,
  okText,
  cancelText,
  renderInput,
  toolbarTitle,
  customStyle,
  ...props
}: InputDateProps) {
  const DefaultRenderInput = (_props: TextFieldProps) => (
    <DefaultRenderInputForInputDate
      errorText={errorText}
      supplement={supplement}
      icon={
        // アイコンクリックでカレンダー表示させるため、cssの設定でイベントを透過させる
        <CalenderIcon
          css={[styles.icon, styles.iconUnclickable, disabled ? styles.iconDisabled : css``]}
        />
      }
      customStyle={[styles.clickableForm, css(customStyle)]}
      textFieldProps={_props}
    />
  )

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ja}
      localeText={{
        okButtonLabel: okText || '決定',
        cancelButtonLabel: cancelText || 'キャンセル',
        datePickerToolbarTitle: toolbarTitle,
      }}
    >
      <ThemeProvider theme={theme}>
        <MobileDatePicker
          format="yyyy/MM/dd"
          disabled={disabled}
          views={['month', 'day']}
          slots={{
            textField: renderInput || DefaultRenderInput,
          }}
          slotProps={{
            toolbar: {
              hidden: true,
            },
          }}
          {...props}
        />
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export type DefaultRenderInputForInputDateProps = {
  errorText?: string | null
  supplement?: string | JSX.Element
  icon?: JSX.Element
  customStyle?: SerializedStyles | Array<SerializedStyles>
  textFieldProps: TextFieldProps
  children?: React.ReactNode
}
export const DefaultRenderInputForInputDate = ({
  errorText,
  supplement,
  icon,
  customStyle,
  children,
  textFieldProps,
}: DefaultRenderInputForInputDateProps) => {
  const textFieldPropsWithoutUnderline = {
    ...textFieldProps,
    InputProps: {
      disableUnderline: true,
      readOnly: true,
    },
  }

  return (
    <InputLayout
      errorText={errorText}
      supplement={supplement}
      icon={icon}
      customStyle={customStyle}
    >
      <TextField
        variant="filled"
        css={[styles.textField, errorText ? styles.textFieldError : css``]}
        {...textFieldPropsWithoutUnderline}
        placeholder=""
      />
      {children}
    </InputLayout>
  )
}

export const CustomInputDate = ({
  date,
  onOk,
  onChange,
  onAccept,
  onClose,
  customTextField,
  ...props
}: Omit<InputDateProps, 'value' | 'onChange' | 'errorText' | 'supplement' | 'renderInput'> & {
  onOk: (date: Date | null) => void
  date: Date | null
  suggestDate?: Date
  onChange?: (date: Date | null) => void
  toolbarTitle?: string
  customTextField: (
    handleOpen: () => void,
    date: Date | null,
  ) => (_props: TextFieldProps) => React.ReactElement
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <InputDate
      defaultValue={date || new Date()}
      onChange={(inputDate) => {
        if (onChange) onChange(inputDate)
      }}
      onAccept={(inputDate) => {
        if (inputDate && (!date || !isSameDay(inputDate, date))) {
          onOk(inputDate)
        }
        if (onAccept) onAccept(inputDate)
      }}
      open={open}
      onClose={() => {
        setOpen(false)
        if (onClose) onClose()
      }}
      renderInput={customTextField(handleOpen, date)}
      {...props}
    />
  )
}

type InputTextProps = TextFieldProps &
  Pick<JSX.IntrinsicElements['input'], 'maxLength'> &
  Omit<InputLayoutProps, 'children' | 'icon'> & {
    css?: SerializedStyles
    errorTextCustomStyle?: SerializedStyles
  }

export function InputText({
  errorText,
  supplement,
  maxLength,
  label,
  inputProps,
  unit,
  errorTextCustomStyle,
  css: _css,
  ...props
}: InputTextProps) {
  return (
    <InputLayout
      unit={unit}
      errorText={errorText}
      supplement={supplement}
      errorTextCustomStyle={errorTextCustomStyle}
    >
      <TextField
        variant="filled"
        css={[
          styles.textField,
          label ? styles.textFieldWithLabel : css``,
          errorText ? styles.textFieldError : css``,
          _css || css``,
        ]}
        inputProps={{
          maxLength,
          ...inputProps,
        }}
        label={label}
        {...Object.assign(props, { InputProps: { disableUnderline: true, ...props.InputProps } })}
      />
    </InputLayout>
  )
}

type InputTimeProps = {
  disabled: boolean
  value: string | null
  onChange?: (date: string | null) => void
  onBlur?: () => void
  placeholder: string
} & Omit<InputLayoutProps, 'children'>

export function InputTime({
  disabled,
  errorText,
  supplement,
  placeholder,
  value,
  onChange,
  onBlur,
}: InputTimeProps) {
  return (
    <InputLayout errorText={errorText} supplement={supplement}>
      <InputMask
        mask={'99:99'}
        maskChar={''}
        alwaysShowMask={false}
        value={value || undefined}
        onChange={(e) => {
          const targetValue = e.target.value
          if (onChange) {
            onChange(targetValue)
          }
        }}
        onBlur={onBlur}
      >
        {() => (
          <TextField
            variant="filled"
            type="tel"
            InputProps={{
              ...{
                endAdornment: (
                  <ClockIcon
                    css={[
                      styles.icon,
                      styles.iconUnclickable,
                      disabled ? styles.iconDisabled : css``,
                    ]}
                  />
                ),
                disabled,
                placeholder,
                disableUnderline: true,
              },
            }}
            css={[styles.textField, errorText ? styles.textFieldError : css``]}
          />
        )}
      </InputMask>
    </InputLayout>
  )
}

type InputPasswordProps = Omit<JSX.IntrinsicElements['input'], 'type'> &
  Omit<InputLayoutProps, 'children' | 'icon'> & {
    revealPassword: boolean
    setRevealPassword: React.Dispatch<React.SetStateAction<boolean>>
  }

export function InputPassword({
  errorText,
  supplement,
  revealPassword,
  setRevealPassword,
  disabled,
  ...props
}: InputPasswordProps) {
  return (
    <InputLayout
      errorText={errorText}
      supplement={supplement}
      icon={
        <BlindIcon
          reveal={revealPassword}
          onClick={() => {
            setRevealPassword(!revealPassword)
          }}
          css={[styles.icon, disabled ? styles.iconDisabled : css``]}
        />
      }
    >
      <input
        css={[styles.base, styles.inputPassword, errorText ? styles.errorInputPassword : css``]}
        type={revealPassword ? 'text' : 'password'}
        disabled={disabled}
        {...props}
      />
    </InputLayout>
  )
}
type CustomLinkProps = React.PropsWithChildren<LinkProps> & {
  target?: string
  rel?: string
  customStyle?: Array<SerializedStyles> | SerializedStyles
  onClick?: () => void
}

export function CustomLink({
  onClick,
  href,
  children,
  target,
  rel,
  customStyle,
  ...props
}: CustomLinkProps) {
  return (
    <Link href={href} passHref {...props}>
      <a css={customStyle} onClick={onClick} target={target} rel={rel}>
        {children}
      </a>
    </Link>
  )
}

const styles = {
  inputLayout: {
    inputLayout: css`
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
    `,
    inputWithUnit: css`
      display: inline-flex;
      width: 100%;
    `,
    unit: css`
      display: inline-flex;
      align-items: center;
    `,
    errorText: css`
      padding-left: 20px;
      margin-top: 4px;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      color: ${colors.COLOR_E32847};
      letter-spacing: 0.5px;
    `,
    supplement: css`
      padding-left: 20px;
      margin-top: 4px;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      color: rgba(75, 75, 75, 0.8);
      letter-spacing: 0.5px;
    `,
  },
  base: css`
    box-sizing: border-box;
    width: 100%;
    padding: 17px 20px;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    color: ${colors.COLOR_4B4B4B};
    text-align: left;
    letter-spacing: 0.5px;
    background: ${colors.COLOR_F7F8FA};
    border: 1px solid transparent;
    border-radius: 16px;
    caret-color: ${colors.COLOR_EA6077};
    ::placeholder {
      color: rgba(75, 75, 75, 0.4);
    }
    :focus {
      border: 1px solid;
      border-color: ${colors.COLOR_EA6077};
      outline: none; /* ブラウザのデフォルトを消す */
      /* TODO: 本当はこれをやりたいが border-image と border-radius は同時には効かないらしい
      border-image-source: linear-gradient(100.43deg, #ea6077 3.17%, #f67a7f 95.68%); */
    }
    :disabled {
      color: rgba(75, 75, 75, 0.6);
      background: ${colors.COLOR_EBECED};
      opacity: 1;
    }
    ::-webkit-calendar-picker-indicator {
      background: none;
    }
  `,
  select: css`
    -webkit-appearance: none;
    appearance: none;
    height: 56px;
  `,
  textarea: css`
    height: 84px;
    resize: none;
  `,
  textareaEmptyValue: css`
    position: absolute;
    inset: 0;
    background: transparent;
    ::placeholder {
      color: transparent;
    }
    :disabled {
      background: transparent;
    }
  `,
  textareaPlaceholder: css`
    color: rgba(75, 75, 75, 0.4);
    border: 1px solid transparent;
  `,
  input: css`
    height: 56px;
  `,
  inputPassword: css`
    height: 56px;
    padding: 17px 36px 20px 17px;
    background: ${colors.COLOR_FFFFFF};
    backdrop-filter: blur(28px);
    ::-webkit-credentials-auto-fill-button, /* safari で表示されるパスワード自動入力の鍵アイコンアイコンを隠す */
    ::-webkit-caps-lock-indicator /* safari で表示される capslock の矢印アイコンを隠す */ {
      position: absolute;
      right: 0;
      display: none !important;
      pointer-events: none;
      visibility: hidden;
    }
    ::-ms-reveal {
      display: none; /* edgeデフォルトの目アイコンを非表示にする */
    }
  `,
  error: css`
    color: ${colors.COLOR_E32847};
    background: linear-gradient(0deg, rgba(227, 40, 71, 0.05), rgba(227, 40, 71, 0.05)),
      ${colors.COLOR_FFFFFF};
    border: 1px solid ${colors.COLOR_E32847};
  `,
  errorInputPassword: css`
    border: 1px solid ${colors.COLOR_E32847};
  `,
  icon: css`
    position: absolute;
    top: 12px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 8px;
  `,
  iconUnclickable: css`
    pointer-events: none;
  `,
  iconDisabled: css`
    path {
      fill: rgba(60, 60, 67, 0.3);
    }
  `,
  clickableForm: css`
    .MuiFilledInput-input {
      cursor: pointer;
    }
  `,
  textField: css`
    .MuiInputBase-root {
      box-sizing: border-box;
      width: 100%;
      height: 56px;
      background: ${colors.COLOR_F7F8FA};
      border: 1px solid transparent;
      border-radius: 16px;
      caret-color: ${colors.COLOR_EA6077};
      &.Mui-focused {
        background: ${colors.COLOR_F7F8FA};
        border: 1px solid ${colors.COLOR_EA6077};
      }
      &.Mui-disabled {
        background: ${colors.COLOR_EBECED};
      }
    }
    .MuiInputBase-input {
      padding: 17px 20px;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      color: ${colors.COLOR_4B4B4B};
      text-align: left;
      letter-spacing: 0.5px;
      ::placeholder {
        color: rgba(75, 75, 75, 0.4);
        opacity: 1;
      }
      &.Mui-disabled {
        color: rgba(75, 75, 75, 0.6);
        -webkit-text-fill-color: rgba(75, 75, 75, 0.6);
      }
    }
    .MuiInputLabel-root {
      position: absolute;
      top: 17px;
      left: 20px;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      color: rgba(75, 75, 75, 0.4);
      letter-spacing: 0.5px;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      transform: none;
      &.MuiInputLabel-shrink {
        top: 9px;
        font-size: 10px;
        color: rgba(75, 75, 75, 0.8);
        &.Mui-focused {
          color: rgba(75, 75, 75, 0.8);
        }
      }
    }
  `,
  textFieldWithLabel: css`
    .MuiInputBase-input {
      padding: 24px 20px 12px;
    }
  `,
  textFieldError: css`
    .MuiInputBase-root {
      background: linear-gradient(0deg, rgba(227, 40, 71, 0.05), rgba(227, 40, 71, 0.05)),
        ${colors.COLOR_FFFFFF};
      border: 1px solid ${colors.COLOR_E32847};
    }
    .MuiInputBase-input {
      color: ${colors.COLOR_E32847};
    }
  `,
  configButton: css`
    width: 100%;
    max-width: 480px;
  `,
}

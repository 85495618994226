import { useLoading } from '@/context/loading'
import { colors } from '@/styles/colors'
import { zIndex } from '@/styles/cs/styleConstants'
import { css } from '@emotion/react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export default function LoadingOverlay() {
  const { loading } = useLoading()

  return (
    <>
      {!!loading && (
        <Backdrop css={styles.backdrop} open={loading}>
          <CircularProgress css={styles.circle} size={47} thickness={5} />
        </Backdrop>
      )}
    </>
  )
}

const styles = {
  backdrop: css`
    z-index: ${zIndex.modal + 1};
    background-color: rgba(255, 255, 255, 0.4);
  `,
  circle: css`
    color: ${colors.COLOR_EA6077};
  `,
}

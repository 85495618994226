import Footer from '@/components/entry/footer'
import Header from '@/components/entry/header'
import Loading from '@/components/entry/loading'
import { useLoading } from '@/context/loading'
import { fontFamily, zIndexLoading } from '@/styles/cl/styleConstants'
import { css } from '@emotion/react'
import { createTheme, ThemeProvider } from '@mui/material/styles'

type LayoutProps = {
  children: React.ReactNode
  showHeaderContents?: boolean
  showEntryCloseModalOnLogout?: boolean
}

export function Layout({ children, showHeaderContents, showEntryCloseModalOnLogout }: LayoutProps) {
  const { loading } = useLoading()

  return (
    <ThemeProvider theme={theme}>
      <div css={styles.wrapper}>
        <div css={styles.mainWrapper}>
          <Header
            showHeaderContents={showHeaderContents}
            showEntryCloseModalOnLogout={showEntryCloseModalOnLogout}
          />
          {children}
          <Footer />
          {loading && (
            <div css={styles.loading}>
              <Loading />
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  )
}

const styles = {
  wrapper: css`
    display: flex;
    font-family: ${fontFamily};
  `,
  mainWrapper: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 1220px;
    min-height: 100vh;
    padding-bottom: 0;
    background-color: #f8f8f8;
  `,
  loading: css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${zIndexLoading};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
  `,
}

const theme = createTheme({
  typography: {
    fontFamily: [fontFamily].join(','),
  },
})

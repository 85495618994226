/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CustomResponseForCouple } from './custom-response-for-couple'
import { GuestCompanion } from './guest-companion'
import { PaymentInfo } from './payment-info'

/**
 *
 * @export
 * @interface GuestResponse
 */
export interface GuestResponse {
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  invitationID: string
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  invitationName: string
  /**
   *
   * @type {boolean}
   * @memberof GuestResponse
   */
  isSuspendedInvitation: boolean
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  responseUUID: string
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  guestID: string
  /**
   *
   * @type {boolean}
   * @memberof GuestResponse
   */
  attendance: boolean
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  nameKana: string
  /**
   *
   * @type {boolean}
   * @memberof GuestResponse
   */
  isUsingPayment: boolean
  /**
   *
   * @type {PaymentInfo}
   * @memberof GuestResponse
   */
  paymentInfo?: PaymentInfo
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  emailAddress: string
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  respondedAt: string
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  whichGuest?: GuestResponseWhichGuest
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  sex?: GuestResponseSex
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  postCode?: string
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  address?: string
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  tel?: string
  /**
   *
   * @type {Array<CustomResponseForCouple>}
   * @memberof GuestResponse
   */
  customResponses: Array<CustomResponseForCouple>
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  message?: string
  /**
   *
   * @type {Array<GuestCompanion>}
   * @memberof GuestResponse
   */
  guestCompanions: Array<GuestCompanion>
}

/**
 * @export
 * @enum {string}
 */
export enum GuestResponseWhichGuest {
  shinroGuest = 'SHINRO_GUEST',
  shinpuGuest = 'SHINPU_GUEST',
}
/**
 * @export
 * @enum {string}
 */
export enum GuestResponseSex {
  male = 'MALE',
  female = 'FEMALE',
  other = 'OTHER',
}

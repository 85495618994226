import { RPCError } from '@/data/rpc'
import React, { createContext, useCallback, useContext, useState } from 'react'

export type CoupleUser = {
  coupleUUID: string
}

type CoupleUserContextType = {
  user: CoupleUser
  error: RPCError
  setAuthState: (_user: CoupleUser, _error: RPCError) => void
}

const CoupleUserContext = createContext<CoupleUserContextType>({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS2322 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  user: null,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS2322 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  error: null,
  setAuthState: (_user: CoupleUser, _error: RPCError) => {},
})

interface Props {
  user: CoupleUser
  error: RPCError
  setAuthState: (_user: CoupleUser, _error: RPCError) => void
}

export const CoupleUserContextProvider: React.FC<Props> = ({
  user,
  error,
  setAuthState,
  children,
}) => (
  <CoupleUserContext.Provider value={{ user, error, setAuthState }}>
    {children}
  </CoupleUserContext.Provider>
)

export const useCoupleUserContext = () => useContext(CoupleUserContext)

export const useCoupleUserFactory = (): CoupleUserContextType => {
  const [user, setUser] = useState(null)
  const [error, setError] = useState(null)
  const setAuthState = useCallback((_user: CoupleUser, _error: RPCError) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2345 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
    setUser(_user)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2345 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
    setError(_error)
  }, [])
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS2352 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  return {
    user,
    error,
    setAuthState,
  } as CoupleUserContextType
}

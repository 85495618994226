/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { ErrorResponse } from '../model'
// @ts-ignore
import { GuestListUpLoginResponse } from '../model'
// @ts-ignore
import { GuestListUpLogoutResponse } from '../model'
// @ts-ignore
import { VerifyGuestListUpPartnerInvitationPasswordRequest } from '../model'
// @ts-ignore
import { VerifyGuestListUpPartnerInvitationPasswordResponse } from '../model'
// @ts-ignore
import { VerifyGuestListUpPartnerInvitationRequest } from '../model'
// @ts-ignore
import { VerifyGuestListUpPinCodeRequest } from '../model'
// @ts-ignore
import { VerifyGuestListUpPinCodeResponse } from '../model'
/**
 * GuestListUpCoupleAuthControllerApi - axios parameter creator
 * @export
 */
export const GuestListUpCoupleAuthControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestListUpCoupleLogin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cs_guest_list_up/api/auth/login`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestListUpLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cs_guest_list_up/api/auth/logout`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {VerifyGuestListUpPartnerInvitationRequest} verifyGuestListUpPartnerInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyGuestListUpPartnerInvitation: async (
      verifyGuestListUpPartnerInvitationRequest: VerifyGuestListUpPartnerInvitationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyGuestListUpPartnerInvitationRequest' is not null or undefined
      assertParamExists(
        'verifyGuestListUpPartnerInvitation',
        'verifyGuestListUpPartnerInvitationRequest',
        verifyGuestListUpPartnerInvitationRequest,
      )
      const localVarPath = `/cs_guest_list_up/api/auth/verifyGuestListUpPartnerInvitation`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyGuestListUpPartnerInvitationRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {VerifyGuestListUpPartnerInvitationPasswordRequest} verifyGuestListUpPartnerInvitationPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyGuestListUpPartnerInvitationPassword: async (
      verifyGuestListUpPartnerInvitationPasswordRequest: VerifyGuestListUpPartnerInvitationPasswordRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyGuestListUpPartnerInvitationPasswordRequest' is not null or undefined
      assertParamExists(
        'verifyGuestListUpPartnerInvitationPassword',
        'verifyGuestListUpPartnerInvitationPasswordRequest',
        verifyGuestListUpPartnerInvitationPasswordRequest,
      )
      const localVarPath = `/cs_guest_list_up/api/auth/verifyGuestListUpPartnerInvitationPassword`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyGuestListUpPartnerInvitationPasswordRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {VerifyGuestListUpPinCodeRequest} verifyGuestListUpPinCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyGuestListUpPinCode: async (
      verifyGuestListUpPinCodeRequest: VerifyGuestListUpPinCodeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyGuestListUpPinCodeRequest' is not null or undefined
      assertParamExists(
        'verifyGuestListUpPinCode',
        'verifyGuestListUpPinCodeRequest',
        verifyGuestListUpPinCodeRequest,
      )
      const localVarPath = `/cs_guest_list_up/api/auth/verifyPinCode`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyGuestListUpPinCodeRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * GuestListUpCoupleAuthControllerApi - functional programming interface
 * @export
 */
export const GuestListUpCoupleAuthControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    GuestListUpCoupleAuthControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async guestListUpCoupleLogin(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuestListUpLoginResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.guestListUpCoupleLogin(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async guestListUpLogout(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuestListUpLogoutResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.guestListUpLogout(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {VerifyGuestListUpPartnerInvitationRequest} verifyGuestListUpPartnerInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyGuestListUpPartnerInvitation(
      verifyGuestListUpPartnerInvitationRequest: VerifyGuestListUpPartnerInvitationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifyGuestListUpPartnerInvitation(
        verifyGuestListUpPartnerInvitationRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {VerifyGuestListUpPartnerInvitationPasswordRequest} verifyGuestListUpPartnerInvitationPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyGuestListUpPartnerInvitationPassword(
      verifyGuestListUpPartnerInvitationPasswordRequest: VerifyGuestListUpPartnerInvitationPasswordRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<VerifyGuestListUpPartnerInvitationPasswordResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.verifyGuestListUpPartnerInvitationPassword(
          verifyGuestListUpPartnerInvitationPasswordRequest,
          options,
        )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {VerifyGuestListUpPinCodeRequest} verifyGuestListUpPinCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyGuestListUpPinCode(
      verifyGuestListUpPinCodeRequest: VerifyGuestListUpPinCodeRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyGuestListUpPinCodeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifyGuestListUpPinCode(
        verifyGuestListUpPinCodeRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * GuestListUpCoupleAuthControllerApi - factory interface
 * @export
 */
export const GuestListUpCoupleAuthControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GuestListUpCoupleAuthControllerApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestListUpCoupleLogin(options?: any): AxiosPromise<GuestListUpLoginResponse> {
      return localVarFp.guestListUpCoupleLogin(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    guestListUpLogout(options?: any): AxiosPromise<GuestListUpLogoutResponse> {
      return localVarFp.guestListUpLogout(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {VerifyGuestListUpPartnerInvitationRequest} verifyGuestListUpPartnerInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyGuestListUpPartnerInvitation(
      verifyGuestListUpPartnerInvitationRequest: VerifyGuestListUpPartnerInvitationRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .verifyGuestListUpPartnerInvitation(verifyGuestListUpPartnerInvitationRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {VerifyGuestListUpPartnerInvitationPasswordRequest} verifyGuestListUpPartnerInvitationPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyGuestListUpPartnerInvitationPassword(
      verifyGuestListUpPartnerInvitationPasswordRequest: VerifyGuestListUpPartnerInvitationPasswordRequest,
      options?: any,
    ): AxiosPromise<VerifyGuestListUpPartnerInvitationPasswordResponse> {
      return localVarFp
        .verifyGuestListUpPartnerInvitationPassword(
          verifyGuestListUpPartnerInvitationPasswordRequest,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {VerifyGuestListUpPinCodeRequest} verifyGuestListUpPinCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyGuestListUpPinCode(
      verifyGuestListUpPinCodeRequest: VerifyGuestListUpPinCodeRequest,
      options?: any,
    ): AxiosPromise<VerifyGuestListUpPinCodeResponse> {
      return localVarFp
        .verifyGuestListUpPinCode(verifyGuestListUpPinCodeRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * GuestListUpCoupleAuthControllerApi - object-oriented interface
 * @export
 * @class GuestListUpCoupleAuthControllerApi
 * @extends {BaseAPI}
 */
export class GuestListUpCoupleAuthControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestListUpCoupleAuthControllerApi
   */
  public guestListUpCoupleLogin(options?: AxiosRequestConfig) {
    return GuestListUpCoupleAuthControllerApiFp(this.configuration)
      .guestListUpCoupleLogin(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestListUpCoupleAuthControllerApi
   */
  public guestListUpLogout(options?: AxiosRequestConfig) {
    return GuestListUpCoupleAuthControllerApiFp(this.configuration)
      .guestListUpLogout(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {VerifyGuestListUpPartnerInvitationRequest} verifyGuestListUpPartnerInvitationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestListUpCoupleAuthControllerApi
   */
  public verifyGuestListUpPartnerInvitation(
    verifyGuestListUpPartnerInvitationRequest: VerifyGuestListUpPartnerInvitationRequest,
    options?: AxiosRequestConfig,
  ) {
    return GuestListUpCoupleAuthControllerApiFp(this.configuration)
      .verifyGuestListUpPartnerInvitation(verifyGuestListUpPartnerInvitationRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {VerifyGuestListUpPartnerInvitationPasswordRequest} verifyGuestListUpPartnerInvitationPasswordRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestListUpCoupleAuthControllerApi
   */
  public verifyGuestListUpPartnerInvitationPassword(
    verifyGuestListUpPartnerInvitationPasswordRequest: VerifyGuestListUpPartnerInvitationPasswordRequest,
    options?: AxiosRequestConfig,
  ) {
    return GuestListUpCoupleAuthControllerApiFp(this.configuration)
      .verifyGuestListUpPartnerInvitationPassword(
        verifyGuestListUpPartnerInvitationPasswordRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {VerifyGuestListUpPinCodeRequest} verifyGuestListUpPinCodeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestListUpCoupleAuthControllerApi
   */
  public verifyGuestListUpPinCode(
    verifyGuestListUpPinCodeRequest: VerifyGuestListUpPinCodeRequest,
    options?: AxiosRequestConfig,
  ) {
    return GuestListUpCoupleAuthControllerApiFp(this.configuration)
      .verifyGuestListUpPinCode(verifyGuestListUpPinCodeRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

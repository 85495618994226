/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CoupleAnalyticsDataResponse } from './couple-analytics-data-response'

/**
 *
 * @export
 * @interface UpdatePartyHoldDateResponse
 */
export interface UpdatePartyHoldDateResponse {
  /**
   *
   * @type {string}
   * @memberof UpdatePartyHoldDateResponse
   */
  partyUpdateStatus: UpdatePartyHoldDateResponsePartyUpdateStatus
  /**
   *
   * @type {CoupleAnalyticsDataResponse}
   * @memberof UpdatePartyHoldDateResponse
   */
  coupleAnalyticsDataResponse: CoupleAnalyticsDataResponse
}

/**
 * @export
 * @enum {string}
 */
export enum UpdatePartyHoldDateResponsePartyUpdateStatus {
  unpublished = 'UNPUBLISHED',
  paymentOnPublished = 'PAYMENT_ON_PUBLISHED',
  paymentOffPublished = 'PAYMENT_OFF_PUBLISHED',
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CoupleAnalyticsDataResponse } from './couple-analytics-data-response'
import { PartnerInvitation } from './partner-invitation'

/**
 *
 * @export
 * @interface GetPartnerInvitationResponse
 */
export interface GetPartnerInvitationResponse {
  /**
   *
   * @type {string}
   * @memberof GetPartnerInvitationResponse
   */
  status: GetPartnerInvitationResponseStatus
  /**
   *
   * @type {PartnerInvitation}
   * @memberof GetPartnerInvitationResponse
   */
  partnerInvitation?: PartnerInvitation
  /**
   *
   * @type {CoupleAnalyticsDataResponse}
   * @memberof GetPartnerInvitationResponse
   */
  coupleAnalyticsDataResponse: CoupleAnalyticsDataResponse
}

/**
 * @export
 * @enum {string}
 */
export enum GetPartnerInvitationResponseStatus {
  invitingPartner = 'INVITING_PARTNER',
  invitedPartner = 'INVITED_PARTNER',
  notInvitePartner = 'NOT_INVITE_PARTNER',
}

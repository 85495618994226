/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ClientCustomerWithPaymentToClient
 */
export interface ClientCustomerWithPaymentToClient {
  /**
   *
   * @type {number}
   * @memberof ClientCustomerWithPaymentToClient
   */
  amsId: number
  /**
   *
   * @type {string}
   * @memberof ClientCustomerWithPaymentToClient
   */
  partyId: string
  /**
   *
   * @type {string}
   * @memberof ClientCustomerWithPaymentToClient
   */
  partyHoldDate: string
  /**
   *
   * @type {boolean}
   * @memberof ClientCustomerWithPaymentToClient
   */
  isAfterPartyHoldDate: boolean
  /**
   *
   * @type {string}
   * @memberof ClientCustomerWithPaymentToClient
   */
  shinroName: string
  /**
   *
   * @type {string}
   * @memberof ClientCustomerWithPaymentToClient
   */
  shinpuName: string
  /**
   *
   * @type {number}
   * @memberof ClientCustomerWithPaymentToClient
   */
  totalGuestPaymentAmount: number
  /**
   *
   * @type {number}
   * @memberof ClientCustomerWithPaymentToClient
   */
  serviceAmount: number
  /**
   *
   * @type {number}
   * @memberof ClientCustomerWithPaymentToClient
   */
  serviceBaseAmount: number
  /**
   *
   * @type {number}
   * @memberof ClientCustomerWithPaymentToClient
   */
  serviceConsumptionTaxAmount: number
  /**
   *
   * @type {number}
   * @memberof ClientCustomerWithPaymentToClient
   */
  totalPaymentToClientAmount: number
  /**
   *
   * @type {string}
   * @memberof ClientCustomerWithPaymentToClient
   */
  paymentToClientStatus: ClientCustomerWithPaymentToClientPaymentToClientStatus
  /**
   *
   * @type {string}
   * @memberof ClientCustomerWithPaymentToClient
   */
  paymentToClientCreatedAt?: string
}

/**
 * @export
 * @enum {string}
 */
export enum ClientCustomerWithPaymentToClientPaymentToClientStatus {
  ready = 'READY',
  failed = 'FAILED',
  complete = 'COMPLETE',
}

import { createContext, useCallback, useContext, useState } from 'react'

type LoadingContextType = {
  loading: boolean
  setLoading: (loading: boolean) => void
}

const LoadingContext = createContext<LoadingContextType>({
  loading: false,
  setLoading: (_: boolean) => {},
})

interface Props {
  loading: boolean
  setLoading: (loading: boolean) => void
}

export const LoadingContextProvider: React.FC<Props> = ({ loading, setLoading, children }) => (
  <LoadingContext.Provider value={{ loading, setLoading }}>{children}</LoadingContext.Provider>
)

export const useLoading = () => useContext(LoadingContext)

export const useLoadingFactory = (): LoadingContextType => {
  const [loading, setLoadingInner] = useState(false)
  const setLoading = useCallback((current: boolean): void => {
    setLoadingInner(current)
  }, [])
  return {
    loading,
    setLoading,
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CustomerInvitation } from './customer-invitation'
import { PartyResponseDeadline } from './party-response-deadline'

/**
 *
 * @export
 * @interface GetCustomerInvitationsResponse
 */
export interface GetCustomerInvitationsResponse {
  /**
   *
   * @type {string}
   * @memberof GetCustomerInvitationsResponse
   */
  partyPaymentSetting: GetCustomerInvitationsResponsePartyPaymentSetting
  /**
   *
   * @type {PartyResponseDeadline}
   * @memberof GetCustomerInvitationsResponse
   */
  partyResponseDeadline: PartyResponseDeadline
  /**
   *
   * @type {string}
   * @memberof GetCustomerInvitationsResponse
   */
  partyHoldDate: string
  /**
   *
   * @type {string}
   * @memberof GetCustomerInvitationsResponse
   */
  partyGuestPaymentSetting: GetCustomerInvitationsResponsePartyGuestPaymentSetting
  /**
   *
   * @type {number}
   * @memberof GetCustomerInvitationsResponse
   */
  price: number
  /**
   *
   * @type {Array<CustomerInvitation>}
   * @memberof GetCustomerInvitationsResponse
   */
  invitations: Array<CustomerInvitation>
}

/**
 * @export
 * @enum {string}
 */
export enum GetCustomerInvitationsResponsePartyPaymentSetting {
  goshugi = 'GOSHUGI',
  kaihi = 'KAIHI',
  noSetting = 'NO_SETTING',
}
/**
 * @export
 * @enum {string}
 */
export enum GetCustomerInvitationsResponsePartyGuestPaymentSetting {
  noSettings = 'NO_SETTINGS',
  notUsing = 'NOT_USING',
  using = 'USING',
}

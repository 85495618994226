/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GetCategoryGuestsResponseCategoryGuestCount } from './get-category-guests-response-category-guest-count'
import { GetCategoryGuestsResponseContent } from './get-category-guests-response-content'
import { GuestListUpAnalyticsDataResponse } from './guest-list-up-analytics-data-response'

/**
 *
 * @export
 * @interface GetCategoryGuestsResponse
 */
export interface GetCategoryGuestsResponse {
  /**
   *
   * @type {string}
   * @memberof GetCategoryGuestsResponse
   */
  guestBucketUUID: string
  /**
   *
   * @type {string}
   * @memberof GetCategoryGuestsResponse
   */
  guestBucketName: string
  /**
   *
   * @type {string}
   * @memberof GetCategoryGuestsResponse
   */
  communityCategory: GetCategoryGuestsResponseCommunityCategory
  /**
   *
   * @type {Array<GetCategoryGuestsResponseContent>}
   * @memberof GetCategoryGuestsResponse
   */
  contents: Array<GetCategoryGuestsResponseContent>
  /**
   *
   * @type {Array<GetCategoryGuestsResponseCategoryGuestCount>}
   * @memberof GetCategoryGuestsResponse
   */
  categoryGuestCount: Array<GetCategoryGuestsResponseCategoryGuestCount>
  /**
   *
   * @type {GuestListUpAnalyticsDataResponse}
   * @memberof GetCategoryGuestsResponse
   */
  guestListUpAnalyticsDataResponse: GuestListUpAnalyticsDataResponse
}

/**
 * @export
 * @enum {string}
 */
export enum GetCategoryGuestsResponseCommunityCategory {
  family = 'FAMILY',
  school = 'SCHOOL',
  workplace = 'WORKPLACE',
  others = 'OTHERS',
}

interface Props {
  color: string
  className?: string
}
const Logo = ({ color, className }: Props) => (
  <svg viewBox="0 0 151 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#a)" fill={color}>
      <path d="M94.68 1.215h-2.49a.223.223 0 0 0-.181.105v.02l-7.107 9.82h2.49a.23.23 0 0 0 .181-.088l1.225-1.697v8.116h3.034V5.161l2.849-3.946ZM100.153 2.453v2.321l-3.538-1.018V1.242s3.152.906 3.322.963c.058.014.11.046.149.09.039.045.062.1.067.158Zm5.364-.15v4.85c0 4.767-3.781 10.328-3.781 10.328h-3.392s4.314-6.25 4.314-10.732V2.076h2.619a.246.246 0 0 1 .162.07.233.233 0 0 1 .071.158h.007ZM65.611 2.453v2.321l-3.538-1.018V1.242s3.131.906 3.323.963c.058.014.11.046.148.09.04.045.063.1.067.158Zm5.365-.15v4.85c0 4.767-3.782 10.328-3.782 10.328h-3.392s4.314-6.25 4.314-10.732V2.076h2.62c.062.001.121.025.166.068.045.042.071.1.074.16ZM81.642 1.924v.794h-7.233v-1.1h6.93a.313.313 0 0 1 .217.092.3.3 0 0 1 .086.214Zm1.044 3.115a.289.289 0 0 0-.087-.217.303.303 0 0 0-.22-.089h-8.805v1.103h6.283v3.329c0 4.835-5.388 8.306-5.388 8.306h4.073S82.686 14 82.686 9.327c-.014.017.01-3.986.01-4.288h-.01ZM60.042 3.603s0-.23-.223-.23h-1.837V1.337a.238.238 0 0 0-.072-.16.25.25 0 0 0-.164-.07h-2.64v2.27h-4.871v1.278h4.811c-.163 2.715-2.327 6.321-6.262 9.779l1.625 1.588c2.337-2.23 4.174-4.693 4.567-5.768v5.174c0 .835-.942 1.049-1.169 1.052h-1.106v.99h3.204a2.064 2.064 0 0 0 1.455-.534c.194-.18.35-.394.459-.631.108-.238.167-.495.174-.755V4.645h2.06l-.011-1.042ZM151 16.209c-1.739-2.4-2.985-5.12-3.232-9.874v-.859h3.089v-.6a.27.27 0 0 0-.09-.176.285.285 0 0 0-.188-.072h-2.811V.278a.274.274 0 0 0-.07-.186.284.284 0 0 0-.18-.092h-1.792v4.628h-2.867v.848h2.867v.611c-.212 4.886-1.461 7.655-3.221 10.084V.763a.27.27 0 0 0-.069-.187.284.284 0 0 0-.182-.09h-1.785v4.75l-.57-2.44h-1.437c-.174 0-.149.16-.149.2l1.002 3.793h1.161v1.822c-.041.017-.069.041-.069.065l-2.411 4.22h2.129l.348-.637v5.181h2.035V16.24l1.625 1.313c1.18-1.8 2.07-3.768 2.637-5.832a17.047 17.047 0 0 0 2.574 5.822L151 16.208Z" />
      <path d="m150.816 4.048-.915-3.105h-1.479c-.167 0-.153.13-.153.17l.838 2.935h1.709ZM132.451 10.47a.273.273 0 0 0-.066-.155.282.282 0 0 0-.146-.088c-.171-.055-3.26-.944-3.26-.944v2.287l3.479 1.018-.007-2.117ZM128.005.383h-1.809c-.091 0-.164.041-.164.078l-2.839 5.48h2.008l2.804-5.558Z" />
      <path d="M136.156 5.568V4.97a.273.273 0 0 0-.091-.18.285.285 0 0 0-.191-.071h-2.839V2.04h2.484v-.56a.253.253 0 0 0-.084-.165.268.268 0 0 0-.177-.066h-2.223V.23a.222.222 0 0 0-.079-.144.233.233 0 0 0-.157-.053h-1.858v1.225h-2.498v.78h2.501v2.69h-3.037v.517h-1.704c-.091 0-.164.04-.164.078l-2.839 5.48h1.945v6.65h2.087v-9.09a.218.218 0 0 0-.078-.145.228.228 0 0 0-.158-.052h-.463l1.367-2.714v.125h5.243v2.284h-5.239v.848h5.243v7.675s.059.309-.324.309h-.967v.76h2.686s.88 0 .88-.818V8.7h.73v-.597a.272.272 0 0 0-.09-.18.289.289 0 0 0-.192-.072h-.448V5.568h.734ZM112.026 8.408V2.453h1.169v-.597a.275.275 0 0 0-.093-.18.287.287 0 0 0-.192-.071h-.884V.302a.274.274 0 0 0-.073-.188.288.288 0 0 0-.184-.09h-1.847v1.58h-1.531v.85h1.531v7.44c-.686.391-1.402.731-2.14 1.018l.783 2.036c.464-.184.917-.392 1.357-.625v5.107h2.104v-6.477c.406-.327.789-.68 1.145-1.059V7.251c-.349.415-.732.802-1.145 1.157ZM120.616 8.574h-6.982v8.822h2.282v-.428h2.675v.418h2.282V8.79a.245.245 0 0 0-.086-.157.257.257 0 0 0-.171-.06Zm-2.025 7.587h-2.675V9.422h2.675v6.739Z" />
      <path d="M120.616.397h-7.222v.848h1.756c0 1.724-.031 3.614-2.184 5.402l1.443 1.357c2.018-1.873 2.526-3.532 2.529-6.742h1.653v5.612s.059.309-.324.309h-1.509v.76h3.224a.871.871 0 0 0 .617-.228.826.826 0 0 0 .264-.59V.615a.25.25 0 0 0-.247-.218ZM25.514 2.718l3.291.814a.182.182 0 0 0 .162-.03.174.174 0 0 0 .068-.146V2.189a.315.315 0 0 0-.069-.18.328.328 0 0 0-.161-.109l-3.291-.814a.182.182 0 0 0-.165.027.173.173 0 0 0-.068.15v1.166c.003.066.027.13.068.182.042.051.1.089.165.107Zm3.52 3.695V5.246a.311.311 0 0 0-.067-.18.324.324 0 0 0-.162-.109l-3.291-.814a.182.182 0 0 0-.163.026.174.174 0 0 0-.07.147v1.167c.003.066.027.13.069.182.041.053.099.091.164.11l3.291.814a.182.182 0 0 0 .167-.032.173.173 0 0 0 .063-.154v.01ZM7.467 3.267h.671a.243.243 0 0 0 .168-.07.231.231 0 0 0 .069-.164V1.527a.231.231 0 0 0-.07-.164.243.243 0 0 0-.167-.07h-.671a.242.242 0 0 0-.169.069.23.23 0 0 0-.068.165v1.496a.226.226 0 0 0 .067.164.237.237 0 0 0 .166.07l.004.01Zm14.61-1.96h-8.042v2.429c0 3.776-1.256 5.697-1.256 5.697h2.839s1.273-1.29 1.273-5.43V1.898h2.553v4.75c0 4.51-4.341 10.803-4.341 10.803H18.4s3.917-5.598 3.917-10.396V1.53a.231.231 0 0 0-.07-.164.243.243 0 0 0-.167-.07l-.003.01ZM10.1 4.576a.234.234 0 0 0-.071-.158.246.246 0 0 0-.162-.07H4.749V1.297H1.875V4.35H0v.567h1.875v11.068s-.212 1.479 1.506 1.479h6.722v-.865H5.58s-.838.088-.838-.916V4.906h2.564V9.44h2.783V4.577h.01Zm24.578-3.216H32.02s.585 9.29-5.357 14.983l2.522 1.642c5.469-5.333 5.73-14.773 5.733-16.408v-.03a.226.226 0 0 0-.084-.138.237.237 0 0 0-.156-.05ZM9.087 3.274h.671a.242.242 0 0 0 .169-.068.23.23 0 0 0 .068-.166V1.527a.226.226 0 0 0-.068-.165.237.237 0 0 0-.169-.07h-.671a.243.243 0 0 0-.168.07.231.231 0 0 0-.069.165v1.496c0 .062.025.12.07.165a.243.243 0 0 0 .167.07v.016Zm32.353 3.02a.241.241 0 0 0-.184.092l-5.218 7.04h1.739a.247.247 0 0 0 .191-.091l.89-1.205v5.34h2.436V8.826l1.882-2.544h-1.74l.004.013Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h151v18H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default Logo

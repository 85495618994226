/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GuestAnalyticsDataResponse } from './guest-analytics-data-response'

/**
 *
 * @export
 * @interface GetPreShareImageGuidanceResponse
 */
export interface GetPreShareImageGuidanceResponse {
  /**
   *
   * @type {string}
   * @memberof GetPreShareImageGuidanceResponse
   */
  guidance: string
  /**
   *
   * @type {string}
   * @memberof GetPreShareImageGuidanceResponse
   */
  partyPaymentType: GetPreShareImageGuidanceResponsePartyPaymentType
  /**
   *
   * @type {boolean}
   * @memberof GetPreShareImageGuidanceResponse
   */
  isUsingPayment: boolean
  /**
   *
   * @type {GuestAnalyticsDataResponse}
   * @memberof GetPreShareImageGuidanceResponse
   */
  analytics: GuestAnalyticsDataResponse
}

/**
 * @export
 * @enum {string}
 */
export enum GetPreShareImageGuidanceResponsePartyPaymentType {
  goshugi = 'GOSHUGI',
  kaihi = 'KAIHI',
}

import { Drawer } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import Modal from '@mui/material/Modal'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme()

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
export const ModalContainer = ({ children, open, ...props }) => (
  <ThemeProvider theme={theme}>
    <Modal open={open} BackdropProps={{ sx: { backgroundColor: 'rgba(0, 0, 0, 0.3)' } }} {...props}>
      {children}
    </Modal>
  </ThemeProvider>
)

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
export const DialogContainer = ({ children, open, ...props }) => (
  <ThemeProvider theme={theme}>
    <Dialog
      open={open}
      BackdropProps={{ sx: { backgroundColor: 'rgba(0, 0, 0, 0.3)' } }}
      {...props}
    >
      {children}
    </Dialog>
  </ThemeProvider>
)

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
export const DrawerContainer = ({ children, anchor, open, ...props }) => (
  <ThemeProvider theme={theme}>
    <Drawer anchor={anchor} open={open} {...props}>
      {children}
    </Drawer>
  </ThemeProvider>
)

import React, { createContext, useContext, useState } from 'react'

type Client = {
  clientName: string
  clientCd: string
  sponsorCd: string
  existsOtherClient: boolean
}

type ClientContextType = {
  client: Client
  setClient: (_: Client) => void
}

const ClientContext = createContext<ClientContextType>({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS2322 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  client: null,
  setClient: (_: Client) => {},
})

interface Props {
  client: Client
  setClient: (_: Client) => void
}

export const ClientContextProvider: React.FC<Props> = ({ client, setClient, children }) => (
  <ClientContext.Provider value={{ client, setClient }}>{children}</ClientContext.Provider>
)

export const useClientContext = () => useContext(ClientContext)

export const useClientFactory = (): ClientContextType => {
  const [client, setClient] = useState(null)
  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2322 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
    client,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2322 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
    setClient,
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GuestListUpAnalyticsDataResponse } from './guest-list-up-analytics-data-response'
import { GuestListUpPartnerInvitation } from './guest-list-up-partner-invitation'

/**
 *
 * @export
 * @interface GetGuestListUpPartnerInvitationResponse
 */
export interface GetGuestListUpPartnerInvitationResponse {
  /**
   *
   * @type {string}
   * @memberof GetGuestListUpPartnerInvitationResponse
   */
  status: GetGuestListUpPartnerInvitationResponseStatus
  /**
   *
   * @type {GuestListUpPartnerInvitation}
   * @memberof GetGuestListUpPartnerInvitationResponse
   */
  partnerInvitation?: GuestListUpPartnerInvitation
  /**
   *
   * @type {GuestListUpAnalyticsDataResponse}
   * @memberof GetGuestListUpPartnerInvitationResponse
   */
  guestListUpAnalyticsDataResponse: GuestListUpAnalyticsDataResponse
}

/**
 * @export
 * @enum {string}
 */
export enum GetGuestListUpPartnerInvitationResponseStatus {
  invitingPartner = 'INVITING_PARTNER',
  invitedPartner = 'INVITED_PARTNER',
  notInvitePartner = 'NOT_INVITE_PARTNER',
}

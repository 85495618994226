import { colors } from '@/styles/colors'
import MuiButton from '@mui/material/Button'
import { styled } from '@mui/material/styles'

export const PrimaryButton = styled(MuiButton)({
  height: '56px',
  backgroundColor: colors.COLOR_EB6D7B,
  borderRadius: '30px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '20px',
  lineHeight: '120%',
  color: colors.COLOR_FFFFFF,
  boxShadow: 'none !important',
  ':hover': {
    backgroundColor: colors.COLOR_EB6D7B,
    opacity: 0.7,
  },
  '&.Mui-disabled': {
    color: colors.COLOR_FFFFFF,
    backgroundColor: colors.COLOR_EB6D7B,
    opacity: 0.3,
  },
})

/** カレンダーでの日付選択時など局所的に使用 */
export const PrimaryButtonMini = styled(MuiButton)({
  minWidth: '46px',
  height: '25px',
  backgroundColor: colors.COLOR_EA6077,
  borderRadius: '20px',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '1.5',
  color: colors.COLOR_FFFFFF,
  ':hover': {
    backgroundColor: colors.COLOR_EA6077,
    opacity: 0.7,
  },
  '&.Mui-disabled': {
    color: colors.COLOR_FFFFFF,
    backgroundColor: colors.COLOR_EA6077,
    opacity: 0.3,
  },
})

PrimaryButton.defaultProps = {
  variant: 'contained',
}

export const PrimaryButtonText = styled(MuiButton)({
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '100%',
  color: colors.COLOR_EA6077,
  ':hover': {
    opacity: 0.7,
  },
})

export const SecondaryButton = styled(MuiButton)({
  height: '56px',
  borderRadius: '30px',
  borderColor: colors.COLOR_EB6D7B,
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '20px',
  lineHeight: '120%',
  color: colors.COLOR_EB6D7B,
  ':hover': {
    backgroundColor: colors.COLOR_FFFFFF,
    borderColor: colors.COLOR_EB6D7B,
    opacity: 0.7,
  },
  '&.Mui-disabled': {
    color: colors.COLOR_EB6D7B,
    borderColor: colors.COLOR_EB6D7B,
    opacity: 0.3,
  },
})

SecondaryButton.defaultProps = {
  variant: 'outlined',
}

export const SimpleButton = styled(MuiButton)({
  borderRadius: '22px',
  fontSize: '14px',
  height: '32px',
  padding: '8px 20px 8px 20px',
  color: colors.COLOR_8D8D8D,
  backgroundColor: colors.COLOR_FFFFFF,
  border: `1px solid ${colors.COLOR_D8D8D8}`,
  boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)',
  textTransform: 'none',
  ':hover': {
    backgroundColor: colors.COLOR_FFFFFF,
    borderColor: colors.COLOR_D8D8D8,
    opacity: 0.7,
  },
  '&.Mui-disabled': {
    backgroundColor: colors.COLOR_FFFFFF,
    borderColor: colors.COLOR_D8D8D8,
    opacity: 0.3,
  },
})

export const SimpleButtonSmall = styled(SimpleButton)({
  borderRadius: '22px',
  fontSize: '14px',
  height: '37px',
  padding: '8px 20px',
})

export const SimpleButtonMini = styled(SimpleButton)({
  borderRadius: '14px',
  fontSize: '14px',
  height: '25px',
  padding: '4px 10px 4px 10px',
  boxShadow: 'none',
  color: colors.COLOR_595959,
})

export const SimpleButtonLarge = styled(SimpleButton)({
  borderRadius: '30px',
  fontSize: '20px',
  height: '56px',
  boxShadow: 'none',
})

SimpleButton.defaultProps = {
  variant: 'outlined',
}

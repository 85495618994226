/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { ConnectConfirmIfAuthenticatedRequest } from '../model'
// @ts-ignore
import { ConnectConfirmIfAuthenticatedResponse } from '../model'
// @ts-ignore
import { ConnectGuestsToOneWRequest } from '../model'
// @ts-ignore
import { ConnectGuestsToOneWResponse } from '../model'
// @ts-ignore
import { ErrorResponse } from '../model'
// @ts-ignore
import { GetOneWConnectGuestsConfirmRequest } from '../model'
// @ts-ignore
import { GetOneWConnectGuestsConfirmResponse } from '../model'
// @ts-ignore
import { SaveOneWAccessTokenRequest } from '../model'
// @ts-ignore
import { SaveOneWAccessTokenResponse } from '../model'
/**
 * OneWControllerApi - axios parameter creator
 * @export
 */
export const OneWControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {ConnectConfirmIfAuthenticatedRequest} connectConfirmIfAuthenticatedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    connectConfirmIfAuthenticated: async (
      connectConfirmIfAuthenticatedRequest: ConnectConfirmIfAuthenticatedRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'connectConfirmIfAuthenticatedRequest' is not null or undefined
      assertParamExists(
        'connectConfirmIfAuthenticated',
        'connectConfirmIfAuthenticatedRequest',
        connectConfirmIfAuthenticatedRequest,
      )
      const localVarPath = `/cs/api/onew/connectConfirmIfAuthenticated`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        connectConfirmIfAuthenticatedRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ConnectGuestsToOneWRequest} connectGuestsToOneWRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    connectGuestsToOneW: async (
      connectGuestsToOneWRequest: ConnectGuestsToOneWRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'connectGuestsToOneWRequest' is not null or undefined
      assertParamExists(
        'connectGuestsToOneW',
        'connectGuestsToOneWRequest',
        connectGuestsToOneWRequest,
      )
      const localVarPath = `/cs/api/onew/connectGuestsToOneW`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        connectGuestsToOneWRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetOneWConnectGuestsConfirmRequest} getOneWConnectGuestsConfirmRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOneWConnectGuestsConfirm: async (
      getOneWConnectGuestsConfirmRequest: GetOneWConnectGuestsConfirmRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getOneWConnectGuestsConfirmRequest' is not null or undefined
      assertParamExists(
        'getOneWConnectGuestsConfirm',
        'getOneWConnectGuestsConfirmRequest',
        getOneWConnectGuestsConfirmRequest,
      )
      const localVarPath = `/cs/api/onew/getOneWConnectGuestsConfirm`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getOneWConnectGuestsConfirmRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {SaveOneWAccessTokenRequest} saveOneWAccessTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveOneWAccessToken: async (
      saveOneWAccessTokenRequest: SaveOneWAccessTokenRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'saveOneWAccessTokenRequest' is not null or undefined
      assertParamExists(
        'saveOneWAccessToken',
        'saveOneWAccessTokenRequest',
        saveOneWAccessTokenRequest,
      )
      const localVarPath = `/cs/api/onew/saveOneWAccessToken`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveOneWAccessTokenRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * OneWControllerApi - functional programming interface
 * @export
 */
export const OneWControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OneWControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {ConnectConfirmIfAuthenticatedRequest} connectConfirmIfAuthenticatedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async connectConfirmIfAuthenticated(
      connectConfirmIfAuthenticatedRequest: ConnectConfirmIfAuthenticatedRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ConnectConfirmIfAuthenticatedResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.connectConfirmIfAuthenticated(
        connectConfirmIfAuthenticatedRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {ConnectGuestsToOneWRequest} connectGuestsToOneWRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async connectGuestsToOneW(
      connectGuestsToOneWRequest: ConnectGuestsToOneWRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectGuestsToOneWResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.connectGuestsToOneW(
        connectGuestsToOneWRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetOneWConnectGuestsConfirmRequest} getOneWConnectGuestsConfirmRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOneWConnectGuestsConfirm(
      getOneWConnectGuestsConfirmRequest: GetOneWConnectGuestsConfirmRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetOneWConnectGuestsConfirmResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOneWConnectGuestsConfirm(
        getOneWConnectGuestsConfirmRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {SaveOneWAccessTokenRequest} saveOneWAccessTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveOneWAccessToken(
      saveOneWAccessTokenRequest: SaveOneWAccessTokenRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveOneWAccessTokenResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveOneWAccessToken(
        saveOneWAccessTokenRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * OneWControllerApi - factory interface
 * @export
 */
export const OneWControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OneWControllerApiFp(configuration)
  return {
    /**
     *
     * @param {ConnectConfirmIfAuthenticatedRequest} connectConfirmIfAuthenticatedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    connectConfirmIfAuthenticated(
      connectConfirmIfAuthenticatedRequest: ConnectConfirmIfAuthenticatedRequest,
      options?: any,
    ): AxiosPromise<ConnectConfirmIfAuthenticatedResponse> {
      return localVarFp
        .connectConfirmIfAuthenticated(connectConfirmIfAuthenticatedRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {ConnectGuestsToOneWRequest} connectGuestsToOneWRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    connectGuestsToOneW(
      connectGuestsToOneWRequest: ConnectGuestsToOneWRequest,
      options?: any,
    ): AxiosPromise<ConnectGuestsToOneWResponse> {
      return localVarFp
        .connectGuestsToOneW(connectGuestsToOneWRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetOneWConnectGuestsConfirmRequest} getOneWConnectGuestsConfirmRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOneWConnectGuestsConfirm(
      getOneWConnectGuestsConfirmRequest: GetOneWConnectGuestsConfirmRequest,
      options?: any,
    ): AxiosPromise<GetOneWConnectGuestsConfirmResponse> {
      return localVarFp
        .getOneWConnectGuestsConfirm(getOneWConnectGuestsConfirmRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {SaveOneWAccessTokenRequest} saveOneWAccessTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveOneWAccessToken(
      saveOneWAccessTokenRequest: SaveOneWAccessTokenRequest,
      options?: any,
    ): AxiosPromise<SaveOneWAccessTokenResponse> {
      return localVarFp
        .saveOneWAccessToken(saveOneWAccessTokenRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * OneWControllerApi - object-oriented interface
 * @export
 * @class OneWControllerApi
 * @extends {BaseAPI}
 */
export class OneWControllerApi extends BaseAPI {
  /**
   *
   * @param {ConnectConfirmIfAuthenticatedRequest} connectConfirmIfAuthenticatedRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OneWControllerApi
   */
  public connectConfirmIfAuthenticated(
    connectConfirmIfAuthenticatedRequest: ConnectConfirmIfAuthenticatedRequest,
    options?: AxiosRequestConfig,
  ) {
    return OneWControllerApiFp(this.configuration)
      .connectConfirmIfAuthenticated(connectConfirmIfAuthenticatedRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ConnectGuestsToOneWRequest} connectGuestsToOneWRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OneWControllerApi
   */
  public connectGuestsToOneW(
    connectGuestsToOneWRequest: ConnectGuestsToOneWRequest,
    options?: AxiosRequestConfig,
  ) {
    return OneWControllerApiFp(this.configuration)
      .connectGuestsToOneW(connectGuestsToOneWRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetOneWConnectGuestsConfirmRequest} getOneWConnectGuestsConfirmRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OneWControllerApi
   */
  public getOneWConnectGuestsConfirm(
    getOneWConnectGuestsConfirmRequest: GetOneWConnectGuestsConfirmRequest,
    options?: AxiosRequestConfig,
  ) {
    return OneWControllerApiFp(this.configuration)
      .getOneWConnectGuestsConfirm(getOneWConnectGuestsConfirmRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {SaveOneWAccessTokenRequest} saveOneWAccessTokenRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OneWControllerApi
   */
  public saveOneWAccessToken(
    saveOneWAccessTokenRequest: SaveOneWAccessTokenRequest,
    options?: AxiosRequestConfig,
  ) {
    return OneWControllerApiFp(this.configuration)
      .saveOneWAccessToken(saveOneWAccessTokenRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

import { useCoupleUserContext } from '@/context/cs/session'
import { useError } from '@/context/error'
import { useEffect } from 'react'

type LayoutType = {
  children: React.ReactNode
}
/**
 * Contextの認証状態を確認して、
 * 認証されていない場合は子Componentを表示させない制御とログイン画面への遷移を提供します。
 *
 * ※本コンポーネントはCoupleUserContextを利用するため、
 * 上位のComponentにAuthenticationコンポーネントが必要です。
 */
export default function ForceAuthentication({ children }: LayoutType) {
  const { user, error } = useCoupleUserContext()
  const { setError } = useError()
  useEffect(() => {
    if (error) {
      setError(error.statusCode)
    }
  }, [error])
  return <>{user && children}</>
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface SaveOneWAccessTokenRequest
 */
export interface SaveOneWAccessTokenRequest {
  /**
   *
   * @type {string}
   * @memberof SaveOneWAccessTokenRequest
   */
  accessToken: string
  /**
   *
   * @type {string}
   * @memberof SaveOneWAccessTokenRequest
   */
  expiredAt: string
  /**
   *
   * @type {string}
   * @memberof SaveOneWAccessTokenRequest
   */
  state: string
  /**
   *
   * @type {string}
   * @memberof SaveOneWAccessTokenRequest
   */
  action: SaveOneWAccessTokenRequestAction
  /**
   *
   * @type {string}
   * @memberof SaveOneWAccessTokenRequest
   */
  guestUUID?: string
}

/**
 * @export
 * @enum {string}
 */
export enum SaveOneWAccessTokenRequestAction {
  allGuests = 'CONNECT_ALL_GUESTS',
  singleGuest = 'CONNECT_SINGLE_GUEST',
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GuestAnalyticsDataResponse } from './guest-analytics-data-response'

/**
 *
 * @export
 * @interface AddResponseResponse
 */
export interface AddResponseResponse {
  /**
   *
   * @type {string}
   * @memberof AddResponseResponse
   */
  responseUUID: string
  /**
   *
   * @type {string}
   * @memberof AddResponseResponse
   */
  afterAddResponse: AddResponseResponseAfterAddResponse
  /**
   *
   * @type {string}
   * @memberof AddResponseResponse
   */
  guestUUID: string
  /**
   *
   * @type {GuestAnalyticsDataResponse}
   * @memberof AddResponseResponse
   */
  analytics: GuestAnalyticsDataResponse
}

/**
 * @export
 * @enum {string}
 */
export enum AddResponseResponseAfterAddResponse {
  payment = 'PAYMENT',
  completeResponseUsingImageShare = 'COMPLETE_RESPONSE_USING_IMAGE_SHARE',
  completeResponse = 'COMPLETE_RESPONSE',
  alreadyPaid = 'ALREADY_PAID',
  alreadyPaidUsingImageShare = 'ALREADY_PAID_USING_IMAGE_SHARE',
}

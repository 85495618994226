/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CoupleAnalyticsDataResponse } from './couple-analytics-data-response'
import { InvitationInfo } from './invitation-info'
import { PartyNotificationResponse } from './party-notification-response'
import { ResponsePaymentSummary } from './response-payment-summary'

/**
 *
 * @export
 * @interface GetPartyResponse
 */
export interface GetPartyResponse {
  /**
   *
   * @type {string}
   * @memberof GetPartyResponse
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetPartyResponse
   */
  clientCd: string
  /**
   *
   * @type {string}
   * @memberof GetPartyResponse
   */
  clientName: string
  /**
   *
   * @type {string}
   * @memberof GetPartyResponse
   */
  coupleID: string
  /**
   *
   * @type {string}
   * @memberof GetPartyResponse
   */
  holdDate: string
  /**
   *
   * @type {string}
   * @memberof GetPartyResponse
   */
  partyState: GetPartyResponsePartyState
  /**
   *
   * @type {string}
   * @memberof GetPartyResponse
   */
  partyType: GetPartyResponsePartyType
  /**
   *
   * @type {string}
   * @memberof GetPartyResponse
   */
  responseDeadline?: string
  /**
   *
   * @type {boolean}
   * @memberof GetPartyResponse
   */
  isAfterResponseDeadline: boolean
  /**
   *
   * @type {number}
   * @memberof GetPartyResponse
   */
  numberOfGuests?: number
  /**
   *
   * @type {number}
   * @memberof GetPartyResponse
   */
  attendanceGuestCount: number
  /**
   *
   * @type {number}
   * @memberof GetPartyResponse
   */
  absenceGuestCount: number
  /**
   *
   * @type {PartyNotificationResponse}
   * @memberof GetPartyResponse
   */
  partyNotification: PartyNotificationResponse
  /**
   *
   * @type {Array<InvitationInfo>}
   * @memberof GetPartyResponse
   */
  invitations: Array<InvitationInfo>
  /**
   *
   * @type {ResponsePaymentSummary}
   * @memberof GetPartyResponse
   */
  responsePaymentSummary?: ResponsePaymentSummary
  /**
   *
   * @type {boolean}
   * @memberof GetPartyResponse
   */
  hasPaymentToClientRequest: boolean
  /**
   *
   * @type {boolean}
   * @memberof GetPartyResponse
   */
  hasOnlyDraftPrePartyImageShareSetting: boolean
  /**
   *
   * @type {boolean}
   * @memberof GetPartyResponse
   */
  hasPublishedOrSuspendedPrePartyImageShareSetting: boolean
  /**
   *
   * @type {CoupleAnalyticsDataResponse}
   * @memberof GetPartyResponse
   */
  coupleAnalyticsDataResponse: CoupleAnalyticsDataResponse
}

/**
 * @export
 * @enum {string}
 */
export enum GetPartyResponsePartyState {
  noParty = 'NO_PARTY',
  noInvitation = 'NO_INVITATION',
  onlyDraftInvitationOnlyUncompletedInvitation = 'ONLY_DRAFT_INVITATION_ONLY_UNCOMPLETED_INVITATION',
  onlyDraftInvitationHasCompletedInvitation = 'ONLY_DRAFT_INVITATION_HAS_COMPLETED_INVITATION',
  hasNotPublishedInvitationAndHasSuspendedInvitation = 'HAS_NOT_PUBLISHED_INVITATION_AND_HAS_SUSPENDED_INVITATION',
  hasPublishedInvitationBeforeDeadline = 'HAS_PUBLISHED_INVITATION_BEFORE_DEADLINE',
  hasPublishedInvitationAfterDeadline = 'HAS_PUBLISHED_INVITATION_AFTER_DEADLINE',
  hasPublishedInvitationEndOfPublication = 'HAS_PUBLISHED_INVITATION_END_OF_PUBLICATION',
}
/**
 * @export
 * @enum {string}
 */
export enum GetPartyResponsePartyType {
  goshugi = 'GOSHUGI',
  kaihi = 'KAIHI',
  noSetting = 'NO_SETTING',
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { ErrorResponse } from '../model'
// @ts-ignore
import { GetCustomResponseSettingRequest } from '../model'
// @ts-ignore
import { GetCustomResponseSettingResponse } from '../model'
// @ts-ignore
import { UpdateCustomResponseSettingRequest } from '../model'
/**
 * ClientCustomResponseSettingControllerApi - axios parameter creator
 * @export
 */
export const ClientCustomResponseSettingControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {GetCustomResponseSettingRequest} getCustomResponseSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomResponseSetting: async (
      getCustomResponseSettingRequest: GetCustomResponseSettingRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getCustomResponseSettingRequest' is not null or undefined
      assertParamExists(
        'getCustomResponseSetting',
        'getCustomResponseSettingRequest',
        getCustomResponseSettingRequest,
      )
      const localVarPath = `/cl/api/getCustomResponseSetting`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getCustomResponseSettingRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateCustomResponseSettingRequest} updateCustomResponseSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCustomResponseSetting: async (
      updateCustomResponseSettingRequest: UpdateCustomResponseSettingRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateCustomResponseSettingRequest' is not null or undefined
      assertParamExists(
        'updateCustomResponseSetting',
        'updateCustomResponseSettingRequest',
        updateCustomResponseSettingRequest,
      )
      const localVarPath = `/cl/api/updateCustomResponseSetting`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCustomResponseSettingRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ClientCustomResponseSettingControllerApi - functional programming interface
 * @export
 */
export const ClientCustomResponseSettingControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ClientCustomResponseSettingControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {GetCustomResponseSettingRequest} getCustomResponseSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomResponseSetting(
      getCustomResponseSettingRequest: GetCustomResponseSettingRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomResponseSettingResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomResponseSetting(
        getCustomResponseSettingRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateCustomResponseSettingRequest} updateCustomResponseSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCustomResponseSetting(
      updateCustomResponseSettingRequest: UpdateCustomResponseSettingRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomResponseSetting(
        updateCustomResponseSettingRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ClientCustomResponseSettingControllerApi - factory interface
 * @export
 */
export const ClientCustomResponseSettingControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ClientCustomResponseSettingControllerApiFp(configuration)
  return {
    /**
     *
     * @param {GetCustomResponseSettingRequest} getCustomResponseSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomResponseSetting(
      getCustomResponseSettingRequest: GetCustomResponseSettingRequest,
      options?: any,
    ): AxiosPromise<GetCustomResponseSettingResponse> {
      return localVarFp
        .getCustomResponseSetting(getCustomResponseSettingRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateCustomResponseSettingRequest} updateCustomResponseSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCustomResponseSetting(
      updateCustomResponseSettingRequest: UpdateCustomResponseSettingRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .updateCustomResponseSetting(updateCustomResponseSettingRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ClientCustomResponseSettingControllerApi - object-oriented interface
 * @export
 * @class ClientCustomResponseSettingControllerApi
 * @extends {BaseAPI}
 */
export class ClientCustomResponseSettingControllerApi extends BaseAPI {
  /**
   *
   * @param {GetCustomResponseSettingRequest} getCustomResponseSettingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomResponseSettingControllerApi
   */
  public getCustomResponseSetting(
    getCustomResponseSettingRequest: GetCustomResponseSettingRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomResponseSettingControllerApiFp(this.configuration)
      .getCustomResponseSetting(getCustomResponseSettingRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateCustomResponseSettingRequest} updateCustomResponseSettingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomResponseSettingControllerApi
   */
  public updateCustomResponseSetting(
    updateCustomResponseSettingRequest: UpdateCustomResponseSettingRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomResponseSettingControllerApiFp(this.configuration)
      .updateCustomResponseSetting(updateCustomResponseSettingRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

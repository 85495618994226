import { ErrorHead } from '@/components/common/commonHead'
import Footer from '@/components/guest/invitations/footer'
import { errorPageStyles } from '@/styles/guest/errorPageStyle'
import { GUEST_EXTERNAL_WEB_URL } from '@/utils/common/externalWebURL'

export default function GuestServerError() {
  return (
    <div css={errorPageStyles.root}>
      <ErrorHead />
      <div css={errorPageStyles.main}>
        <div css={[errorPageStyles.container, errorPageStyles.serverContainer]}>
          <h1 css={errorPageStyles.title}>
            メンテナンス中
            <br />
            もしくはアクセス集中のため
            <br />
            表示できません
          </h1>
          <p css={errorPageStyles.text}>
            詳細については
            <br css={errorPageStyles.onlySp} />
            <a css={errorPageStyles.link} href={GUEST_EXTERNAL_WEB_URL.FAQ}>
              最新のお知らせ情報
            </a>
            をご確認ください。
          </p>
        </div>
      </div>
      <div css={errorPageStyles.footer}>
        <Footer pattern="white" />
      </div>
    </div>
  )
}

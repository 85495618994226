import ClarityScript from '@/components/common/clarityScriptProvider'
import GoogleTagManager from '@/components/common/googleTagManager'
import { KarteMeasurementTag } from '@/components/common/karte/karteTagProvider'
import { Config, ConfigContextProvider, useConfigFactory } from '@/context/config'
import { ErrorContextProvider, useError, useErrorFactory } from '@/context/error'
import { LoadingContextProvider, useLoadingFactory } from '@/context/loading'
import DefaultError from '@/pages/_error'
import { globalStyles } from '@/styles/globalStyle'
import { FEATURE_TOGGLE_TYPE, isDevelopment } from '@/utils/common/featureToggle'
import { NextComponentType, NextPageContext } from 'next'
import React from 'react'
import { SWRConfig } from 'swr'

type AppProps = {
  config: Config
  pageProps: any
  Component: NextComponentType<NextPageContext, any, any> & {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
    getLayout: ({ page, ...props }) => React.Component
    layoutProps: any
  }
}

const DefaultLayout = ({ Component, pageProps }: AppProps) => {
  const { statusCode, errorCode } = useError()
  if (statusCode || errorCode) {
    return <DefaultError statusCode={statusCode} errorCode={errorCode} />
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  const Layout = Component.getLayout ? Component.getLayout : ({ page }) => page
  const layoutProps = Component.layoutProps ? Component.layoutProps : null
  return Layout({
    page: <Component {...pageProps} />,
    ...layoutProps,
  })
}

export default function App(props: AppProps) {
  const { config } = props
  const loadingCtx = useLoadingFactory()
  const errorCtx = useErrorFactory()
  const configCtx = useConfigFactory(config)
  return (
    <>
      <GoogleTagManager googleTagManagerId={configCtx.config.gtm} />
      {globalStyles}
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          dedupingInterval: 3000,
          focusThrottleInterval: 0,
        }}
      >
        <ConfigContextProvider {...configCtx}>
          <KarteMeasurementTag />
          {isDevelopment(configCtx.config.featureToggle) && (
            <ClarityScript clarityId={configCtx.config.clarityId} />
          )}
          <ErrorContextProvider {...errorCtx}>
            <LoadingContextProvider {...loadingCtx}>
              <DefaultLayout {...props} />
            </LoadingContextProvider>
          </ErrorContextProvider>
        </ConfigContextProvider>
      </SWRConfig>
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
App.getInitialProps = async ({ ctx }) => {
  if (!ctx.req) return {}
  const createConfig = () => {
    const env = process.env.APP_ENV
    switch (env) {
      case 'dev':
        return {
          featureToggle: FEATURE_TOGGLE_TYPE.dev,
          gtm: 'GTM-WM2DRDJ',
          clarityId: 'lie9v13jq0',
          clUserManual:
            'https://wwwtst.cszebra.zexy.net/z/contents/manual/pdf/xyinvitaiton_startedguide.pdf',
          clZebraUrl: 'https://wwwtst.cszebra.zexy.net/id/login/',
          // dev2 と ローカルで適用される設定となっており、 dev2 を優先するためローカルからも dev2 を参照するようになっている
          // 必要に応じて設定を変えるもしくは設定を増やす方針を対応する
          serviceUrl: 'https://dev2.xyinvitaiton-dev.com',
          coupleLpUrl: 'https://preview.studio.site/live/xmaZYlNJqR',
          csInvitationGuidanceArticleUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/knowledge_text',
          csGoshugiExperiencesArticleUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/usercase_realvoice3',
          csGoshugiResearchArticleUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/knowledge_guest2',
          csImageShareManualUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/guide/createinvitation_photosharing',
          csInstagramCampaignUrl: 'https://preview.studio.site/live/xmaZYlNJqR/instagram_campaign',
          csGuestListGuideUrl: 'https://preview.studio.site/live/xmaZYlNJqR/guide/checkguest',
          guestGoshugiQuestionsUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/knowledge_guest1',
          coupleGuestListUpServiceTermUrl:
            'https://preview.p.recruit.co.jp/terms/xys-t-1012/index.html',
        }
      case 'demo':
        return {
          featureToggle: FEATURE_TOGGLE_TYPE.demo,
          gtm: 'GTM-WM2DRDJ',
          clarityId: 'invalid-id', // TODO: 本番環境で利用 Ready になった際には発行されたIDを設定する
          clUserManual:
            'https://wwwtst.cszebra.zexy.net/z/contents/manual/pdf/xyinvitaiton_startedguide.pdf',
          clZebraUrl: 'https://wwwtst.cszebra.zexy.net/id/login/',
          serviceUrl: 'https://dev3.xyinvitaiton-dev.com',
          coupleLpUrl: 'https://preview.studio.site/live/xmaZYlNJqR',
          csInvitationGuidanceArticleUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/knowledge_text',
          csGoshugiExperiencesArticleUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/usercase_realvoice3',
          csGoshugiResearchArticleUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/knowledge_guest2',
          csImageShareManualUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/guide/createinvitation_photosharing',
          csInstagramCampaignUrl: 'https://preview.studio.site/live/xmaZYlNJqR/instagram_campaign',
          csGuestListGuideUrl: 'https://preview.studio.site/live/xmaZYlNJqR/guide/checkguest',
          guestGoshugiQuestionsUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/knowledge_guest1',
          coupleGuestListUpServiceTermUrl:
            'https://preview.p.recruit.co.jp/terms/xys-t-1012/index.html',
        }
      case 'stg':
        return {
          featureToggle: FEATURE_TOGGLE_TYPE.stg,
          gtm: 'GTM-WM2DRDJ',
          clarityId: 'invalid-id', // TODO: 本番環境で利用 Ready になった際には発行されたIDを設定する
          clUserManual:
            'https://wwwtst.cszebra.zexy.net/z/contents/manual/pdf/xyinvitaiton_startedguide.pdf',
          clZebraUrl: 'https://wwwtst.cszebra.zexy.net/id/login/',
          serviceUrl: 'https://dev1.xyinvitaiton-dev.com',
          coupleLpUrl: 'https://preview.studio.site/live/xmaZYlNJqR',
          csInvitationGuidanceArticleUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/knowledge_text',
          csGoshugiExperiencesArticleUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/usercase_realvoice3',
          csGoshugiResearchArticleUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/knowledge_guest2',
          csImageShareManualUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/guide/createinvitation_photosharing',
          csInstagramCampaignUrl: 'https://preview.studio.site/live/xmaZYlNJqR/instagram_campaign',
          csGuestListGuideUrl: 'https://preview.studio.site/live/xmaZYlNJqR/guide/checkguest',
          guestGoshugiQuestionsUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/knowledge_guest1',
          coupleGuestListUpServiceTermUrl:
            'https://preview.p.recruit.co.jp/terms/xys-t-1012/index.html',
        }
      default:
        return {
          featureToggle: FEATURE_TOGGLE_TYPE.prd,
          gtm: 'GTM-WM2DRDJ',
          clarityId: 'invalid-id', // TODO: 本番環境で利用 Ready になった際には発行されたIDを設定する
          clUserManual:
            'https://cszebra.zexy.net/z/contents/manual/pdf/xyinvitaiton_startedguide.pdf',
          clZebraUrl: 'https://cszebra.zexy.net/id/login/',
          serviceUrl: 'https://online.zexy.net',
          coupleLpUrl: 'https://introduction.online.zexy.net',
          csInvitationGuidanceArticleUrl:
            'https://introduction.online.zexy.net/article/knowledge_text',
          csGoshugiExperiencesArticleUrl:
            'https://introduction.online.zexy.net/article/usercase_realvoice3',
          csGoshugiResearchArticleUrl:
            'https://introduction.online.zexy.net/article/knowledge_guest2',
          csImageShareManualUrl:
            'https://introduction.online.zexy.net/guide/createinvitation_photosharing',
          csInstagramCampaignUrl: 'https://introduction.online.zexy.net/instagram_campaign',
          csGuestListGuideUrl: 'https://introduction.online.zexy.net/guide/checkguest',
          guestGoshugiQuestionsUrl: 'https://introduction.online.zexy.net/article/knowledge_guest1',
          coupleGuestListUpServiceTermUrl:
            'https://cdn.p.recruit.co.jp/terms/xys-t-1012/index.html',
        }
    }
  }
  return {
    config: createConfig(),
  }
}

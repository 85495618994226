import { ErrorHead } from '@/components/common/commonHead'
import { LoggingErrorPageView } from '@/components/common/googleTagManager'
import { ErrorLayout, styles } from '@/components/cs/errorLayout'
import { useConfig } from '@/context/config'
import { CS_EXTERNAL_WEB_URL } from '@/utils/common/externalWebURL'
import Link from 'next/link'

export default function ServerError({ original_screen_name = 'cs_error_500' }) {
  const { config } = useConfig()
  return (
    <ErrorLayout>
      <ErrorHead />
      <LoggingErrorPageView original_screen_name={original_screen_name} />
      <h1 css={styles.head}>
        メンテナンス中
        <br />
        もしくはアクセス集中のため
        <br />
        表示できません
      </h1>
      <p css={styles.text}>
        詳細については
        <br />
        <Link href={CS_EXTERNAL_WEB_URL(config).FAQ} passHref>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a css={styles.link}>最新のお知らせ情報</a>
        </Link>
        をご覧ください。
      </p>
    </ErrorLayout>
  )
}

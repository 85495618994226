import { ClPageError } from '@/components/cl/errorLayout'
import { PageNotFoundErrorHead } from '@/components/common/commonHead'
import { loggingCommonPageView } from '@/components/common/googleTagManager'
import CSGuestListUpPageError from '@/components/couple/cs_guest_list_up/NotFound'
import CSPageError from '@/components/cs/page_error'
import { EntryPageError } from '@/components/entry/error/errorLayout'
import GuestPageError from '@/components/guest/errors/pageError'
import { colors } from '@/styles/colors'
import { breakpoints } from '@/styles/cs/styleConstants'
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'

enum Actor {
  CS = 1,
  CL = 2,
  Guest = 3,
  Entry = 4,
  CS_GUEST_LIST_UP = 5,
}

const getActor = () => {
  const path = window.location.pathname
  if (path.startsWith('/cs/') || path.startsWith('/ams/')) {
    return Actor.CS
  }
  if (path.startsWith('/cs_guest_list_up/')) {
    return Actor.CS_GUEST_LIST_UP
  }
  if (path.startsWith('/cl/')) {
    return Actor.CL
  }
  if (path.startsWith('/guest/')) {
    return Actor.Guest
  }
  if (path.startsWith('/entry/')) {
    return Actor.Entry
  }
  return null
}

export default function Page() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS2345 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  const [actor, setActor] = useState<Actor>(undefined)
  useEffect(() => {
    // 認証の検査
    const initialActor = getActor()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2345 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
    setActor(initialActor)
  }, [])

  const LoggingCommon404PageView = () => {
    // その他の404エラーページの表示ロギング
    loggingCommonPageView({
      original_screen_name: 'common_error_404',
    })
    return <></>
  }

  const HandledPageError = () => {
    if (actor === Actor.CL) return <ClPageError />
    if (actor === Actor.CS) return <CSPageError />
    if (actor === Actor.CS_GUEST_LIST_UP) return <CSGuestListUpPageError />
    if (actor === Actor.Guest) return <GuestPageError />
    if (actor === Actor.Entry) return <EntryPageError />
    return (
      <>
        <PageNotFoundErrorHead />
        <LoggingCommon404PageView />
        <div css={styles.root}>
          <div css={styles.main}>
            <div css={styles.container}>
              <h1>
                お探しのページが
                <br />
                見つかりませんでした
              </h1>
              <p style={{ marginTop: '16px' }}>
                URLが間違っているか、ページが移動、
                <br />
                もしくは削除されている可能性があります。
              </p>
            </div>
          </div>
        </div>
      </>
    )
  }

  return <>{actor !== undefined && <HandledPageError />}</>
}

const styles = {
  root: css`
    min-height: 100vh;
  `,
  main: css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 50px - 52px);
  `,
  container: css`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 707px;
    height: 280px;
    border: 3px solid ${colors.COLOR_EFEFEF};
    border-radius: 10px;
    @media (max-width: ${breakpoints.sp}px) {
      width: 87vw;
      min-width: 327px;
      max-width: 707px;
      height: 204px;
    }

    h1 {
      font-size: 20px;
      font-weight: bold;
      color: ${colors.COLOR_4B4B4B};
      text-align: center;
      @media (max-width: ${breakpoints.sp}px) {
        font-size: 18px;
      }
    }

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 160%;
      color: ${colors.COLOR_4B4B4B};
      text-align: center;
      @media (max-width: ${breakpoints.sp}px) {
        font-size: 12px;
      }
    }
  `,
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { ErrorResponse } from '../model'
// @ts-ignore
import { GetPartnerInvitationResponse } from '../model'
// @ts-ignore
import { InvitePartnerRequest } from '../model'
// @ts-ignore
import { InvitePartnerResponse } from '../model'
/**
 * PartnerInvitationControllerApi - axios parameter creator
 * @export
 */
export const PartnerInvitationControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelPartnerInvitation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cs/api/partner/cancelPartnerInvitation`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartnerInvitation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cs/api/partner/getPartnerInvitation`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {InvitePartnerRequest} invitePartnerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    invite: async (
      invitePartnerRequest: InvitePartnerRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'invitePartnerRequest' is not null or undefined
      assertParamExists('invite', 'invitePartnerRequest', invitePartnerRequest)
      const localVarPath = `/cs/api/partner/invite`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        invitePartnerRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {InvitePartnerRequest} invitePartnerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validatePartnerInvitation: async (
      invitePartnerRequest: InvitePartnerRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'invitePartnerRequest' is not null or undefined
      assertParamExists('validatePartnerInvitation', 'invitePartnerRequest', invitePartnerRequest)
      const localVarPath = `/cs/api/partner/validatePartnerInvitation`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        invitePartnerRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PartnerInvitationControllerApi - functional programming interface
 * @export
 */
export const PartnerInvitationControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PartnerInvitationControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelPartnerInvitation(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelPartnerInvitation(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPartnerInvitation(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPartnerInvitationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnerInvitation(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {InvitePartnerRequest} invitePartnerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async invite(
      invitePartnerRequest: InvitePartnerRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitePartnerResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.invite(
        invitePartnerRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {InvitePartnerRequest} invitePartnerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validatePartnerInvitation(
      invitePartnerRequest: InvitePartnerRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validatePartnerInvitation(
        invitePartnerRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * PartnerInvitationControllerApi - factory interface
 * @export
 */
export const PartnerInvitationControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PartnerInvitationControllerApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelPartnerInvitation(options?: any): AxiosPromise<object> {
      return localVarFp.cancelPartnerInvitation(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartnerInvitation(options?: any): AxiosPromise<GetPartnerInvitationResponse> {
      return localVarFp.getPartnerInvitation(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {InvitePartnerRequest} invitePartnerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    invite(
      invitePartnerRequest: InvitePartnerRequest,
      options?: any,
    ): AxiosPromise<InvitePartnerResponse> {
      return localVarFp
        .invite(invitePartnerRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {InvitePartnerRequest} invitePartnerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validatePartnerInvitation(
      invitePartnerRequest: InvitePartnerRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .validatePartnerInvitation(invitePartnerRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * PartnerInvitationControllerApi - object-oriented interface
 * @export
 * @class PartnerInvitationControllerApi
 * @extends {BaseAPI}
 */
export class PartnerInvitationControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PartnerInvitationControllerApi
   */
  public cancelPartnerInvitation(options?: AxiosRequestConfig) {
    return PartnerInvitationControllerApiFp(this.configuration)
      .cancelPartnerInvitation(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PartnerInvitationControllerApi
   */
  public getPartnerInvitation(options?: AxiosRequestConfig) {
    return PartnerInvitationControllerApiFp(this.configuration)
      .getPartnerInvitation(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {InvitePartnerRequest} invitePartnerRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PartnerInvitationControllerApi
   */
  public invite(invitePartnerRequest: InvitePartnerRequest, options?: AxiosRequestConfig) {
    return PartnerInvitationControllerApiFp(this.configuration)
      .invite(invitePartnerRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {InvitePartnerRequest} invitePartnerRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PartnerInvitationControllerApi
   */
  public validatePartnerInvitation(
    invitePartnerRequest: InvitePartnerRequest,
    options?: AxiosRequestConfig,
  ) {
    return PartnerInvitationControllerApiFp(this.configuration)
      .validatePartnerInvitation(invitePartnerRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ConnectInviteesCountSummary } from './connect-invitees-count-summary'
import { CoupleAnalyticsDataResponse } from './couple-analytics-data-response'
import { InviteeGroupForDisplay } from './invitee-group-for-display'

/**
 *
 * @export
 * @interface GetOneWConnectGuestsConfirmResponse
 */
export interface GetOneWConnectGuestsConfirmResponse {
  /**
   *
   * @type {string}
   * @memberof GetOneWConnectGuestsConfirmResponse
   */
  action: GetOneWConnectGuestsConfirmResponseAction
  /**
   *
   * @type {ConnectInviteesCountSummary}
   * @memberof GetOneWConnectGuestsConfirmResponse
   */
  inviteesCountSummary: ConnectInviteesCountSummary
  /**
   *
   * @type {InviteeGroupForDisplay}
   * @memberof GetOneWConnectGuestsConfirmResponse
   */
  inviteeGroup?: InviteeGroupForDisplay
  /**
   *
   * @type {Array<string>}
   * @memberof GetOneWConnectGuestsConfirmResponse
   */
  guestQuestions: Array<string>
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof GetOneWConnectGuestsConfirmResponse
   */
  connectGuestPlan: Array<Array<string>>
  /**
   *
   * @type {CoupleAnalyticsDataResponse}
   * @memberof GetOneWConnectGuestsConfirmResponse
   */
  coupleAnalyticsDataResponse: CoupleAnalyticsDataResponse
}

/**
 * @export
 * @enum {string}
 */
export enum GetOneWConnectGuestsConfirmResponseAction {
  allGuests = 'CONNECT_ALL_GUESTS',
  singleGuest = 'CONNECT_SINGLE_GUEST',
}

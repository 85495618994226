/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CoupleAnalyticsDataResponse } from './couple-analytics-data-response'

/**
 *
 * @export
 * @interface GetPaymentSettingResponse
 */
export interface GetPaymentSettingResponse {
  /**
   *
   * @type {string}
   * @memberof GetPaymentSettingResponse
   */
  paymentType: GetPaymentSettingResponsePaymentType
  /**
   *
   * @type {string}
   * @memberof GetPaymentSettingResponse
   */
  partyPaymentType: GetPaymentSettingResponsePartyPaymentType
  /**
   *
   * @type {number}
   * @memberof GetPaymentSettingResponse
   */
  kaihiAmount?: number
  /**
   *
   * @type {string}
   * @memberof GetPaymentSettingResponse
   */
  kaihiSupplementaryText: string
  /**
   *
   * @type {string}
   * @memberof GetPaymentSettingResponse
   */
  guidanceText?: string
  /**
   *
   * @type {boolean}
   * @memberof GetPaymentSettingResponse
   */
  isPublished: boolean
  /**
   *
   * @type {boolean}
   * @memberof GetPaymentSettingResponse
   */
  hasPublished: boolean
  /**
   *
   * @type {string}
   * @memberof GetPaymentSettingResponse
   */
  partyState: GetPaymentSettingResponsePartyState
  /**
   *
   * @type {CoupleAnalyticsDataResponse}
   * @memberof GetPaymentSettingResponse
   */
  coupleAnalyticsDataResponse: CoupleAnalyticsDataResponse
}

/**
 * @export
 * @enum {string}
 */
export enum GetPaymentSettingResponsePaymentType {
  notUsing = 'NOT_USING',
  using = 'USING',
  noSettings = 'NO_SETTINGS',
}
/**
 * @export
 * @enum {string}
 */
export enum GetPaymentSettingResponsePartyPaymentType {
  goshugi = 'GOSHUGI',
  kaihi = 'KAIHI',
  noSettings = 'NO_SETTINGS',
}
/**
 * @export
 * @enum {string}
 */
export enum GetPaymentSettingResponsePartyState {
  noParty = 'NO_PARTY',
  noInvitation = 'NO_INVITATION',
  onlyDraftInvitationOnlyUncompletedInvitation = 'ONLY_DRAFT_INVITATION_ONLY_UNCOMPLETED_INVITATION',
  onlyDraftInvitationHasCompletedInvitation = 'ONLY_DRAFT_INVITATION_HAS_COMPLETED_INVITATION',
  hasNotPublishedInvitationAndHasSuspendedInvitation = 'HAS_NOT_PUBLISHED_INVITATION_AND_HAS_SUSPENDED_INVITATION',
  hasPublishedInvitationBeforeDeadline = 'HAS_PUBLISHED_INVITATION_BEFORE_DEADLINE',
  hasPublishedInvitationAfterDeadline = 'HAS_PUBLISHED_INVITATION_AFTER_DEADLINE',
  hasPublishedInvitationEndOfPublication = 'HAS_PUBLISHED_INVITATION_END_OF_PUBLICATION',
}

import { colors } from '@/styles/colors'
import { reset } from '@/styles/reset'
import { Global, css } from '@emotion/react'
import React from 'react'

const { COLOR_2C2C2C } = colors

const globalCss = css`
  ${reset};
  body {
    position: relative;
  }
  color: ${COLOR_2C2C2C};
`

export const globalStyles = <Global styles={globalCss} />

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface InvitationResponseSettingResponse
 */
export interface InvitationResponseSettingResponse {
  /**
   *
   * @type {string}
   * @memberof InvitationResponseSettingResponse
   */
  shouldWhichGuestResponded: InvitationResponseSettingResponseShouldWhichGuestResponded
  /**
   *
   * @type {string}
   * @memberof InvitationResponseSettingResponse
   */
  shouldSexResponded: InvitationResponseSettingResponseShouldSexResponded
  /**
   *
   * @type {string}
   * @memberof InvitationResponseSettingResponse
   */
  shouldAddressResponded: InvitationResponseSettingResponseShouldAddressResponded
  /**
   *
   * @type {string}
   * @memberof InvitationResponseSettingResponse
   */
  shouldTelResponded: InvitationResponseSettingResponseShouldTelResponded
  /**
   *
   * @type {string}
   * @memberof InvitationResponseSettingResponse
   */
  shouldMessageResponded: InvitationResponseSettingResponseShouldMessageResponded
  /**
   *
   * @type {boolean}
   * @memberof InvitationResponseSettingResponse
   */
  canAddGuestCompanion: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum InvitationResponseSettingResponseShouldWhichGuestResponded {
  hidden = 'HIDDEN',
  required = 'REQUIRED',
}
/**
 * @export
 * @enum {string}
 */
export enum InvitationResponseSettingResponseShouldSexResponded {
  hidden = 'HIDDEN',
  required = 'REQUIRED',
}
/**
 * @export
 * @enum {string}
 */
export enum InvitationResponseSettingResponseShouldAddressResponded {
  hidden = 'HIDDEN',
  required = 'REQUIRED',
}
/**
 * @export
 * @enum {string}
 */
export enum InvitationResponseSettingResponseShouldTelResponded {
  hidden = 'HIDDEN',
  required = 'REQUIRED',
}
/**
 * @export
 * @enum {string}
 */
export enum InvitationResponseSettingResponseShouldMessageResponded {
  hidden = 'HIDDEN',
  required = 'REQUIRED',
}

import { PageNotFoundErrorHead } from '@/components/common/commonHead'
import Footer from '@/components/guest/invitations/footer'
import { errorPageStyles } from '@/styles/guest/errorPageStyle'

export default function GuestPageError() {
  return (
    <div css={errorPageStyles.root}>
      <PageNotFoundErrorHead />
      <div css={errorPageStyles.main}>
        <div css={errorPageStyles.container}>
          <h1 css={errorPageStyles.title}>
            お探しのページが
            <br />
            見つかりませんでした
          </h1>
          <p css={errorPageStyles.text}>
            URLが間違っているか、ページが移動、
            <br />
            もしくは削除されている可能性があります。
          </p>
        </div>
      </div>
      <div css={errorPageStyles.footer}>
        <Footer pattern="white" />
      </div>
    </div>
  )
}

import {
  GuestListUpCoupleUser,
  GuestListUpCoupleUserContextProvider,
} from '@/context/cs_guest_list_up/session'
import { GuestListUpCoupleSessionControllerApi } from '@/data/gen'
import { defaultConfiguration, invoke, RPCError } from '@/data/rpc'
import { ReactNode, useEffect, useState } from 'react'

type Props = {
  children: ReactNode
}

/**
 * GuestListUpにおけるCoupleの認証状態を確認して、認証状態をContextに保存します。
 * 配下のComponentでログイン状態による制御を行う際はContextから取得してださい。
 *
 * ※Contextがないことによるエラーを防ぐため、
 * cs_guest_list_up配下のページ/レイアウトを作成の際はルートに本コンポーネントを設定してください。
 */
export const Authentication = ({ children }: Props) => {
  const [user, setUser] = useState<GuestListUpCoupleUser | null>(null)
  const [error, setError] = useState<RPCError | null>(null)
  const checkSession = async () => {
    const api = new GuestListUpCoupleSessionControllerApi(defaultConfiguration())
    await invoke(() => api.checkGuestListUpCoupleSession()).then(async (response) => {
      if (response.success) {
        setUser({ coupleUUID: response.success.coupleUUID })
        setError(null)
        return
      }
      setUser(null)
      setError(response.error)
    })
  }

  useEffect(() => {
    checkSession()
  }, [])

  return (
    <GuestListUpCoupleUserContextProvider user={user} error={error}>
      {(user || error) && children}
    </GuestListUpCoupleUserContextProvider>
  )
}

export const cs_colors = {
  common: {
    zexyPink: '#EA6077',
    white: '#FFFFFF',
    gold: '#ABA675',
    brown: '#6C605A',
    red_error: '#E32847',
  },
  textAndIcon: {
    black_text: '#4B4B4B',
    black_textLabel: 'rgba(75, 75, 75, 0.8)',
    black_textFormDisable: 'rgba(75, 75, 75, 0.6)',
    black_textGray: 'rgba(75, 75, 75, 0.5)',
    black_textPlaceHolder: 'rgba(75, 75, 75, 0.4)',
    black_textDisabled: 'rgba(75, 75, 75, 0.3)',
    lightGray_disable: 'rgba(60, 60, 67, 0.06)',
    pink_textDisable: 'rgba(234, 96, 119, 0.3)',
  },
  background: {
    gradientLight_bg:
      'linear-gradient(81.52deg, rgba(234, 96, 119, 0.15) 12.09%, rgba(246, 122, 127, 0.15) 88.52%), #FFFFFF',
    gradientLight_bgModal:
      'linear-gradient(81.52deg, rgba(234, 96, 119, 0.15) 12.09%, rgba(246, 122, 127, 0.15) 88.52%), #FFFFFF',
    lightBeige_bg: '#FAF7F1',
    lightGray_bg: '#F7F8FA',
    lightGray_bgFormDisable: '#EBECED',
    lightGray_bgTable: 'rgba(248, 248, 248, 0.5)',
    lightPink_bgError: 'rgba(227, 40, 71, 0.05)',
  },
  border: {
    lightGray_line: 'rgba(60, 60, 67, 0.1)',
    gold_line: 'rgba(171, 166, 117, 0.5)',
  },
  button: {
    gradient_btn: 'linear-gradient(100.43deg, #EA6077 3.17%, #F67A7F 95.68%)',
    gradientLight_btn:
      'linear-gradient(100.43deg, rgba(234, 96, 119, 0.2) 3.17%, rgba(255, 162, 133, 0.2) 95.68%)',
    lightGray_btnDisable: '#E7E0E0',
  },
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { ErrorResponse } from '../model'
// @ts-ignore
import { UploadInvitationImageResponse } from '../model'
/**
 * CoupleInvitationImageControllerApi - axios parameter creator
 * @export
 */
export const CoupleInvitationImageControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upload: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists('upload', 'file', file)
      const localVarPath = `/cs/api/uploadInvitationImage`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)()

      if (file !== undefined) {
        localVarFormParams.append('file', file as any)
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CoupleInvitationImageControllerApi - functional programming interface
 * @export
 */
export const CoupleInvitationImageControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CoupleInvitationImageControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upload(
      file: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadInvitationImageResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upload(file, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CoupleInvitationImageControllerApi - factory interface
 * @export
 */
export const CoupleInvitationImageControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CoupleInvitationImageControllerApiFp(configuration)
  return {
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upload(file: any, options?: any): AxiosPromise<UploadInvitationImageResponse> {
      return localVarFp.upload(file, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * CoupleInvitationImageControllerApi - object-oriented interface
 * @export
 * @class CoupleInvitationImageControllerApi
 * @extends {BaseAPI}
 */
export class CoupleInvitationImageControllerApi extends BaseAPI {
  /**
   *
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleInvitationImageControllerApi
   */
  public upload(file: any, options?: AxiosRequestConfig) {
    return CoupleInvitationImageControllerApiFp(this.configuration)
      .upload(file, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

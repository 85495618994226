const GUEST_BREAKPOINTS = {
  lg: 'lg', // PC (1440~)
  md: 'md', // PC (960~1439)
  sp: 'sp', // SP (~959)
} as const
export type GuestBreakPoint = typeof GUEST_BREAKPOINTS[keyof typeof GUEST_BREAKPOINTS]

export const GUEST_VIEWPORTS = {
  md: { width: 1440, height: 800 },
  sp: { width: 375, height: 667 },
} as const
export type GuestViewports = typeof GUEST_VIEWPORTS[keyof typeof GUEST_VIEWPORTS]

// export const px2rem = (px: number, base = 16): string => `${1 / base * px}rem;`

const mq = (bp: GuestBreakPoint): string => {
  switch (bp) {
    case GUEST_BREAKPOINTS.sp:
      return '@media screen and (max-width: 959px)'
    case GUEST_BREAKPOINTS.md:
      return '@media screen and (min-width: 960px) and (max-width: 1440px)'
    case GUEST_BREAKPOINTS.lg:
    default:
      return ''
  }
}
export const mqSp = (): string => mq('sp')
export const mqMd = (): string => mq('md')

const calc = (px: number, bp: GuestBreakPoint): string =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS7053 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  `calc( 100vw / ${GUEST_VIEWPORTS[bp].width} * ${px})`

export const calcSp = (px: number): string => calc(px, 'sp')
export const calcMd = (px: number): string => calc(px, 'md')

const calcBasedOnHeight = (px: number, bp: GuestBreakPoint): string =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS7053 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  `calc( 100vh / ${GUEST_VIEWPORTS[bp].height} * ${px})`

export const calcSpHeight = (px: number): string => calcBasedOnHeight(px, 'sp')
export const calcMdHeight = (px: number): string => calcBasedOnHeight(px, 'md')

export const ffShipporiMincho = () => `
font-family: shippori-mincho, 'Hiragino Mincho ProN', 'MS PMincho', 'Noto serif JP', serif;
font-weight: 500;
font-style: normal;
`

export const ffDnpShueiMinchoPro6 = () => `
font-family: dnp-shuei-mincho-pr6, 'Hiragino Mincho ProN', 'MS PMincho', 'Noto serif JP', serif;
font-weight: 400;
font-style: normal;
`

export const ffDnpShueiGothicGinStd = () => `
font-family: dnp-shuei-gothic-gin-std, 'Hiragino Kaku Gothic Pro', 'BIZ UDPGothic', 'メイリオ', 'Noto sans JP',sans-serif;
font-weight: 400;
font-style: normal;
`

export const ffAdobeGaramondPro = () => `
font-family: adobe-garamond-pro, 'Times New Roman', 'Noto serif JP', serif;
font-weight: 400;
font-style: normal;
`

export const ffCaflischScriptPro = () => `
font-family: caflisch-script-pro, Arial, Roboto, sans-serif;
font-weight: 400;
font-style: normal;
`

export const ffSanviyoPro = () => `
font-family: sanvito-pro, 'Times New Roman', 'Noto serif JP', serif;
font-weight: 400;
font-style: normal;
`

export const ffSerifSystem = () => `
font-family: 'Hiragino Mincho ProN', 'MS PMincho', 'Noto serif JP',serif;
`

export const ffSansSerifSystem = () => `
font-family: 'Hiragino Kaku Gothic Pro','メイリオ', 'MS PGothic', 'Noto sans JP',sans-serif;
`

export const ffFormSystem = (systemFont: string) => `
  ${systemFont}
  &:autofill::first-line,
  &:autofill,
  &:autofill:hover,
  &:autofill:focus,
  &:autofill:active,
  &:-webkit-autofill::first-line,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    ${systemFont}
  }
`

import { SquareMSecondaryButton } from '@/components/cs/button'
import { ModalContainer } from '@/components/cs/modalContainer'
import { colors } from '@/styles/colors'
import { css } from '@emotion/react'
import Image from 'next/image'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
const NetWorkErrorModal = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false)
  }

  const AttentionIcon = () => (
    <div css={styles.icon}>
      <Image src="/images/cs/icons/28px/attention.svg" width={28} height={28} layout={'fixed'} />
    </div>
  )
  return (
    <ModalContainer open={open} onClose={handleClose}>
      <div css={styles.modalWindow}>
        <AttentionIcon />
        <div css={styles.title}>
          ネットワークに
          <br />
          接続できませんでした
        </div>
        <div css={styles.description}>
          しばらくしてから
          <br />
          再度お試しください。
        </div>
        <div css={styles.closeButton}>
          <SquareMSecondaryButton label="閉じる" onClick={handleClose} css={styles.closeButton} />
        </div>
      </div>
    </ModalContainer>
  )
}
const styles = {
  modalWindow: css`
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 311px;
    height: 290px;
    padding: 28px 32px;
    background: linear-gradient(
        81.52deg,
        rgba(234, 96, 119, 0.1) 12.09%,
        rgba(255, 162, 133, 0.1) 88.52%
      ),
      ${colors.COLOR_FFFFFF};
    border-radius: 16px;
    transform: translate(-50%, -50%);
    :focus {
      outline: none;
    }
  `,
  title: css`
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 150%;
    color: ${colors.COLOR_4B4B4B};
    text-align: center;
    letter-spacing: 0.5px;
  `,
  description: css`
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    color: ${colors.COLOR_4B4B4B};
    text-align: center;
    letter-spacing: 0.5px;
  `,
  closeButton: css`
    width: 100%;
    min-width: 94px;
    height: 44px;
    text-align: center;
    button {
      width: 100%;
    }
  `,
  icon: css`
    width: 28px;
    height: 28px;
  `,
}

export default NetWorkErrorModal

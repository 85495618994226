/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { RegisterMyHistoryForCategoryRequestContent } from './register-my-history-for-category-request-content'

/**
 *
 * @export
 * @interface RegisterMyHistoryForCategoryRequest
 */
export interface RegisterMyHistoryForCategoryRequest {
  /**
   *
   * @type {string}
   * @memberof RegisterMyHistoryForCategoryRequest
   */
  guestBucketUUID: string
  /**
   *
   * @type {string}
   * @memberof RegisterMyHistoryForCategoryRequest
   */
  communityCategory: RegisterMyHistoryForCategoryRequestCommunityCategory
  /**
   *
   * @type {Array<RegisterMyHistoryForCategoryRequestContent>}
   * @memberof RegisterMyHistoryForCategoryRequest
   */
  contents: Array<RegisterMyHistoryForCategoryRequestContent>
}

/**
 * @export
 * @enum {string}
 */
export enum RegisterMyHistoryForCategoryRequestCommunityCategory {
  family = 'FAMILY',
  school = 'SCHOOL',
  workplace = 'WORKPLACE',
  others = 'OTHERS',
}

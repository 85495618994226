/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface GetEntryTargetClientsResponseClient
 */
export interface GetEntryTargetClientsResponseClient {
  /**
   *
   * @type {string}
   * @memberof GetEntryTargetClientsResponseClient
   */
  clientCd: string
  /**
   *
   * @type {string}
   * @memberof GetEntryTargetClientsResponseClient
   */
  sponsorCd: string
  /**
   *
   * @type {string}
   * @memberof GetEntryTargetClientsResponseClient
   */
  madoguchiName: string
  /**
   *
   * @type {string}
   * @memberof GetEntryTargetClientsResponseClient
   */
  gyoshu: GetEntryTargetClientsResponseClientGyoshu
  /**
   *
   * @type {boolean}
   * @memberof GetEntryTargetClientsResponseClient
   */
  isEntryCompleted: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum GetEntryTargetClientsResponseClientGyoshu {
  weddingReceptionClient = 'WEDDING_RECEPTION_CLIENT',
  resortClient = 'RESORT_CLIENT',
}

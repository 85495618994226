import { css } from '@emotion/react'

export const reset = css`
  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html,
  body {
    font-family: 'Hiragino Kaku Gothic ProN', 'メイリオ', sans-serif;
    background-color: #fff;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  figure,
  blockquote,
  ul,
  ol,
  li,
  dl,
  dd {
    padding: 0;
    margin: 0;
  }

  /* Remove list styles on ul, ol elements */
  ul,
  ol {
    list-style: none;
  }

  img {
    display: block;
    max-width: 100%;
  }

  button {
    border: none;
  }

  th {
    font-weight: inherit;
    text-align: inherit;
  }
`

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { CancelGuestPaymentRequest } from '../model'
// @ts-ignore
import { CancelGuestPaymentResponse } from '../model'
// @ts-ignore
import { ErrorResponse } from '../model'
// @ts-ignore
import { GetClientCustomerRequest } from '../model'
// @ts-ignore
import { GetClientCustomerResponse } from '../model'
// @ts-ignore
import { GetClientCustomerSummaryResponse } from '../model'
// @ts-ignore
import { GetClientCustomersRequest } from '../model'
// @ts-ignore
import { GetClientCustomersResponse } from '../model'
// @ts-ignore
import { GetClientCustomersWithPaymentToClientRequest } from '../model'
// @ts-ignore
import { GetClientCustomersWithPaymentToClientResponse } from '../model'
// @ts-ignore
import { GetCustomerGuestHistoriesResponse } from '../model'
// @ts-ignore
import { GetCustomerGuestsRequest } from '../model'
// @ts-ignore
import { GetCustomerGuestsResponse } from '../model'
// @ts-ignore
import { GetCustomerInvitationsRequest } from '../model'
// @ts-ignore
import { GetCustomerInvitationsResponse } from '../model'
// @ts-ignore
import { GetGuestsForClientCSVResponse } from '../model'
// @ts-ignore
import { GetGuestsForClientWPSCSVResponse } from '../model'
// @ts-ignore
import { GetPaymentToClientBadgeInfoRequest } from '../model'
// @ts-ignore
import { GetPaymentToClientBadgeInfoResponse } from '../model'
// @ts-ignore
import { GetPaymentToClientNotificationRequest } from '../model'
// @ts-ignore
import { GetPaymentToClientNotificationResponse } from '../model'
// @ts-ignore
import { RequestPaymentToClientRequest } from '../model'
// @ts-ignore
import { RequestPaymentToClientResponse } from '../model'
// @ts-ignore
import { SearchClientCustomersRequest } from '../model'
// @ts-ignore
import { UpdateResponseDeadlineAfterResponseDeadlineRequest } from '../model'
/**
 * ClientCustomerControllerApi - axios parameter creator
 * @export
 */
export const ClientCustomerControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CancelGuestPaymentRequest} cancelGuestPaymentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelGuestPayment: async (
      cancelGuestPaymentRequest: CancelGuestPaymentRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cancelGuestPaymentRequest' is not null or undefined
      assertParamExists(
        'cancelGuestPayment',
        'cancelGuestPaymentRequest',
        cancelGuestPaymentRequest,
      )
      const localVarPath = `/cl/api/cancelGuestPayment`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        cancelGuestPaymentRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetClientCustomerRequest} getClientCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientCustomer: async (
      getClientCustomerRequest: GetClientCustomerRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getClientCustomerRequest' is not null or undefined
      assertParamExists('getClientCustomer', 'getClientCustomerRequest', getClientCustomerRequest)
      const localVarPath = `/cl/api/getClientCustomer`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getClientCustomerRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetClientCustomerRequest} getClientCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientCustomerSummary: async (
      getClientCustomerRequest: GetClientCustomerRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getClientCustomerRequest' is not null or undefined
      assertParamExists(
        'getClientCustomerSummary',
        'getClientCustomerRequest',
        getClientCustomerRequest,
      )
      const localVarPath = `/cl/api/getClientCustomerSummary`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getClientCustomerRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetClientCustomersRequest} getClientCustomersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientCustomers: async (
      getClientCustomersRequest: GetClientCustomersRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getClientCustomersRequest' is not null or undefined
      assertParamExists(
        'getClientCustomers',
        'getClientCustomersRequest',
        getClientCustomersRequest,
      )
      const localVarPath = `/cl/api/getClientCustomers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getClientCustomersRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetClientCustomersWithPaymentToClientRequest} getClientCustomersWithPaymentToClientRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientCustomersWithPaymentToClient: async (
      getClientCustomersWithPaymentToClientRequest: GetClientCustomersWithPaymentToClientRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getClientCustomersWithPaymentToClientRequest' is not null or undefined
      assertParamExists(
        'getClientCustomersWithPaymentToClient',
        'getClientCustomersWithPaymentToClientRequest',
        getClientCustomersWithPaymentToClientRequest,
      )
      const localVarPath = `/cl/api/getClientCustomersWithPaymentToClient`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getClientCustomersWithPaymentToClientRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerGuestHistories: async (
      getCustomerGuestsRequest: GetCustomerGuestsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getCustomerGuestsRequest' is not null or undefined
      assertParamExists(
        'getCustomerGuestHistories',
        'getCustomerGuestsRequest',
        getCustomerGuestsRequest,
      )
      const localVarPath = `/cl/api/getCustomerGuestHistories`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getCustomerGuestsRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerGuestHistoriesForCSV: async (
      getCustomerGuestsRequest: GetCustomerGuestsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getCustomerGuestsRequest' is not null or undefined
      assertParamExists(
        'getCustomerGuestHistoriesForCSV',
        'getCustomerGuestsRequest',
        getCustomerGuestsRequest,
      )
      const localVarPath = `/cl/api/getCustomerGuestHistoriesForCSV`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getCustomerGuestsRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerGuestHistoriesForWPSCSV: async (
      getCustomerGuestsRequest: GetCustomerGuestsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getCustomerGuestsRequest' is not null or undefined
      assertParamExists(
        'getCustomerGuestHistoriesForWPSCSV',
        'getCustomerGuestsRequest',
        getCustomerGuestsRequest,
      )
      const localVarPath = `/cl/api/getCustomerGuestHistoriesForWPSCSV`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getCustomerGuestsRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerGuests: async (
      getCustomerGuestsRequest: GetCustomerGuestsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getCustomerGuestsRequest' is not null or undefined
      assertParamExists('getCustomerGuests', 'getCustomerGuestsRequest', getCustomerGuestsRequest)
      const localVarPath = `/cl/api/getCustomerGuests`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getCustomerGuestsRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerGuestsForCSV: async (
      getCustomerGuestsRequest: GetCustomerGuestsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getCustomerGuestsRequest' is not null or undefined
      assertParamExists(
        'getCustomerGuestsForCSV',
        'getCustomerGuestsRequest',
        getCustomerGuestsRequest,
      )
      const localVarPath = `/cl/api/getCustomerGuestsForCSV`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getCustomerGuestsRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerGuestsForWPSCSV: async (
      getCustomerGuestsRequest: GetCustomerGuestsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getCustomerGuestsRequest' is not null or undefined
      assertParamExists(
        'getCustomerGuestsForWPSCSV',
        'getCustomerGuestsRequest',
        getCustomerGuestsRequest,
      )
      const localVarPath = `/cl/api/getCustomerGuestsForWPSCSV`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getCustomerGuestsRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetCustomerInvitationsRequest} getCustomerInvitationsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerInvitations: async (
      getCustomerInvitationsRequest: GetCustomerInvitationsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getCustomerInvitationsRequest' is not null or undefined
      assertParamExists(
        'getCustomerInvitations',
        'getCustomerInvitationsRequest',
        getCustomerInvitationsRequest,
      )
      const localVarPath = `/cl/api/getCustomerInvitations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getCustomerInvitationsRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetPaymentToClientBadgeInfoRequest} getPaymentToClientBadgeInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentToClientBadgeInfo: async (
      getPaymentToClientBadgeInfoRequest: GetPaymentToClientBadgeInfoRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getPaymentToClientBadgeInfoRequest' is not null or undefined
      assertParamExists(
        'getPaymentToClientBadgeInfo',
        'getPaymentToClientBadgeInfoRequest',
        getPaymentToClientBadgeInfoRequest,
      )
      const localVarPath = `/cl/api/getPaymentToClientBadgeInfo`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getPaymentToClientBadgeInfoRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetPaymentToClientNotificationRequest} getPaymentToClientNotificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentToClientNotification: async (
      getPaymentToClientNotificationRequest: GetPaymentToClientNotificationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getPaymentToClientNotificationRequest' is not null or undefined
      assertParamExists(
        'getPaymentToClientNotification',
        'getPaymentToClientNotificationRequest',
        getPaymentToClientNotificationRequest,
      )
      const localVarPath = `/cl/api/getPaymentToClientNotification`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getPaymentToClientNotificationRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {RequestPaymentToClientRequest} requestPaymentToClientRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestPaymentToClient: async (
      requestPaymentToClientRequest: RequestPaymentToClientRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'requestPaymentToClientRequest' is not null or undefined
      assertParamExists(
        'requestPaymentToClient',
        'requestPaymentToClientRequest',
        requestPaymentToClientRequest,
      )
      const localVarPath = `/cl/api/requestPaymentToClient`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestPaymentToClientRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {SearchClientCustomersRequest} searchClientCustomersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchClientCustomers: async (
      searchClientCustomersRequest: SearchClientCustomersRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'searchClientCustomersRequest' is not null or undefined
      assertParamExists(
        'searchClientCustomers',
        'searchClientCustomersRequest',
        searchClientCustomersRequest,
      )
      const localVarPath = `/cl/api/searchClientCustomers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        searchClientCustomersRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateResponseDeadlineAfterResponseDeadlineRequest} updateResponseDeadlineAfterResponseDeadlineRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateResponseDeadlineAfterResponseDeadline: async (
      updateResponseDeadlineAfterResponseDeadlineRequest: UpdateResponseDeadlineAfterResponseDeadlineRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateResponseDeadlineAfterResponseDeadlineRequest' is not null or undefined
      assertParamExists(
        'updateResponseDeadlineAfterResponseDeadline',
        'updateResponseDeadlineAfterResponseDeadlineRequest',
        updateResponseDeadlineAfterResponseDeadlineRequest,
      )
      const localVarPath = `/cl/api/updateResponseDeadlineAfterResponseDeadline`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateResponseDeadlineAfterResponseDeadlineRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ClientCustomerControllerApi - functional programming interface
 * @export
 */
export const ClientCustomerControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ClientCustomerControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {CancelGuestPaymentRequest} cancelGuestPaymentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelGuestPayment(
      cancelGuestPaymentRequest: CancelGuestPaymentRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancelGuestPaymentResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelGuestPayment(
        cancelGuestPaymentRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetClientCustomerRequest} getClientCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientCustomer(
      getClientCustomerRequest: GetClientCustomerRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientCustomerResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClientCustomer(
        getClientCustomerRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetClientCustomerRequest} getClientCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientCustomerSummary(
      getClientCustomerRequest: GetClientCustomerRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientCustomerSummaryResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClientCustomerSummary(
        getClientCustomerRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetClientCustomersRequest} getClientCustomersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientCustomers(
      getClientCustomersRequest: GetClientCustomersRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientCustomersResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClientCustomers(
        getClientCustomersRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetClientCustomersWithPaymentToClientRequest} getClientCustomersWithPaymentToClientRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientCustomersWithPaymentToClient(
      getClientCustomersWithPaymentToClientRequest: GetClientCustomersWithPaymentToClientRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetClientCustomersWithPaymentToClientResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientCustomersWithPaymentToClient(
          getClientCustomersWithPaymentToClientRequest,
          options,
        )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomerGuestHistories(
      getCustomerGuestsRequest: GetCustomerGuestsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomerGuestHistoriesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerGuestHistories(
        getCustomerGuestsRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomerGuestHistoriesForCSV(
      getCustomerGuestsRequest: GetCustomerGuestsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestsForClientCSVResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerGuestHistoriesForCSV(
        getCustomerGuestsRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomerGuestHistoriesForWPSCSV(
      getCustomerGuestsRequest: GetCustomerGuestsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestsForClientWPSCSVResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerGuestHistoriesForWPSCSV(
        getCustomerGuestsRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomerGuests(
      getCustomerGuestsRequest: GetCustomerGuestsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomerGuestsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerGuests(
        getCustomerGuestsRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomerGuestsForCSV(
      getCustomerGuestsRequest: GetCustomerGuestsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestsForClientCSVResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerGuestsForCSV(
        getCustomerGuestsRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomerGuestsForWPSCSV(
      getCustomerGuestsRequest: GetCustomerGuestsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestsForClientWPSCSVResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerGuestsForWPSCSV(
        getCustomerGuestsRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetCustomerInvitationsRequest} getCustomerInvitationsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomerInvitations(
      getCustomerInvitationsRequest: GetCustomerInvitationsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomerInvitationsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerInvitations(
        getCustomerInvitationsRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetPaymentToClientBadgeInfoRequest} getPaymentToClientBadgeInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPaymentToClientBadgeInfo(
      getPaymentToClientBadgeInfoRequest: GetPaymentToClientBadgeInfoRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetPaymentToClientBadgeInfoResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentToClientBadgeInfo(
        getPaymentToClientBadgeInfoRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetPaymentToClientNotificationRequest} getPaymentToClientNotificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPaymentToClientNotification(
      getPaymentToClientNotificationRequest: GetPaymentToClientNotificationRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetPaymentToClientNotificationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentToClientNotification(
        getPaymentToClientNotificationRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {RequestPaymentToClientRequest} requestPaymentToClientRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async requestPaymentToClient(
      requestPaymentToClientRequest: RequestPaymentToClientRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestPaymentToClientResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.requestPaymentToClient(
        requestPaymentToClientRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {SearchClientCustomersRequest} searchClientCustomersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchClientCustomers(
      searchClientCustomersRequest: SearchClientCustomersRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientCustomersResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchClientCustomers(
        searchClientCustomersRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateResponseDeadlineAfterResponseDeadlineRequest} updateResponseDeadlineAfterResponseDeadlineRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateResponseDeadlineAfterResponseDeadline(
      updateResponseDeadlineAfterResponseDeadlineRequest: UpdateResponseDeadlineAfterResponseDeadlineRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateResponseDeadlineAfterResponseDeadline(
          updateResponseDeadlineAfterResponseDeadlineRequest,
          options,
        )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ClientCustomerControllerApi - factory interface
 * @export
 */
export const ClientCustomerControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ClientCustomerControllerApiFp(configuration)
  return {
    /**
     *
     * @param {CancelGuestPaymentRequest} cancelGuestPaymentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelGuestPayment(
      cancelGuestPaymentRequest: CancelGuestPaymentRequest,
      options?: any,
    ): AxiosPromise<CancelGuestPaymentResponse> {
      return localVarFp
        .cancelGuestPayment(cancelGuestPaymentRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetClientCustomerRequest} getClientCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientCustomer(
      getClientCustomerRequest: GetClientCustomerRequest,
      options?: any,
    ): AxiosPromise<GetClientCustomerResponse> {
      return localVarFp
        .getClientCustomer(getClientCustomerRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetClientCustomerRequest} getClientCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientCustomerSummary(
      getClientCustomerRequest: GetClientCustomerRequest,
      options?: any,
    ): AxiosPromise<GetClientCustomerSummaryResponse> {
      return localVarFp
        .getClientCustomerSummary(getClientCustomerRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetClientCustomersRequest} getClientCustomersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientCustomers(
      getClientCustomersRequest: GetClientCustomersRequest,
      options?: any,
    ): AxiosPromise<GetClientCustomersResponse> {
      return localVarFp
        .getClientCustomers(getClientCustomersRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetClientCustomersWithPaymentToClientRequest} getClientCustomersWithPaymentToClientRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientCustomersWithPaymentToClient(
      getClientCustomersWithPaymentToClientRequest: GetClientCustomersWithPaymentToClientRequest,
      options?: any,
    ): AxiosPromise<GetClientCustomersWithPaymentToClientResponse> {
      return localVarFp
        .getClientCustomersWithPaymentToClient(
          getClientCustomersWithPaymentToClientRequest,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerGuestHistories(
      getCustomerGuestsRequest: GetCustomerGuestsRequest,
      options?: any,
    ): AxiosPromise<GetCustomerGuestHistoriesResponse> {
      return localVarFp
        .getCustomerGuestHistories(getCustomerGuestsRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerGuestHistoriesForCSV(
      getCustomerGuestsRequest: GetCustomerGuestsRequest,
      options?: any,
    ): AxiosPromise<GetGuestsForClientCSVResponse> {
      return localVarFp
        .getCustomerGuestHistoriesForCSV(getCustomerGuestsRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerGuestHistoriesForWPSCSV(
      getCustomerGuestsRequest: GetCustomerGuestsRequest,
      options?: any,
    ): AxiosPromise<GetGuestsForClientWPSCSVResponse> {
      return localVarFp
        .getCustomerGuestHistoriesForWPSCSV(getCustomerGuestsRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerGuests(
      getCustomerGuestsRequest: GetCustomerGuestsRequest,
      options?: any,
    ): AxiosPromise<GetCustomerGuestsResponse> {
      return localVarFp
        .getCustomerGuests(getCustomerGuestsRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerGuestsForCSV(
      getCustomerGuestsRequest: GetCustomerGuestsRequest,
      options?: any,
    ): AxiosPromise<GetGuestsForClientCSVResponse> {
      return localVarFp
        .getCustomerGuestsForCSV(getCustomerGuestsRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerGuestsForWPSCSV(
      getCustomerGuestsRequest: GetCustomerGuestsRequest,
      options?: any,
    ): AxiosPromise<GetGuestsForClientWPSCSVResponse> {
      return localVarFp
        .getCustomerGuestsForWPSCSV(getCustomerGuestsRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetCustomerInvitationsRequest} getCustomerInvitationsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerInvitations(
      getCustomerInvitationsRequest: GetCustomerInvitationsRequest,
      options?: any,
    ): AxiosPromise<GetCustomerInvitationsResponse> {
      return localVarFp
        .getCustomerInvitations(getCustomerInvitationsRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetPaymentToClientBadgeInfoRequest} getPaymentToClientBadgeInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentToClientBadgeInfo(
      getPaymentToClientBadgeInfoRequest: GetPaymentToClientBadgeInfoRequest,
      options?: any,
    ): AxiosPromise<GetPaymentToClientBadgeInfoResponse> {
      return localVarFp
        .getPaymentToClientBadgeInfo(getPaymentToClientBadgeInfoRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetPaymentToClientNotificationRequest} getPaymentToClientNotificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentToClientNotification(
      getPaymentToClientNotificationRequest: GetPaymentToClientNotificationRequest,
      options?: any,
    ): AxiosPromise<GetPaymentToClientNotificationResponse> {
      return localVarFp
        .getPaymentToClientNotification(getPaymentToClientNotificationRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {RequestPaymentToClientRequest} requestPaymentToClientRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestPaymentToClient(
      requestPaymentToClientRequest: RequestPaymentToClientRequest,
      options?: any,
    ): AxiosPromise<RequestPaymentToClientResponse> {
      return localVarFp
        .requestPaymentToClient(requestPaymentToClientRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {SearchClientCustomersRequest} searchClientCustomersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchClientCustomers(
      searchClientCustomersRequest: SearchClientCustomersRequest,
      options?: any,
    ): AxiosPromise<GetClientCustomersResponse> {
      return localVarFp
        .searchClientCustomers(searchClientCustomersRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateResponseDeadlineAfterResponseDeadlineRequest} updateResponseDeadlineAfterResponseDeadlineRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateResponseDeadlineAfterResponseDeadline(
      updateResponseDeadlineAfterResponseDeadlineRequest: UpdateResponseDeadlineAfterResponseDeadlineRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .updateResponseDeadlineAfterResponseDeadline(
          updateResponseDeadlineAfterResponseDeadlineRequest,
          options,
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ClientCustomerControllerApi - object-oriented interface
 * @export
 * @class ClientCustomerControllerApi
 * @extends {BaseAPI}
 */
export class ClientCustomerControllerApi extends BaseAPI {
  /**
   *
   * @param {CancelGuestPaymentRequest} cancelGuestPaymentRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomerControllerApi
   */
  public cancelGuestPayment(
    cancelGuestPaymentRequest: CancelGuestPaymentRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomerControllerApiFp(this.configuration)
      .cancelGuestPayment(cancelGuestPaymentRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetClientCustomerRequest} getClientCustomerRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomerControllerApi
   */
  public getClientCustomer(
    getClientCustomerRequest: GetClientCustomerRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomerControllerApiFp(this.configuration)
      .getClientCustomer(getClientCustomerRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetClientCustomerRequest} getClientCustomerRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomerControllerApi
   */
  public getClientCustomerSummary(
    getClientCustomerRequest: GetClientCustomerRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomerControllerApiFp(this.configuration)
      .getClientCustomerSummary(getClientCustomerRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetClientCustomersRequest} getClientCustomersRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomerControllerApi
   */
  public getClientCustomers(
    getClientCustomersRequest: GetClientCustomersRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomerControllerApiFp(this.configuration)
      .getClientCustomers(getClientCustomersRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetClientCustomersWithPaymentToClientRequest} getClientCustomersWithPaymentToClientRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomerControllerApi
   */
  public getClientCustomersWithPaymentToClient(
    getClientCustomersWithPaymentToClientRequest: GetClientCustomersWithPaymentToClientRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomerControllerApiFp(this.configuration)
      .getClientCustomersWithPaymentToClient(getClientCustomersWithPaymentToClientRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomerControllerApi
   */
  public getCustomerGuestHistories(
    getCustomerGuestsRequest: GetCustomerGuestsRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomerControllerApiFp(this.configuration)
      .getCustomerGuestHistories(getCustomerGuestsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomerControllerApi
   */
  public getCustomerGuestHistoriesForCSV(
    getCustomerGuestsRequest: GetCustomerGuestsRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomerControllerApiFp(this.configuration)
      .getCustomerGuestHistoriesForCSV(getCustomerGuestsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomerControllerApi
   */
  public getCustomerGuestHistoriesForWPSCSV(
    getCustomerGuestsRequest: GetCustomerGuestsRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomerControllerApiFp(this.configuration)
      .getCustomerGuestHistoriesForWPSCSV(getCustomerGuestsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomerControllerApi
   */
  public getCustomerGuests(
    getCustomerGuestsRequest: GetCustomerGuestsRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomerControllerApiFp(this.configuration)
      .getCustomerGuests(getCustomerGuestsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomerControllerApi
   */
  public getCustomerGuestsForCSV(
    getCustomerGuestsRequest: GetCustomerGuestsRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomerControllerApiFp(this.configuration)
      .getCustomerGuestsForCSV(getCustomerGuestsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetCustomerGuestsRequest} getCustomerGuestsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomerControllerApi
   */
  public getCustomerGuestsForWPSCSV(
    getCustomerGuestsRequest: GetCustomerGuestsRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomerControllerApiFp(this.configuration)
      .getCustomerGuestsForWPSCSV(getCustomerGuestsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetCustomerInvitationsRequest} getCustomerInvitationsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomerControllerApi
   */
  public getCustomerInvitations(
    getCustomerInvitationsRequest: GetCustomerInvitationsRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomerControllerApiFp(this.configuration)
      .getCustomerInvitations(getCustomerInvitationsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetPaymentToClientBadgeInfoRequest} getPaymentToClientBadgeInfoRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomerControllerApi
   */
  public getPaymentToClientBadgeInfo(
    getPaymentToClientBadgeInfoRequest: GetPaymentToClientBadgeInfoRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomerControllerApiFp(this.configuration)
      .getPaymentToClientBadgeInfo(getPaymentToClientBadgeInfoRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetPaymentToClientNotificationRequest} getPaymentToClientNotificationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomerControllerApi
   */
  public getPaymentToClientNotification(
    getPaymentToClientNotificationRequest: GetPaymentToClientNotificationRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomerControllerApiFp(this.configuration)
      .getPaymentToClientNotification(getPaymentToClientNotificationRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {RequestPaymentToClientRequest} requestPaymentToClientRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomerControllerApi
   */
  public requestPaymentToClient(
    requestPaymentToClientRequest: RequestPaymentToClientRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomerControllerApiFp(this.configuration)
      .requestPaymentToClient(requestPaymentToClientRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {SearchClientCustomersRequest} searchClientCustomersRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomerControllerApi
   */
  public searchClientCustomers(
    searchClientCustomersRequest: SearchClientCustomersRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomerControllerApiFp(this.configuration)
      .searchClientCustomers(searchClientCustomersRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateResponseDeadlineAfterResponseDeadlineRequest} updateResponseDeadlineAfterResponseDeadlineRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientCustomerControllerApi
   */
  public updateResponseDeadlineAfterResponseDeadline(
    updateResponseDeadlineAfterResponseDeadlineRequest: UpdateResponseDeadlineAfterResponseDeadlineRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientCustomerControllerApiFp(this.configuration)
      .updateResponseDeadlineAfterResponseDeadline(
        updateResponseDeadlineAfterResponseDeadlineRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

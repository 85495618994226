/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface InvitationWithResponseSummaryResponse
 */
export interface InvitationWithResponseSummaryResponse {
  /**
   *
   * @type {string}
   * @memberof InvitationWithResponseSummaryResponse
   */
  invitationID: string
  /**
   *
   * @type {string}
   * @memberof InvitationWithResponseSummaryResponse
   */
  invitationName: string
  /**
   *
   * @type {string}
   * @memberof InvitationWithResponseSummaryResponse
   */
  invitationStatus: InvitationWithResponseSummaryResponseInvitationStatus
  /**
   *
   * @type {number}
   * @memberof InvitationWithResponseSummaryResponse
   */
  attendanceGuestCount: number
  /**
   *
   * @type {number}
   * @memberof InvitationWithResponseSummaryResponse
   */
  absenceGuestCount: number
  /**
   *
   * @type {number}
   * @memberof InvitationWithResponseSummaryResponse
   */
  paymentReceivedCount?: number
}

/**
 * @export
 * @enum {string}
 */
export enum InvitationWithResponseSummaryResponseInvitationStatus {
  published = 'PUBLISHED',
  suspended = 'SUSPENDED',
}

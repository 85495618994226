/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ClientUser
 */
export interface ClientUser {
  /**
   *
   * @type {string}
   * @memberof ClientUser
   */
  userId: string
  /**
   *
   * @type {string}
   * @memberof ClientUser
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof ClientUser
   */
  lastName: string
  /**
   *
   * @type {string}
   * @memberof ClientUser
   */
  role: ClientUserRole
  /**
   *
   * @type {boolean}
   * @memberof ClientUser
   */
  isActive: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum ClientUserRole {
  admin = 'ADMIN',
  member = 'MEMBER',
}

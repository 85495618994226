import { mqSp, calcSp } from '@/styles/guest/styleConstants'
import { css } from '@emotion/react'

// note: 中のフレーム幅は固定
export const errorPageStyles = {
  root: css`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 141px;
    text-align: center;
    ${mqSp()} {
      align-items: center;
      padding-bottom: ${calcSp(195)};
    }
  `,
  main: css`
    width: 100%;
    padding: 80px 0;
    ${mqSp()} {
      height: 100%;
      padding: 80px 0;
    }
  `,
  container: css`
    width: 707px;
    padding: 76px 196px;
    margin: 0 auto;
    text-align: center;
    border: 3px solid #efefef;
    border-radius: 10px;
    ${mqSp()} {
      width: 327px;
      padding: 44px 40px;
    }
  `,
  serverContainer: css`
    width: 742px;
    padding: 76px 0;
  `,
  title: css`
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
    color: #4b4b4b;
    letter-spacing: spacing 0.5px;
    word-break: break-all;
    white-space: pre-wrap;
    ${mqSp()} {
      font-size: 18px;
    }
  `,
  text: css`
    margin-top: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    color: #4b4b4b;
    word-break: break-all;
    white-space: pre-wrap;
    ${mqSp()} {
      font-size: 12px;
    }
  `,
  link: css`
    font-size: 14px;
    color: #ea6077;
    letter-spacing: 0.5px;
    -webkit-tap-highlight-color: transparent;
    ${mqSp()} {
      font-size: 12px;
    }
  `,

  button: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 315px;
    padding: 15px 0;
    margin: 32px auto 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    color: #ea6077;
    text-decoration: none;
    letter-spacing: 0.5px;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #ea6077;
    border-radius: 50px;
    -webkit-tap-highlight-color: transparent;
    ${mqSp()} {
      width: 247px;
    }
  `,
  footer: css`
    position: absolute;
    bottom: 0;
    width: 100%;
  `,
  onlySp: css`
    display: none;
    ${mqSp()} {
      display: inline;
    }
  `,
}

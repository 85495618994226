import { colors } from '@/styles/colors'
import { cs_colors } from '@/styles/cs/colors'
import { breakpoints } from '@/styles/cs/styleConstants'
import { CsTextStyles } from '@/styles/cs/textStyles'
import { css } from '@emotion/react'

type MenuItemProps = {
  itemTitle: string
  isNewWindowOpenItem: boolean
  onClick: () => void
}

type HeaderMenuComponentProps = {
  menuItems: Array<MenuItemProps>
  onLogout: () => void
}
/**
 * カップル向けヘッダーで利用するメニューバーのパーツ (PCでは右サイドバー、SPでは全体に出る)
 */
export const HeaderMenuComponent = ({ menuItems, onLogout }: HeaderMenuComponentProps) => (
  <nav css={styles.menu.layout}>
    <ul css={styles.menu.ul}>
      {menuItems.map((item) => (
        <li
          key={item.itemTitle}
          css={
            item.isNewWindowOpenItem
              ? [
                  styles.menu.newWindow,
                  styles.menu.list,
                  CsTextStyles.heading.bold.s14px.h100(cs_colors.textAndIcon.black_text),
                ]
              : [
                  styles.menu.list,
                  CsTextStyles.heading.bold.s14px.h100(cs_colors.textAndIcon.black_text),
                ]
          }
          onClick={item.onClick}
        >
          {item.itemTitle}
        </li>
      ))}
    </ul>
    <span
      css={[
        styles.menu.list,
        styles.menu.logout,
        CsTextStyles.heading.bold.s14px.h100(cs_colors.textAndIcon.black_text),
      ]}
      onClick={onLogout}
    >
      ログアウト
    </span>
  </nav>
)

const styles = {
  menu: {
    layout: css`
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      width: 503px;
      height: 100%;
      padding: 115px 80px 74px;
      overflow-y: auto;
      color: ${colors.COLOR_4B4B4B};
      letter-spacing: 0.5px;
      background-color: rgba(255, 255, 255, 0.5);

      @media (max-width: ${breakpoints.sp}px) {
        width: 100%;
        padding: 89px 32px 0;
      }
    `,
    ul: css`
      flex: 1;
    `,
    list: css`
      display: flex;
      align-items: center;
      min-height: 64px;
      padding-left: 24px;
      margin: 16px 8px;
      cursor: pointer;
      border-radius: 8px;
      &:hover {
        background: rgba(234, 96, 119, 0.05);
      }

      @media (max-width: ${breakpoints.sp}px) {
        padding-left: 0;
        margin: 0;
        border-bottom: 1px solid rgba(60, 60, 67, 0.06);
        border-radius: 0;
        &:hover {
          background: none;
        }
      }
    `,
    newWindow: css`
      &::after {
        width: 12px;
        height: 12px;
        margin-left: 8px;
        content: '';
        background: url('/images/cs/new-window.svg') no-repeat;
        background-size: contain;
      }
    `,
    logout: css`
      border-bottom: 0 !important;
      @media screen and (orientation: landscape) {
        margin-top: 64px;
      }
    `,
  },
}

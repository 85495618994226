import { SecondaryButton } from '@/components/cl/button'
import Layout from '@/components/cl/layout'
import { ErrorHead, PageNotFoundErrorHead } from '@/components/common/commonHead'
import { loggingClientPageView } from '@/components/common/googleTagManager'
import { colors } from '@/styles/colors'
import { css } from '@emotion/react'
import { useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
export function ErrorLayout({ children, ...props }) {
  return (
    <Layout backgroundColor={colors.COLOR_FFFFFF} logoLink="/cl" {...props}>
      <div css={styles.main}>
        <div css={styles.card}>{children}</div>
      </div>
    </Layout>
  )
}

export function ClNetworkError() {
  useEffect(() => {
    loggingClientPageView({ original_screen_name: 'cl_error_500' })
  }, [])

  return (
    <ErrorLayout>
      <ErrorHead />
      <h1 css={styles.head}>データの取得に失敗しました</h1>
      <p css={styles.text}>
        通信環境の良い場所で
        <br />
        再読み込みしてください。
      </p>
      <SecondaryButton css={styles.button} onClick={() => window.location.reload()}>
        再読み込み
      </SecondaryButton>
    </ErrorLayout>
  )
}

export function ClPageError() {
  useEffect(() => {
    loggingClientPageView({ original_screen_name: 'cl_error_404' })
  }, [])

  return (
    <ErrorLayout>
      <PageNotFoundErrorHead />
      <h1 css={styles.head}>
        お探しのページが
        <br />
        見つかりませんでした
      </h1>
      <p css={styles.text}>
        URLが間違っているか、ページが移動、
        <br />
        もしくは削除されている可能性があります。
        <br />
        お手数ですが、トップ画面から
        <br />
        お探しください。
      </p>
      <SecondaryButton
        css={styles.button}
        onClick={() => {
          window.location.href = '/cl'
        }}
      >
        トップへ
      </SecondaryButton>
    </ErrorLayout>
  )
}

export function ClServerError() {
  useEffect(() => {
    loggingClientPageView({ original_screen_name: 'cl_error_500' })
  }, [])

  return (
    <ErrorLayout>
      <ErrorHead />
      <h1 css={styles.head}>
        メンテナンス中
        <br />
        もしくはアクセス集中のため
        <br />
        表示できません
      </h1>
      <p css={styles.text}>
        お手数ですが、しばらく時間をおいてから
        <br />
        再度アクセスしてください。
      </p>
    </ErrorLayout>
  )
}

export function ClCustomerNotStartedError() {
  return (
    <ErrorLayout>
      <ErrorHead />
      <p css={styles.text}>
        お客様が招待状の作成を開始していません。
        <br />
        招待状作成開始後、お客様ページに
        <br />
        アクセスできるようになります。
      </p>
      <SecondaryButton
        css={styles.button}
        onClick={() => {
          window.location.href = '/cl'
        }}
      >
        トップへ
      </SecondaryButton>
    </ErrorLayout>
  )
}
export function ClCustomerNotInvitedError() {
  return (
    <ErrorLayout>
      <ErrorHead />
      <p css={styles.text}>
        お客様にURLを発行してください。
        <br />
        既にURLを発行済みの場合、URLの有効期限が切れているため、
        <br />
        新規URLを発行し再度お客様に送付してください。
      </p>
    </ErrorLayout>
  )
}

export const styles = {
  main: css`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
    border-bottom: 1px solid ${colors.COLOR_CDCDCD};
  `,
  card: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 196px;
    background-color: ${colors.COLOR_FFFFFF};
    border: 3px solid ${colors.COLOR_EFEFEF};
    border-radius: 10px;
  `,
  head: css`
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    color: ${colors.COLOR_4B4B4B};
    text-align: center;
    letter-spacing: 0.5px;
  `,
  text: css`
    margin-top: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    color: ${colors.COLOR_4B4B4B};
    text-align: center;
  `,
  button: css`
    width: 315px;
    height: 44px;
    padding: 15px;
    margin: 32px auto 0;
    font-size: 14px;
    letter-spacing: 0.5px;
  `,
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { ErrorResponse } from '../model'
// @ts-ignore
import { LoginResponse } from '../model'
// @ts-ignore
import { LogoutResponse } from '../model'
// @ts-ignore
import { VerifyInvitationURLPasswordRequest } from '../model'
// @ts-ignore
import { VerifyInvitationURLPasswordResponse } from '../model'
// @ts-ignore
import { VerifyPartnerInvitationPasswordRequest } from '../model'
// @ts-ignore
import { VerifyPartnerInvitationPasswordResponse } from '../model'
// @ts-ignore
import { VerifyPartnerInvitationRequest } from '../model'
// @ts-ignore
import { VerifyRegisterableInvitationUrlRequest } from '../model'
/**
 * CoupleAuthControllerApi - axios parameter creator
 * @export
 */
export const CoupleAuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    coupleLogin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cs/api/auth/login`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    coupleLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cs/api/auth/logout`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    coupleOneTimeRegister: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cs/api/auth/oneTimeRegister`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {VerifyInvitationURLPasswordRequest} verifyInvitationURLPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyInvitationURLPassword: async (
      verifyInvitationURLPasswordRequest: VerifyInvitationURLPasswordRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyInvitationURLPasswordRequest' is not null or undefined
      assertParamExists(
        'verifyInvitationURLPassword',
        'verifyInvitationURLPasswordRequest',
        verifyInvitationURLPasswordRequest,
      )
      const localVarPath = `/cs/api/auth/ams/verifyInvitationURLPassword`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyInvitationURLPasswordRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {VerifyPartnerInvitationRequest} verifyPartnerInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyPartnerInvitation: async (
      verifyPartnerInvitationRequest: VerifyPartnerInvitationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyPartnerInvitationRequest' is not null or undefined
      assertParamExists(
        'verifyPartnerInvitation',
        'verifyPartnerInvitationRequest',
        verifyPartnerInvitationRequest,
      )
      const localVarPath = `/cs/api/auth/verifyPartnerInvitation`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyPartnerInvitationRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {VerifyPartnerInvitationPasswordRequest} verifyPartnerInvitationPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyPartnerInvitationPassword: async (
      verifyPartnerInvitationPasswordRequest: VerifyPartnerInvitationPasswordRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyPartnerInvitationPasswordRequest' is not null or undefined
      assertParamExists(
        'verifyPartnerInvitationPassword',
        'verifyPartnerInvitationPasswordRequest',
        verifyPartnerInvitationPasswordRequest,
      )
      const localVarPath = `/cs/api/auth/verifyPartnerInvitationPassword`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyPartnerInvitationPasswordRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {VerifyRegisterableInvitationUrlRequest} verifyRegisterableInvitationUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyRegisterableInvitationUrl: async (
      verifyRegisterableInvitationUrlRequest: VerifyRegisterableInvitationUrlRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyRegisterableInvitationUrlRequest' is not null or undefined
      assertParamExists(
        'verifyRegisterableInvitationUrl',
        'verifyRegisterableInvitationUrlRequest',
        verifyRegisterableInvitationUrlRequest,
      )
      const localVarPath = `/cs/api/auth/ams/verifyRegisterableInvitationURL`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyRegisterableInvitationUrlRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CoupleAuthControllerApi - functional programming interface
 * @export
 */
export const CoupleAuthControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CoupleAuthControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async coupleLogin(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.coupleLogin(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async coupleLogout(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogoutResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.coupleLogout(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async coupleOneTimeRegister(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.coupleOneTimeRegister(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {VerifyInvitationURLPasswordRequest} verifyInvitationURLPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyInvitationURLPassword(
      verifyInvitationURLPasswordRequest: VerifyInvitationURLPasswordRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<VerifyInvitationURLPasswordResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifyInvitationURLPassword(
        verifyInvitationURLPasswordRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {VerifyPartnerInvitationRequest} verifyPartnerInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyPartnerInvitation(
      verifyPartnerInvitationRequest: VerifyPartnerInvitationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifyPartnerInvitation(
        verifyPartnerInvitationRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {VerifyPartnerInvitationPasswordRequest} verifyPartnerInvitationPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyPartnerInvitationPassword(
      verifyPartnerInvitationPasswordRequest: VerifyPartnerInvitationPasswordRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<VerifyPartnerInvitationPasswordResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifyPartnerInvitationPassword(
        verifyPartnerInvitationPasswordRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {VerifyRegisterableInvitationUrlRequest} verifyRegisterableInvitationUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyRegisterableInvitationUrl(
      verifyRegisterableInvitationUrlRequest: VerifyRegisterableInvitationUrlRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifyRegisterableInvitationUrl(
        verifyRegisterableInvitationUrlRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CoupleAuthControllerApi - factory interface
 * @export
 */
export const CoupleAuthControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CoupleAuthControllerApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    coupleLogin(options?: any): AxiosPromise<LoginResponse> {
      return localVarFp.coupleLogin(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    coupleLogout(options?: any): AxiosPromise<LogoutResponse> {
      return localVarFp.coupleLogout(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    coupleOneTimeRegister(options?: any): AxiosPromise<object> {
      return localVarFp.coupleOneTimeRegister(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {VerifyInvitationURLPasswordRequest} verifyInvitationURLPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyInvitationURLPassword(
      verifyInvitationURLPasswordRequest: VerifyInvitationURLPasswordRequest,
      options?: any,
    ): AxiosPromise<VerifyInvitationURLPasswordResponse> {
      return localVarFp
        .verifyInvitationURLPassword(verifyInvitationURLPasswordRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {VerifyPartnerInvitationRequest} verifyPartnerInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyPartnerInvitation(
      verifyPartnerInvitationRequest: VerifyPartnerInvitationRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .verifyPartnerInvitation(verifyPartnerInvitationRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {VerifyPartnerInvitationPasswordRequest} verifyPartnerInvitationPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyPartnerInvitationPassword(
      verifyPartnerInvitationPasswordRequest: VerifyPartnerInvitationPasswordRequest,
      options?: any,
    ): AxiosPromise<VerifyPartnerInvitationPasswordResponse> {
      return localVarFp
        .verifyPartnerInvitationPassword(verifyPartnerInvitationPasswordRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {VerifyRegisterableInvitationUrlRequest} verifyRegisterableInvitationUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyRegisterableInvitationUrl(
      verifyRegisterableInvitationUrlRequest: VerifyRegisterableInvitationUrlRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .verifyRegisterableInvitationUrl(verifyRegisterableInvitationUrlRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * CoupleAuthControllerApi - object-oriented interface
 * @export
 * @class CoupleAuthControllerApi
 * @extends {BaseAPI}
 */
export class CoupleAuthControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleAuthControllerApi
   */
  public coupleLogin(options?: AxiosRequestConfig) {
    return CoupleAuthControllerApiFp(this.configuration)
      .coupleLogin(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleAuthControllerApi
   */
  public coupleLogout(options?: AxiosRequestConfig) {
    return CoupleAuthControllerApiFp(this.configuration)
      .coupleLogout(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleAuthControllerApi
   */
  public coupleOneTimeRegister(options?: AxiosRequestConfig) {
    return CoupleAuthControllerApiFp(this.configuration)
      .coupleOneTimeRegister(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {VerifyInvitationURLPasswordRequest} verifyInvitationURLPasswordRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleAuthControllerApi
   */
  public verifyInvitationURLPassword(
    verifyInvitationURLPasswordRequest: VerifyInvitationURLPasswordRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleAuthControllerApiFp(this.configuration)
      .verifyInvitationURLPassword(verifyInvitationURLPasswordRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {VerifyPartnerInvitationRequest} verifyPartnerInvitationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleAuthControllerApi
   */
  public verifyPartnerInvitation(
    verifyPartnerInvitationRequest: VerifyPartnerInvitationRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleAuthControllerApiFp(this.configuration)
      .verifyPartnerInvitation(verifyPartnerInvitationRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {VerifyPartnerInvitationPasswordRequest} verifyPartnerInvitationPasswordRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleAuthControllerApi
   */
  public verifyPartnerInvitationPassword(
    verifyPartnerInvitationPasswordRequest: VerifyPartnerInvitationPasswordRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleAuthControllerApiFp(this.configuration)
      .verifyPartnerInvitationPassword(verifyPartnerInvitationPasswordRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {VerifyRegisterableInvitationUrlRequest} verifyRegisterableInvitationUrlRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleAuthControllerApi
   */
  public verifyRegisterableInvitationUrl(
    verifyRegisterableInvitationUrlRequest: VerifyRegisterableInvitationUrlRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleAuthControllerApiFp(this.configuration)
      .verifyRegisterableInvitationUrl(verifyRegisterableInvitationUrlRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { CreateInvitationRequest } from '../model'
// @ts-ignore
import { CreateInvitationResponse } from '../model'
// @ts-ignore
import { DuplicateInvitationRequest } from '../model'
// @ts-ignore
import { DuplicateInvitationResponse } from '../model'
// @ts-ignore
import { ErrorResponse } from '../model'
// @ts-ignore
import { GetInvitationContentsRequest } from '../model'
// @ts-ignore
import { GetInvitationContentsResponse } from '../model'
// @ts-ignore
import { GetInvitationDataResponse } from '../model'
// @ts-ignore
import { GetInvitationResponseSettingRequest } from '../model'
// @ts-ignore
import { GetInvitationResponseSettingResponse } from '../model'
// @ts-ignore
import { GetInvitationSendSettingRequest } from '../model'
// @ts-ignore
import { GetInvitationSendSettingResponse } from '../model'
// @ts-ignore
import { GetPaymentSettingRequest } from '../model'
// @ts-ignore
import { GetPaymentSettingResponse } from '../model'
// @ts-ignore
import { GuestPaymentPreviewDataRequest } from '../model'
// @ts-ignore
import { GuestPaymentPreviewDataResponse } from '../model'
// @ts-ignore
import { InvitationPreviewDataRequest } from '../model'
// @ts-ignore
import { PublishInvitationRequest } from '../model'
// @ts-ignore
import { PublishInvitationResponse } from '../model'
// @ts-ignore
import { StopPublishInvitationResponse } from '../model'
// @ts-ignore
import { UpdateInvitationContentsRequest } from '../model'
// @ts-ignore
import { UpdateInvitationContentsResponse } from '../model'
// @ts-ignore
import { UpdateInvitationDesignThemeRequest } from '../model'
// @ts-ignore
import { UpdateInvitationDesignThemeResponse } from '../model'
// @ts-ignore
import { UpdateInvitationNameRequest } from '../model'
// @ts-ignore
import { UpdateInvitationNameResponse } from '../model'
// @ts-ignore
import { UpdateInvitationPasswordRequest } from '../model'
// @ts-ignore
import { UpdateInvitationPasswordResponse } from '../model'
// @ts-ignore
import { UpdateInvitationResponseSettingRequest } from '../model'
// @ts-ignore
import { UpdateInvitationResponseSettingResponse } from '../model'
// @ts-ignore
import { UpdatePaymentSettingRequest } from '../model'
// @ts-ignore
import { UpdatePaymentSettingResponse } from '../model'
/**
 * CoupleInvitationControllerApi - axios parameter creator
 * @export
 */
export const CoupleInvitationControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CreateInvitationRequest} createInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInvitation: async (
      createInvitationRequest: CreateInvitationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createInvitationRequest' is not null or undefined
      assertParamExists('createInvitation', 'createInvitationRequest', createInvitationRequest)
      const localVarPath = `/cs/api/createInvitation`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createInvitationRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {DuplicateInvitationRequest} duplicateInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicateInvitation: async (
      duplicateInvitationRequest: DuplicateInvitationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'duplicateInvitationRequest' is not null or undefined
      assertParamExists(
        'duplicateInvitation',
        'duplicateInvitationRequest',
        duplicateInvitationRequest,
      )
      const localVarPath = `/cs/api/duplicateInvitation`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        duplicateInvitationRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GuestPaymentPreviewDataRequest} guestPaymentPreviewDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGuestPaymentPreviewData: async (
      guestPaymentPreviewDataRequest: GuestPaymentPreviewDataRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'guestPaymentPreviewDataRequest' is not null or undefined
      assertParamExists(
        'getGuestPaymentPreviewData',
        'guestPaymentPreviewDataRequest',
        guestPaymentPreviewDataRequest,
      )
      const localVarPath = `/cs/api/getGuestPaymentPreviewData`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        guestPaymentPreviewDataRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetInvitationContentsRequest} getInvitationContentsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvitationContents: async (
      getInvitationContentsRequest: GetInvitationContentsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getInvitationContentsRequest' is not null or undefined
      assertParamExists(
        'getInvitationContents',
        'getInvitationContentsRequest',
        getInvitationContentsRequest,
      )
      const localVarPath = `/cs/api/getInvitationContents`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getInvitationContentsRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetPaymentSettingRequest} getPaymentSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvitationPaymentSetting: async (
      getPaymentSettingRequest: GetPaymentSettingRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getPaymentSettingRequest' is not null or undefined
      assertParamExists(
        'getInvitationPaymentSetting',
        'getPaymentSettingRequest',
        getPaymentSettingRequest,
      )
      const localVarPath = `/cs/api/getInvitationPaymentSetting`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getPaymentSettingRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {InvitationPreviewDataRequest} invitationPreviewDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvitationPreviewData: async (
      invitationPreviewDataRequest: InvitationPreviewDataRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'invitationPreviewDataRequest' is not null or undefined
      assertParamExists(
        'getInvitationPreviewData',
        'invitationPreviewDataRequest',
        invitationPreviewDataRequest,
      )
      const localVarPath = `/cs/api/getInvitationPreviewData`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        invitationPreviewDataRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetInvitationResponseSettingRequest} getInvitationResponseSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvitationResponseSetting: async (
      getInvitationResponseSettingRequest: GetInvitationResponseSettingRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getInvitationResponseSettingRequest' is not null or undefined
      assertParamExists(
        'getInvitationResponseSetting',
        'getInvitationResponseSettingRequest',
        getInvitationResponseSettingRequest,
      )
      const localVarPath = `/cs/api/getInvitationResponseSetting`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getInvitationResponseSettingRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetInvitationSendSettingRequest} getInvitationSendSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvitationSendSetting: async (
      getInvitationSendSettingRequest: GetInvitationSendSettingRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getInvitationSendSettingRequest' is not null or undefined
      assertParamExists(
        'getInvitationSendSetting',
        'getInvitationSendSettingRequest',
        getInvitationSendSettingRequest,
      )
      const localVarPath = `/cs/api/getInvitationSendSetting`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getInvitationSendSettingRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {PublishInvitationRequest} publishInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishInvitation: async (
      publishInvitationRequest: PublishInvitationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'publishInvitationRequest' is not null or undefined
      assertParamExists('publishInvitation', 'publishInvitationRequest', publishInvitationRequest)
      const localVarPath = `/cs/api/publishInvitation`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        publishInvitationRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {PublishInvitationRequest} publishInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopPublishInvitation: async (
      publishInvitationRequest: PublishInvitationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'publishInvitationRequest' is not null or undefined
      assertParamExists(
        'stopPublishInvitation',
        'publishInvitationRequest',
        publishInvitationRequest,
      )
      const localVarPath = `/cs/api/stopPublishInvitation`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        publishInvitationRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateInvitationContentsRequest} updateInvitationContentsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInvitationContents: async (
      updateInvitationContentsRequest: UpdateInvitationContentsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateInvitationContentsRequest' is not null or undefined
      assertParamExists(
        'updateInvitationContents',
        'updateInvitationContentsRequest',
        updateInvitationContentsRequest,
      )
      const localVarPath = `/cs/api/updateInvitationContents`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateInvitationContentsRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateInvitationDesignThemeRequest} updateInvitationDesignThemeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInvitationDesignTheme: async (
      updateInvitationDesignThemeRequest: UpdateInvitationDesignThemeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateInvitationDesignThemeRequest' is not null or undefined
      assertParamExists(
        'updateInvitationDesignTheme',
        'updateInvitationDesignThemeRequest',
        updateInvitationDesignThemeRequest,
      )
      const localVarPath = `/cs/api/updateInvitationDesignTheme`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateInvitationDesignThemeRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateInvitationNameRequest} updateInvitationNameRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInvitationName: async (
      updateInvitationNameRequest: UpdateInvitationNameRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateInvitationNameRequest' is not null or undefined
      assertParamExists(
        'updateInvitationName',
        'updateInvitationNameRequest',
        updateInvitationNameRequest,
      )
      const localVarPath = `/cs/api/updateInvitationName`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateInvitationNameRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateInvitationPasswordRequest} updateInvitationPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInvitationPassword: async (
      updateInvitationPasswordRequest: UpdateInvitationPasswordRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateInvitationPasswordRequest' is not null or undefined
      assertParamExists(
        'updateInvitationPassword',
        'updateInvitationPasswordRequest',
        updateInvitationPasswordRequest,
      )
      const localVarPath = `/cs/api/updateInvitationPassword`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateInvitationPasswordRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdatePaymentSettingRequest} updatePaymentSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInvitationPaymentSetting: async (
      updatePaymentSettingRequest: UpdatePaymentSettingRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updatePaymentSettingRequest' is not null or undefined
      assertParamExists(
        'updateInvitationPaymentSetting',
        'updatePaymentSettingRequest',
        updatePaymentSettingRequest,
      )
      const localVarPath = `/cs/api/updateInvitationPaymentSetting`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePaymentSettingRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateInvitationResponseSettingRequest} updateInvitationResponseSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInvitationResponseSetting: async (
      updateInvitationResponseSettingRequest: UpdateInvitationResponseSettingRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateInvitationResponseSettingRequest' is not null or undefined
      assertParamExists(
        'updateInvitationResponseSetting',
        'updateInvitationResponseSettingRequest',
        updateInvitationResponseSettingRequest,
      )
      const localVarPath = `/cs/api/updateInvitationResponseSetting`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateInvitationResponseSettingRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CoupleInvitationControllerApi - functional programming interface
 * @export
 */
export const CoupleInvitationControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CoupleInvitationControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {CreateInvitationRequest} createInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createInvitation(
      createInvitationRequest: CreateInvitationRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateInvitationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createInvitation(
        createInvitationRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {DuplicateInvitationRequest} duplicateInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async duplicateInvitation(
      duplicateInvitationRequest: DuplicateInvitationRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DuplicateInvitationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateInvitation(
        duplicateInvitationRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GuestPaymentPreviewDataRequest} guestPaymentPreviewDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGuestPaymentPreviewData(
      guestPaymentPreviewDataRequest: GuestPaymentPreviewDataRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuestPaymentPreviewDataResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGuestPaymentPreviewData(
        guestPaymentPreviewDataRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetInvitationContentsRequest} getInvitationContentsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInvitationContents(
      getInvitationContentsRequest: GetInvitationContentsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInvitationContentsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationContents(
        getInvitationContentsRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetPaymentSettingRequest} getPaymentSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInvitationPaymentSetting(
      getPaymentSettingRequest: GetPaymentSettingRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaymentSettingResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationPaymentSetting(
        getPaymentSettingRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {InvitationPreviewDataRequest} invitationPreviewDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInvitationPreviewData(
      invitationPreviewDataRequest: InvitationPreviewDataRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInvitationDataResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationPreviewData(
        invitationPreviewDataRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetInvitationResponseSettingRequest} getInvitationResponseSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInvitationResponseSetting(
      getInvitationResponseSettingRequest: GetInvitationResponseSettingRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetInvitationResponseSettingResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationResponseSetting(
        getInvitationResponseSettingRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetInvitationSendSettingRequest} getInvitationSendSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInvitationSendSetting(
      getInvitationSendSettingRequest: GetInvitationSendSettingRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInvitationSendSettingResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationSendSetting(
        getInvitationSendSettingRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {PublishInvitationRequest} publishInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publishInvitation(
      publishInvitationRequest: PublishInvitationRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublishInvitationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publishInvitation(
        publishInvitationRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {PublishInvitationRequest} publishInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stopPublishInvitation(
      publishInvitationRequest: PublishInvitationRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StopPublishInvitationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.stopPublishInvitation(
        publishInvitationRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateInvitationContentsRequest} updateInvitationContentsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateInvitationContents(
      updateInvitationContentsRequest: UpdateInvitationContentsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateInvitationContentsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvitationContents(
        updateInvitationContentsRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateInvitationDesignThemeRequest} updateInvitationDesignThemeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateInvitationDesignTheme(
      updateInvitationDesignThemeRequest: UpdateInvitationDesignThemeRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateInvitationDesignThemeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvitationDesignTheme(
        updateInvitationDesignThemeRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateInvitationNameRequest} updateInvitationNameRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateInvitationName(
      updateInvitationNameRequest: UpdateInvitationNameRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateInvitationNameResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvitationName(
        updateInvitationNameRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateInvitationPasswordRequest} updateInvitationPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateInvitationPassword(
      updateInvitationPasswordRequest: UpdateInvitationPasswordRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateInvitationPasswordResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvitationPassword(
        updateInvitationPasswordRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdatePaymentSettingRequest} updatePaymentSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateInvitationPaymentSetting(
      updatePaymentSettingRequest: UpdatePaymentSettingRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePaymentSettingResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvitationPaymentSetting(
        updatePaymentSettingRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateInvitationResponseSettingRequest} updateInvitationResponseSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateInvitationResponseSetting(
      updateInvitationResponseSettingRequest: UpdateInvitationResponseSettingRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateInvitationResponseSettingResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvitationResponseSetting(
        updateInvitationResponseSettingRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CoupleInvitationControllerApi - factory interface
 * @export
 */
export const CoupleInvitationControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CoupleInvitationControllerApiFp(configuration)
  return {
    /**
     *
     * @param {CreateInvitationRequest} createInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInvitation(
      createInvitationRequest: CreateInvitationRequest,
      options?: any,
    ): AxiosPromise<CreateInvitationResponse> {
      return localVarFp
        .createInvitation(createInvitationRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {DuplicateInvitationRequest} duplicateInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicateInvitation(
      duplicateInvitationRequest: DuplicateInvitationRequest,
      options?: any,
    ): AxiosPromise<DuplicateInvitationResponse> {
      return localVarFp
        .duplicateInvitation(duplicateInvitationRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GuestPaymentPreviewDataRequest} guestPaymentPreviewDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGuestPaymentPreviewData(
      guestPaymentPreviewDataRequest: GuestPaymentPreviewDataRequest,
      options?: any,
    ): AxiosPromise<GuestPaymentPreviewDataResponse> {
      return localVarFp
        .getGuestPaymentPreviewData(guestPaymentPreviewDataRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetInvitationContentsRequest} getInvitationContentsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvitationContents(
      getInvitationContentsRequest: GetInvitationContentsRequest,
      options?: any,
    ): AxiosPromise<GetInvitationContentsResponse> {
      return localVarFp
        .getInvitationContents(getInvitationContentsRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetPaymentSettingRequest} getPaymentSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvitationPaymentSetting(
      getPaymentSettingRequest: GetPaymentSettingRequest,
      options?: any,
    ): AxiosPromise<GetPaymentSettingResponse> {
      return localVarFp
        .getInvitationPaymentSetting(getPaymentSettingRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {InvitationPreviewDataRequest} invitationPreviewDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvitationPreviewData(
      invitationPreviewDataRequest: InvitationPreviewDataRequest,
      options?: any,
    ): AxiosPromise<GetInvitationDataResponse> {
      return localVarFp
        .getInvitationPreviewData(invitationPreviewDataRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetInvitationResponseSettingRequest} getInvitationResponseSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvitationResponseSetting(
      getInvitationResponseSettingRequest: GetInvitationResponseSettingRequest,
      options?: any,
    ): AxiosPromise<GetInvitationResponseSettingResponse> {
      return localVarFp
        .getInvitationResponseSetting(getInvitationResponseSettingRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetInvitationSendSettingRequest} getInvitationSendSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvitationSendSetting(
      getInvitationSendSettingRequest: GetInvitationSendSettingRequest,
      options?: any,
    ): AxiosPromise<GetInvitationSendSettingResponse> {
      return localVarFp
        .getInvitationSendSetting(getInvitationSendSettingRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {PublishInvitationRequest} publishInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishInvitation(
      publishInvitationRequest: PublishInvitationRequest,
      options?: any,
    ): AxiosPromise<PublishInvitationResponse> {
      return localVarFp
        .publishInvitation(publishInvitationRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {PublishInvitationRequest} publishInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopPublishInvitation(
      publishInvitationRequest: PublishInvitationRequest,
      options?: any,
    ): AxiosPromise<StopPublishInvitationResponse> {
      return localVarFp
        .stopPublishInvitation(publishInvitationRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateInvitationContentsRequest} updateInvitationContentsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInvitationContents(
      updateInvitationContentsRequest: UpdateInvitationContentsRequest,
      options?: any,
    ): AxiosPromise<UpdateInvitationContentsResponse> {
      return localVarFp
        .updateInvitationContents(updateInvitationContentsRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateInvitationDesignThemeRequest} updateInvitationDesignThemeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInvitationDesignTheme(
      updateInvitationDesignThemeRequest: UpdateInvitationDesignThemeRequest,
      options?: any,
    ): AxiosPromise<UpdateInvitationDesignThemeResponse> {
      return localVarFp
        .updateInvitationDesignTheme(updateInvitationDesignThemeRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateInvitationNameRequest} updateInvitationNameRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInvitationName(
      updateInvitationNameRequest: UpdateInvitationNameRequest,
      options?: any,
    ): AxiosPromise<UpdateInvitationNameResponse> {
      return localVarFp
        .updateInvitationName(updateInvitationNameRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateInvitationPasswordRequest} updateInvitationPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInvitationPassword(
      updateInvitationPasswordRequest: UpdateInvitationPasswordRequest,
      options?: any,
    ): AxiosPromise<UpdateInvitationPasswordResponse> {
      return localVarFp
        .updateInvitationPassword(updateInvitationPasswordRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdatePaymentSettingRequest} updatePaymentSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInvitationPaymentSetting(
      updatePaymentSettingRequest: UpdatePaymentSettingRequest,
      options?: any,
    ): AxiosPromise<UpdatePaymentSettingResponse> {
      return localVarFp
        .updateInvitationPaymentSetting(updatePaymentSettingRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateInvitationResponseSettingRequest} updateInvitationResponseSettingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInvitationResponseSetting(
      updateInvitationResponseSettingRequest: UpdateInvitationResponseSettingRequest,
      options?: any,
    ): AxiosPromise<UpdateInvitationResponseSettingResponse> {
      return localVarFp
        .updateInvitationResponseSetting(updateInvitationResponseSettingRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * CoupleInvitationControllerApi - object-oriented interface
 * @export
 * @class CoupleInvitationControllerApi
 * @extends {BaseAPI}
 */
export class CoupleInvitationControllerApi extends BaseAPI {
  /**
   *
   * @param {CreateInvitationRequest} createInvitationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleInvitationControllerApi
   */
  public createInvitation(
    createInvitationRequest: CreateInvitationRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleInvitationControllerApiFp(this.configuration)
      .createInvitation(createInvitationRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DuplicateInvitationRequest} duplicateInvitationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleInvitationControllerApi
   */
  public duplicateInvitation(
    duplicateInvitationRequest: DuplicateInvitationRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleInvitationControllerApiFp(this.configuration)
      .duplicateInvitation(duplicateInvitationRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GuestPaymentPreviewDataRequest} guestPaymentPreviewDataRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleInvitationControllerApi
   */
  public getGuestPaymentPreviewData(
    guestPaymentPreviewDataRequest: GuestPaymentPreviewDataRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleInvitationControllerApiFp(this.configuration)
      .getGuestPaymentPreviewData(guestPaymentPreviewDataRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetInvitationContentsRequest} getInvitationContentsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleInvitationControllerApi
   */
  public getInvitationContents(
    getInvitationContentsRequest: GetInvitationContentsRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleInvitationControllerApiFp(this.configuration)
      .getInvitationContents(getInvitationContentsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetPaymentSettingRequest} getPaymentSettingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleInvitationControllerApi
   */
  public getInvitationPaymentSetting(
    getPaymentSettingRequest: GetPaymentSettingRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleInvitationControllerApiFp(this.configuration)
      .getInvitationPaymentSetting(getPaymentSettingRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {InvitationPreviewDataRequest} invitationPreviewDataRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleInvitationControllerApi
   */
  public getInvitationPreviewData(
    invitationPreviewDataRequest: InvitationPreviewDataRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleInvitationControllerApiFp(this.configuration)
      .getInvitationPreviewData(invitationPreviewDataRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetInvitationResponseSettingRequest} getInvitationResponseSettingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleInvitationControllerApi
   */
  public getInvitationResponseSetting(
    getInvitationResponseSettingRequest: GetInvitationResponseSettingRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleInvitationControllerApiFp(this.configuration)
      .getInvitationResponseSetting(getInvitationResponseSettingRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetInvitationSendSettingRequest} getInvitationSendSettingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleInvitationControllerApi
   */
  public getInvitationSendSetting(
    getInvitationSendSettingRequest: GetInvitationSendSettingRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleInvitationControllerApiFp(this.configuration)
      .getInvitationSendSetting(getInvitationSendSettingRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {PublishInvitationRequest} publishInvitationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleInvitationControllerApi
   */
  public publishInvitation(
    publishInvitationRequest: PublishInvitationRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleInvitationControllerApiFp(this.configuration)
      .publishInvitation(publishInvitationRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {PublishInvitationRequest} publishInvitationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleInvitationControllerApi
   */
  public stopPublishInvitation(
    publishInvitationRequest: PublishInvitationRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleInvitationControllerApiFp(this.configuration)
      .stopPublishInvitation(publishInvitationRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateInvitationContentsRequest} updateInvitationContentsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleInvitationControllerApi
   */
  public updateInvitationContents(
    updateInvitationContentsRequest: UpdateInvitationContentsRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleInvitationControllerApiFp(this.configuration)
      .updateInvitationContents(updateInvitationContentsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateInvitationDesignThemeRequest} updateInvitationDesignThemeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleInvitationControllerApi
   */
  public updateInvitationDesignTheme(
    updateInvitationDesignThemeRequest: UpdateInvitationDesignThemeRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleInvitationControllerApiFp(this.configuration)
      .updateInvitationDesignTheme(updateInvitationDesignThemeRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateInvitationNameRequest} updateInvitationNameRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleInvitationControllerApi
   */
  public updateInvitationName(
    updateInvitationNameRequest: UpdateInvitationNameRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleInvitationControllerApiFp(this.configuration)
      .updateInvitationName(updateInvitationNameRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateInvitationPasswordRequest} updateInvitationPasswordRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleInvitationControllerApi
   */
  public updateInvitationPassword(
    updateInvitationPasswordRequest: UpdateInvitationPasswordRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleInvitationControllerApiFp(this.configuration)
      .updateInvitationPassword(updateInvitationPasswordRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdatePaymentSettingRequest} updatePaymentSettingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleInvitationControllerApi
   */
  public updateInvitationPaymentSetting(
    updatePaymentSettingRequest: UpdatePaymentSettingRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleInvitationControllerApiFp(this.configuration)
      .updateInvitationPaymentSetting(updatePaymentSettingRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateInvitationResponseSettingRequest} updateInvitationResponseSettingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleInvitationControllerApi
   */
  public updateInvitationResponseSetting(
    updateInvitationResponseSettingRequest: UpdateInvitationResponseSettingRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleInvitationControllerApiFp(this.configuration)
      .updateInvitationResponseSetting(updateInvitationResponseSettingRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

import { useConfig } from '@/context/config'
import { colors } from '@/styles/colors'
import { breakpoints } from '@/styles/cs/styleConstants'
import { CS_EXTERNAL_WEB_URL } from '@/utils/common/externalWebURL'
import { css } from '@emotion/react'
import Image from 'next/image'

export default function Footer() {
  const { config } = useConfig()
  return (
    <footer css={styles.footer}>
      <div css={styles.row}>
        <div css={styles.links}>
          <a href={CS_EXTERNAL_WEB_URL(config).FAQ} target="_blank" rel="noopener noreferrer">
            よくある質問・お問い合わせ
          </a>
          <a
            href={CS_EXTERNAL_WEB_URL(config).SERVICE_TERMS}
            target="_blank"
            rel="noopener noreferrer"
          >
            利用規約
          </a>
          <a
            href={CS_EXTERNAL_WEB_URL(config).MEMBERSHIP_AGREEMENT}
            target="_blank"
            rel="noopener noreferrer"
          >
            ID・会員規約
          </a>
          <a
            href={CS_EXTERNAL_WEB_URL(config).PRIVACY_POLICY}
            target="_blank"
            rel="noopener noreferrer"
          >
            プライバシーポリシー
          </a>
          <a
            href={CS_EXTERNAL_WEB_URL(config).SPECIFIED_COMMERCIAL_TRANSACTION}
            target="_blank"
            rel="noopener noreferrer"
          >
            特定商取引に基づく表示
          </a>
        </div>
        <div css={styles.recruit}>
          <a
            href={CS_EXTERNAL_WEB_URL(config).RECRUIT}
            target="_blank"
            rel="noopener noreferrer"
            css={styles.recruitLink}
          >
            <Image src="/images/recruit-logo.png" width={100} height={26} layout={'fixed'} />
            <div css={styles.copyright}>(C) Recruit Co., Ltd.</div>
          </a>
        </div>
      </div>
    </footer>
  )
}

const styles = {
  footer: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px;
    margin-top: auto;
    background: ${colors.COLOR_F7F8FA};
    @media (max-width: ${breakpoints.sp}px) {
      padding: 24px;
    }
  `,
  row: css`
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: -32px 0 0 0;
  `,
  recruit: css`
    padding: 0 24px 0 0;
    margin: 32px auto 0 0;
  `,
  recruitLink: css`
    display: flex;
    align-items: flex-end;
    text-decoration-line: none;
  `,
  links: css`
    display: flex;
    flex-wrap: wrap;
    margin: 24px 0 0 -12px;
    a {
      padding: 0 12px;
      margin: 12px 0 0 0;
      font-size: 11px;
      font-weight: 500;
      line-height: 100%;
      color: ${colors.COLOR_4B4B4B};
      text-decoration-line: none;
      letter-spacing: 0.5px;
      border-right: 1px solid rgba(60, 60, 67, 0.1);
    }
    a:last-child {
      padding: 0 0 0 12px;
      border-right: none;
    }
  `,
  copyright: css`
    margin-left: 10px;
    font-size: 10px;
    font-weight: 700;
    line-height: 100%;
    color: ${colors.COLOR_435B67};
    text-align: right;
    letter-spacing: 0.5px;
  `,
}

import Footer from '@/components/cl/footer'
import Header from '@/components/cl/header'
import Loading from '@/components/cl/loading'
import { useLoading } from '@/context/loading'
import { fontFamily, zIndexModal } from '@/styles/cl/styleConstants'
import { css } from '@emotion/react'
import { createTheme, ThemeProvider } from '@mui/material/styles'

type GenerateStyleType = {
  backgroundColor: string
  paddingBottom: string
  sideMenuProps: null | {
    opened?: boolean
  }
}

export default function Layout({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  children,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  SideMenu,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  sideMenuProps,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  backgroundColor,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  paddingBottom,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  logoLink,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  isLoadingEnabled,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  isHideHeaderFooter,
  isClPreview = false,
}) {
  const styles = generateStyle({ backgroundColor, paddingBottom, sideMenuProps })
  const { loading } = useLoading()

  return (
    <ThemeProvider theme={theme}>
      {isClPreview ? (
        <>{children}</>
      ) : (
        <div css={styles.wrapper}>
          {SideMenu && <SideMenu {...sideMenuProps} />}
          <div css={styles.mainWrapper}>
            {!isHideHeaderFooter && <Header logoLink={logoLink} />}
            {children}
            {!isHideHeaderFooter && <Footer />}
          </div>
          {isLoadingEnabled && loading && (
            <div css={styles.loading}>
              <Loading size={58} thickness={6} />
            </div>
          )}
        </div>
      )}
    </ThemeProvider>
  )
}

Layout.defaultProps = {
  SideMenu: null,
  logoLink: null,
  sideMenuProps: null,
  /* 画面下部に固定で表示するフロートボタンを表示する際に指定する */
  paddingBottom: 0,
  /* 全体ローディングを使用したくないページで指定する */
  isLoadingEnabled: true,
  /* header/footerを非表示にする */
  isHideHeaderFooter: false,
}

const generateStyle = ({ backgroundColor, paddingBottom, sideMenuProps }: GenerateStyleType) => ({
  wrapper: css`
    display: flex;
    font-family: ${fontFamily};
  `,
  loading: css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${zIndexModal + 1};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
  `,
  mainWrapper: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: ${sideMenuProps?.opened ? '1040px' : '1220px'};
    min-height: 100vh;
    padding-bottom: ${paddingBottom}px;
    background-color: ${backgroundColor};
  `,
})

const theme = createTheme({
  typography: {
    fontFamily: [fontFamily].join(','),
  },
})

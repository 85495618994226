// APIのエラーコードに該当するメッセージ
import { ErrorResponse } from '@/data/gen'

export const MESSAGE: { [name: string]: string } = {
  NETWORK_TIMEOUT:
    'ネットワークに接続できませんでした。通信環境の良い場所で再読み込みしてください。',
  INTERNAL_SERVER_ERROR:
    'メンテナンス中、もしくはアクセス集中のため表示できません。お手数ですが、しばらく時間をおいてから再度アクセスしてください。',

  // クライアント認証エラー
  CL_AUTH_LOGIN_FAILED:
    '入力されたzebra IDまたはパスワードが正しくありません。Caps Lockが押されていないか？日本語入力がONになっていないか？など確認していただいて、もう一度入力してください。',
  CL_AUTH_ACCOUNT_LOCKED: 'アカウントがロックされています。',
  CL_AUTH_IS_TEMPORARY_ACCOUNT:
    '入力されたzebra IDは本サービスに登録されていません。zebraにてアカウント登録を完了してください。',
  CL_AUTH_PASSWORD_EXPIRED:
    '入力されたzebra IDはパスワードの有効期限が切れています。zebraにてパスワードを変更してください。',
  CL_AUTH_HAS_NO_AVAILABLE_CLIENT: '入力されたzebra IDは本サービスに登録されていません。',
  CL_AUTH_UNAUTHORIZED:
    '入力されたzebra IDでは本サービスをご利用いただくことはできません。管理者権限をお持ちの方にアカウント権限の変更を依頼してください。',
}

export const getMessage = (response: ErrorResponse) =>
  MESSAGE[response.code] ||
  (!window.navigator.onLine ? MESSAGE.NETWORK_TIMEOUT : MESSAGE.INTERNAL_SERVER_ERROR)

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { InvitationCustomResponseSettingForRequest } from './invitation-custom-response-setting-for-request'
import { PrePartyImageShareSettingForRequest } from './pre-party-image-share-setting-for-request'

/**
 *
 * @export
 * @interface UpdateInvitationResponseSettingRequest
 */
export interface UpdateInvitationResponseSettingRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationResponseSettingRequest
   */
  invitationUUID: string
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationResponseSettingRequest
   */
  whichGuest?: UpdateInvitationResponseSettingRequestWhichGuest
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationResponseSettingRequest
   */
  sex?: UpdateInvitationResponseSettingRequestSex
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationResponseSettingRequest
   */
  address?: UpdateInvitationResponseSettingRequestAddress
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationResponseSettingRequest
   */
  tel?: UpdateInvitationResponseSettingRequestTel
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationResponseSettingRequest
   */
  message?: UpdateInvitationResponseSettingRequestMessage
  /**
   *
   * @type {boolean}
   * @memberof UpdateInvitationResponseSettingRequest
   */
  canAddGuestCompanion?: boolean
  /**
   *
   * @type {InvitationCustomResponseSettingForRequest}
   * @memberof UpdateInvitationResponseSettingRequest
   */
  invitationCustomResponseSetting: InvitationCustomResponseSettingForRequest
  /**
   *
   * @type {PrePartyImageShareSettingForRequest}
   * @memberof UpdateInvitationResponseSettingRequest
   */
  prePartyImageShareSetting: PrePartyImageShareSettingForRequest
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateInvitationResponseSettingRequestWhichGuest {
  hidden = 'HIDDEN',
  required = 'REQUIRED',
}
/**
 * @export
 * @enum {string}
 */
export enum UpdateInvitationResponseSettingRequestSex {
  hidden = 'HIDDEN',
  required = 'REQUIRED',
}
/**
 * @export
 * @enum {string}
 */
export enum UpdateInvitationResponseSettingRequestAddress {
  hidden = 'HIDDEN',
  required = 'REQUIRED',
}
/**
 * @export
 * @enum {string}
 */
export enum UpdateInvitationResponseSettingRequestTel {
  hidden = 'HIDDEN',
  required = 'REQUIRED',
}
/**
 * @export
 * @enum {string}
 */
export enum UpdateInvitationResponseSettingRequestMessage {
  hidden = 'HIDDEN',
  required = 'REQUIRED',
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { ErrorResponse } from '../model'
// @ts-ignore
import { GetClientForAdminResponse } from '../model'
// @ts-ignore
import { GetClientRequest } from '../model'
// @ts-ignore
import { GetClientUsersResponse } from '../model'
// @ts-ignore
import { GetGuestPaymentTokushohouRequestForClient } from '../model'
// @ts-ignore
import { GuestPaymentTokushohou } from '../model'
// @ts-ignore
import { UpdateClientForAdminRequest } from '../model'
// @ts-ignore
import { UpdateClientUserActivityRequest } from '../model'
/**
 * ClientAdminControllerApi - axios parameter creator
 * @export
 */
export const ClientAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {UpdateClientUserActivityRequest} updateClientUserActivityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateClientUser: async (
      updateClientUserActivityRequest: UpdateClientUserActivityRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateClientUserActivityRequest' is not null or undefined
      assertParamExists(
        'activateClientUser',
        'updateClientUserActivityRequest',
        updateClientUserActivityRequest,
      )
      const localVarPath = `/cl/api/admin/activateClientUser`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateClientUserActivityRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateClientUserActivityRequest} updateClientUserActivityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateClientUser: async (
      updateClientUserActivityRequest: UpdateClientUserActivityRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateClientUserActivityRequest' is not null or undefined
      assertParamExists(
        'deactivateClientUser',
        'updateClientUserActivityRequest',
        updateClientUserActivityRequest,
      )
      const localVarPath = `/cl/api/admin/deactivateClientUser`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateClientUserActivityRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetClientRequest} getClientRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClient: async (
      getClientRequest: GetClientRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getClientRequest' is not null or undefined
      assertParamExists('getClient', 'getClientRequest', getClientRequest)
      const localVarPath = `/cl/api/admin/getClient`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getClientRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cl/api/admin/getClientUsers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetGuestPaymentTokushohouRequestForClient} getGuestPaymentTokushohouRequestForClient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGuestPaymentTokushohouForClient: async (
      getGuestPaymentTokushohouRequestForClient: GetGuestPaymentTokushohouRequestForClient,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getGuestPaymentTokushohouRequestForClient' is not null or undefined
      assertParamExists(
        'getGuestPaymentTokushohouForClient',
        'getGuestPaymentTokushohouRequestForClient',
        getGuestPaymentTokushohouRequestForClient,
      )
      const localVarPath = `/cl/api/admin/getGuestPaymentTokushohouForClient`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getGuestPaymentTokushohouRequestForClient,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateClientForAdminRequest} updateClientForAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClient: async (
      updateClientForAdminRequest: UpdateClientForAdminRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateClientForAdminRequest' is not null or undefined
      assertParamExists('updateClient', 'updateClientForAdminRequest', updateClientForAdminRequest)
      const localVarPath = `/cl/api/admin/updateClient`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateClientForAdminRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ClientAdminControllerApi - functional programming interface
 * @export
 */
export const ClientAdminControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ClientAdminControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {UpdateClientUserActivityRequest} updateClientUserActivityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async activateClientUser(
      updateClientUserActivityRequest: UpdateClientUserActivityRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.activateClientUser(
        updateClientUserActivityRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateClientUserActivityRequest} updateClientUserActivityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deactivateClientUser(
      updateClientUserActivityRequest: UpdateClientUserActivityRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateClientUser(
        updateClientUserActivityRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetClientRequest} getClientRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClient(
      getClientRequest: GetClientRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientForAdminResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClient(getClientRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientUsers(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientUsersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClientUsers(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetGuestPaymentTokushohouRequestForClient} getGuestPaymentTokushohouRequestForClient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGuestPaymentTokushohouForClient(
      getGuestPaymentTokushohouRequestForClient: GetGuestPaymentTokushohouRequestForClient,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuestPaymentTokushohou>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGuestPaymentTokushohouForClient(
        getGuestPaymentTokushohouRequestForClient,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateClientForAdminRequest} updateClientForAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateClient(
      updateClientForAdminRequest: UpdateClientForAdminRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateClient(
        updateClientForAdminRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ClientAdminControllerApi - factory interface
 * @export
 */
export const ClientAdminControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ClientAdminControllerApiFp(configuration)
  return {
    /**
     *
     * @param {UpdateClientUserActivityRequest} updateClientUserActivityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateClientUser(
      updateClientUserActivityRequest: UpdateClientUserActivityRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .activateClientUser(updateClientUserActivityRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateClientUserActivityRequest} updateClientUserActivityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateClientUser(
      updateClientUserActivityRequest: UpdateClientUserActivityRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .deactivateClientUser(updateClientUserActivityRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetClientRequest} getClientRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClient(
      getClientRequest: GetClientRequest,
      options?: any,
    ): AxiosPromise<GetClientForAdminResponse> {
      return localVarFp
        .getClient(getClientRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientUsers(options?: any): AxiosPromise<GetClientUsersResponse> {
      return localVarFp.getClientUsers(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetGuestPaymentTokushohouRequestForClient} getGuestPaymentTokushohouRequestForClient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGuestPaymentTokushohouForClient(
      getGuestPaymentTokushohouRequestForClient: GetGuestPaymentTokushohouRequestForClient,
      options?: any,
    ): AxiosPromise<GuestPaymentTokushohou> {
      return localVarFp
        .getGuestPaymentTokushohouForClient(getGuestPaymentTokushohouRequestForClient, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateClientForAdminRequest} updateClientForAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClient(
      updateClientForAdminRequest: UpdateClientForAdminRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .updateClient(updateClientForAdminRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ClientAdminControllerApi - object-oriented interface
 * @export
 * @class ClientAdminControllerApi
 * @extends {BaseAPI}
 */
export class ClientAdminControllerApi extends BaseAPI {
  /**
   *
   * @param {UpdateClientUserActivityRequest} updateClientUserActivityRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientAdminControllerApi
   */
  public activateClientUser(
    updateClientUserActivityRequest: UpdateClientUserActivityRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientAdminControllerApiFp(this.configuration)
      .activateClientUser(updateClientUserActivityRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateClientUserActivityRequest} updateClientUserActivityRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientAdminControllerApi
   */
  public deactivateClientUser(
    updateClientUserActivityRequest: UpdateClientUserActivityRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientAdminControllerApiFp(this.configuration)
      .deactivateClientUser(updateClientUserActivityRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetClientRequest} getClientRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientAdminControllerApi
   */
  public getClient(getClientRequest: GetClientRequest, options?: AxiosRequestConfig) {
    return ClientAdminControllerApiFp(this.configuration)
      .getClient(getClientRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientAdminControllerApi
   */
  public getClientUsers(options?: AxiosRequestConfig) {
    return ClientAdminControllerApiFp(this.configuration)
      .getClientUsers(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetGuestPaymentTokushohouRequestForClient} getGuestPaymentTokushohouRequestForClient
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientAdminControllerApi
   */
  public getGuestPaymentTokushohouForClient(
    getGuestPaymentTokushohouRequestForClient: GetGuestPaymentTokushohouRequestForClient,
    options?: AxiosRequestConfig,
  ) {
    return ClientAdminControllerApiFp(this.configuration)
      .getGuestPaymentTokushohouForClient(getGuestPaymentTokushohouRequestForClient, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateClientForAdminRequest} updateClientForAdminRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientAdminControllerApi
   */
  public updateClient(
    updateClientForAdminRequest: UpdateClientForAdminRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientAdminControllerApiFp(this.configuration)
      .updateClient(updateClientForAdminRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

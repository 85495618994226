export const ENTRY_PATH = {
  LOGIN: '/entry',
  HOUJIN_INFO: '/entry/houjinInfo',
  HOUJIN_INFO_CONFIRM: '/entry/houjinInfoConfirm',
  SELECT_CLIENT: '/entry/selectClient',
  CLIENT_INFO: '/entry/clientInfo',
  NOTIFICATION_INFO: '/entry/clientInfoNotification',
  CLIENT_INFO_CONFIRM: '/entry/clientInfoConfirm',
  COMPLETE: '/entry/complete',
} as const

export type EntryPath = typeof ENTRY_PATH[keyof typeof ENTRY_PATH]

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CustomResponseValue } from './custom-response-value'

/**
 *
 * @export
 * @interface GuestForClientCSV
 */
export interface GuestForClientCSV {
  /**
   *
   * @type {string}
   * @memberof GuestForClientCSV
   */
  respondedAt: string
  /**
   *
   * @type {string}
   * @memberof GuestForClientCSV
   */
  guestId: string
  /**
   *
   * @type {string}
   * @memberof GuestForClientCSV
   */
  invitationName: string
  /**
   *
   * @type {string}
   * @memberof GuestForClientCSV
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof GuestForClientCSV
   */
  kana: string
  /**
   *
   * @type {string}
   * @memberof GuestForClientCSV
   */
  companionInfo?: string
  /**
   *
   * @type {boolean}
   * @memberof GuestForClientCSV
   */
  attendance: boolean
  /**
   *
   * @type {string}
   * @memberof GuestForClientCSV
   */
  whichGuest?: GuestForClientCSVWhichGuest
  /**
   *
   * @type {string}
   * @memberof GuestForClientCSV
   */
  sex?: GuestForClientCSVSex
  /**
   *
   * @type {string}
   * @memberof GuestForClientCSV
   */
  postCode?: string
  /**
   *
   * @type {string}
   * @memberof GuestForClientCSV
   */
  address?: string
  /**
   *
   * @type {string}
   * @memberof GuestForClientCSV
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof GuestForClientCSV
   */
  tel?: string
  /**
   *
   * @type {Array<CustomResponseValue>}
   * @memberof GuestForClientCSV
   */
  customResponseValues: Array<CustomResponseValue>
  /**
   *
   * @type {string}
   * @memberof GuestForClientCSV
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof GuestForClientCSV
   */
  paymentReceivedAt?: string
  /**
   *
   * @type {string}
   * @memberof GuestForClientCSV
   */
  paymentTrackingId?: string
  /**
   *
   * @type {number}
   * @memberof GuestForClientCSV
   */
  paymentAmount?: number
  /**
   *
   * @type {string}
   * @memberof GuestForClientCSV
   */
  paymentStatus?: string
  /**
   *
   * @type {string}
   * @memberof GuestForClientCSV
   */
  cancelRequestedAt?: string
  /**
   *
   * @type {boolean}
   * @memberof GuestForClientCSV
   */
  isCompanion: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum GuestForClientCSVWhichGuest {
  shinroGuest = 'SHINRO_GUEST',
  shinpuGuest = 'SHINPU_GUEST',
}
/**
 * @export
 * @enum {string}
 */
export enum GuestForClientCSVSex {
  male = 'MALE',
  female = 'FEMALE',
  other = 'OTHER',
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface CustomerInvitation
 */
export interface CustomerInvitation {
  /**
   *
   * @type {string}
   * @memberof CustomerInvitation
   */
  invitationUUID: string
  /**
   *
   * @type {string}
   * @memberof CustomerInvitation
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CustomerInvitation
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof CustomerInvitation
   */
  status: CustomerInvitationStatus
  /**
   *
   * @type {string}
   * @memberof CustomerInvitation
   */
  guestPaymentSettingStatus: CustomerInvitationGuestPaymentSettingStatus
  /**
   *
   * @type {string}
   * @memberof CustomerInvitation
   */
  invitationType?: CustomerInvitationInvitationType
  /**
   *
   * @type {string}
   * @memberof CustomerInvitation
   */
  clientDetail?: string
  /**
   *
   * @type {string}
   * @memberof CustomerInvitation
   */
  holdDate?: string
  /**
   *
   * @type {string}
   * @memberof CustomerInvitation
   */
  acceptStartAt?: string
  /**
   *
   * @type {string}
   * @memberof CustomerInvitation
   */
  weddingStartAt?: string
  /**
   *
   * @type {string}
   * @memberof CustomerInvitation
   */
  receptionStartAt?: string
  /**
   *
   * @type {string}
   * @memberof CustomerInvitation
   */
  customText?: string
  /**
   *
   * @type {string}
   * @memberof CustomerInvitation
   */
  responseDeadline?: string
  /**
   *
   * @type {string}
   * @memberof CustomerInvitation
   */
  paymentDeadline?: string
  /**
   *
   * @type {string}
   * @memberof CustomerInvitation
   */
  password?: string
  /**
   *
   * @type {boolean}
   * @memberof CustomerInvitation
   */
  isPublic?: boolean
  /**
   *
   * @type {string}
   * @memberof CustomerInvitation
   */
  publishedAt?: string
  /**
   *
   * @type {string}
   * @memberof CustomerInvitation
   */
  invitationURL?: string
  /**
   *
   * @type {boolean}
   * @memberof CustomerInvitation
   */
  canShowPreview: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum CustomerInvitationStatus {
  draft = 'DRAFT',
  beforeDeadline = 'BEFORE_DEADLINE',
  afterDeadline = 'AFTER_DEADLINE',
  endOfPublication = 'END_OF_PUBLICATION',
  suspended = 'SUSPENDED',
}
/**
 * @export
 * @enum {string}
 */
export enum CustomerInvitationGuestPaymentSettingStatus {
  noSettings = 'NO_SETTINGS',
  notUsing = 'NOT_USING',
  using = 'USING',
}
/**
 * @export
 * @enum {string}
 */
export enum CustomerInvitationInvitationType {
  weddingReception = 'WEDDING_RECEPTION',
  wedding = 'WEDDING',
  reception = 'RECEPTION',
  casual = 'CASUAL',
  weddingPartyMeal = 'WEDDING_PARTY_MEAL',
  mealParty = 'MEAL_PARTY',
  custom = 'CUSTOM',
}

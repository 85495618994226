/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GetCategoryGuestsResponseGuestCount } from './get-category-guests-response-guest-count'

/**
 *
 * @export
 * @interface GetCategoryGuestsResponseCategoryGuestCount
 */
export interface GetCategoryGuestsResponseCategoryGuestCount {
  /**
   *
   * @type {string}
   * @memberof GetCategoryGuestsResponseCategoryGuestCount
   */
  category: GetCategoryGuestsResponseCategoryGuestCountCategory
  /**
   *
   * @type {GetCategoryGuestsResponseGuestCount}
   * @memberof GetCategoryGuestsResponseCategoryGuestCount
   */
  guestCount: GetCategoryGuestsResponseGuestCount
}

/**
 * @export
 * @enum {string}
 */
export enum GetCategoryGuestsResponseCategoryGuestCountCategory {
  family = 'FAMILY',
  school = 'SCHOOL',
  workplace = 'WORKPLACE',
  others = 'OTHERS',
}

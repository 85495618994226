import { ErrorHead } from '@/components/common/commonHead'
import Footer from '@/components/guest/invitations/footer'
import { errorPageStyles } from '@/styles/guest/errorPageStyle'

export default function GuestNetworkError() {
  const handleReloadClick = () => {
    window.location.reload()
  }
  return (
    <div css={errorPageStyles.root}>
      <ErrorHead />
      <div css={errorPageStyles.main}>
        <div css={errorPageStyles.container}>
          <h1 css={errorPageStyles.title}>データの取得に失敗しました</h1>
          <p css={errorPageStyles.text}>
            通信環境の良い場所で
            <br />
            再読み込みしてください。
          </p>
          <button css={errorPageStyles.button} type="button" onClick={handleReloadClick}>
            再読み込み
          </button>
        </div>
      </div>
      <div css={errorPageStyles.footer}>
        <Footer pattern="white" />
      </div>
    </div>
  )
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CustomerPaymentSummary } from './customer-payment-summary'
import { CustomerResponseSummary } from './customer-response-summary'

/**
 *
 * @export
 * @interface CustomerParty
 */
export interface CustomerParty {
  /**
   *
   * @type {string}
   * @memberof CustomerParty
   */
  clientCd: string
  /**
   *
   * @type {string}
   * @memberof CustomerParty
   */
  holdDate: string
  /**
   *
   * @type {number}
   * @memberof CustomerParty
   */
  numberOfGuests?: number
  /**
   *
   * @type {number}
   * @memberof CustomerParty
   */
  totalPaymentAmount?: number
  /**
   *
   * @type {string}
   * @memberof CustomerParty
   */
  responseDeadline?: string
  /**
   *
   * @type {string}
   * @memberof CustomerParty
   */
  paymentDeadlineDate?: string
  /**
   *
   * @type {CustomerResponseSummary}
   * @memberof CustomerParty
   */
  responseSummary?: CustomerResponseSummary
  /**
   *
   * @type {CustomerPaymentSummary}
   * @memberof CustomerParty
   */
  paymentSummary?: CustomerPaymentSummary
  /**
   *
   * @type {string}
   * @memberof CustomerParty
   */
  paymentExpectedDate?: string
  /**
   *
   * @type {string}
   * @memberof CustomerParty
   */
  paymentToClientStatus: CustomerPartyPaymentToClientStatus
  /**
   *
   * @type {boolean}
   * @memberof CustomerParty
   */
  hasGuestPaymentForSuspendedInvitation: boolean
  /**
   *
   * @type {boolean}
   * @memberof CustomerParty
   */
  hasSuspendedInvitation: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum CustomerPartyPaymentToClientStatus {
  notAcceptingGuestPayment = 'NOT_ACCEPTING_GUEST_PAYMENT',
  acceptingGuestPayment = 'ACCEPTING_GUEST_PAYMENT',
  stoppedGuestPayment = 'STOPPED_GUEST_PAYMENT',
  beforePaymentToClientRequest = 'BEFORE_PAYMENT_TO_CLIENT_REQUEST',
  afterPaymentToClientRequest = 'AFTER_PAYMENT_TO_CLIENT_REQUEST',
  failedPaymentToClient = 'FAILED_PAYMENT_TO_CLIENT',
}

import {
  CoupleLogCommonProps,
  loggingCoupleCustomEvent,
} from '@/components/common/googleTagManager'
import { SquareMSecondaryButton } from '@/components/cs/button'
import { DialogContainer } from '@/components/cs/modalContainer'
import { useConfig } from '@/context/config'
import { cs_colors } from '@/styles/cs/colors'
import { breakpoints } from '@/styles/cs/styleConstants'
import { CsTextStyles } from '@/styles/cs/textStyles'
import { CS_EXTERNAL_WEB_URL } from '@/utils/common/externalWebURL'
import { css } from '@emotion/react'
import DuplicationIcon from '@images/cs/icons/16px/duplication.svg'
import { Divider } from '@mui/material'

type ButtonProps = JSX.IntrinsicElements['button'] & {
  icon?: JSX.Element
  label: string
}

function MenuItemButton({ icon, label, ...props }: ButtonProps) {
  const menuItemButtonStyles = {
    itemButton: css`
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 56px;
      padding-right: 0;
      padding-left: 0;
      cursor: pointer;
      background: none;
      /* Android Chromeのボタンタッチエフェクトを無効化 */
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    `,
    labelArea: [
      CsTextStyles.body.regular.s14px.h100(cs_colors.textAndIcon.black_text),
      css`
        cursor: inherit;
      `,
    ],
    menuItemIcon: css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
    `,
  }
  return (
    <button {...props} css={[menuItemButtonStyles.itemButton]}>
      <label css={menuItemButtonStyles.labelArea}>{label}</label>
      {icon && <div css={menuItemButtonStyles.menuItemIcon}>{icon}</div>}
    </button>
  )
}

type menuModalParam = {
  menuModalShow: boolean
  hideMenuModal: () => void
  logContents: CoupleLogCommonProps
}

export const HeaderHelpModal = ({ menuModalShow, hideMenuModal, logContents }: menuModalParam) => {
  const { config } = useConfig()

  const loggingUserGuide = () => {
    loggingCoupleCustomEvent({
      event: 'cs_header_guide_tap',
      cs_userid: logContents.cs_userid,
      party_id: logContents.party_id,
    })
    window.open(CS_EXTERNAL_WEB_URL(config).USER_GUIDE, '_blank')
  }

  const loggingFaq = () => {
    loggingCoupleCustomEvent({
      event: 'cs_header_faq_tap',
      cs_userid: logContents.cs_userid,
      party_id: logContents.party_id,
    })
    window.open(CS_EXTERNAL_WEB_URL(config).FAQ, '_blank')
  }

  return (
    <>
      <DialogContainer
        open={menuModalShow}
        onBackdropClick={hideMenuModal}
        PaperProps={{
          style: {
            borderRadius: 16,
            maxWidth: '375px',
            minWidth: '311px',
          },
        }}
        fullWidth
      >
        <div css={styles.modal} onClick={(e) => e.stopPropagation()}>
          <MenuItemButton
            label="使い方ガイド"
            icon={<DuplicationIcon css={styles.menuItemButtonIcon} />}
            onClick={loggingUserGuide}
          />
          <Divider css={styles.divider} />
          <MenuItemButton
            label="よくあるご質問・お問い合わせ"
            icon={<DuplicationIcon css={styles.menuItemButtonIcon} />}
            onClick={loggingFaq}
          />
          <SquareMSecondaryButton
            onClick={hideMenuModal}
            label="キャンセル"
            css={styles.cancelButton}
          />
        </div>
      </DialogContainer>
    </>
  )
}

const styles = {
  modal: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px;
    background: linear-gradient(
        81.52deg,
        rgba(234, 96, 119, 0.15) 12.09%,
        rgba(246, 122, 127, 0.15) 88.52%
      ),
      #ffffff;
    @media (max-width: ${breakpoints.sp}px) {
      padding: 32px 32px;
    }
  `,
  divider: css`
    width: 100%;
    margin: 8px 0;
    border-color: ${cs_colors.border.lightGray_line};
    border-bottom-width: 1px;
  `,
  menuItemButtonIcon: css`
    path {
      fill: ${cs_colors.common.zexyPink};
    }
  `,
  cancelButton: css`
    width: 100%;
    margin: 16px 0 0;
    text-decoration-line: none;
    -webkit-tap-highlight-color: transparent;
  `,
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CustomResponseForClient } from './custom-response-for-client'
import { CustomerGuestCompanion } from './customer-guest-companion'
import { CustomerGuestPaymentInfo } from './customer-guest-payment-info'

/**
 *
 * @export
 * @interface CustomerGuestHistory
 */
export interface CustomerGuestHistory {
  /**
   *
   * @type {string}
   * @memberof CustomerGuestHistory
   */
  invitationId: string
  /**
   *
   * @type {string}
   * @memberof CustomerGuestHistory
   */
  invitationName: string
  /**
   *
   * @type {string}
   * @memberof CustomerGuestHistory
   */
  responseId: string
  /**
   *
   * @type {string}
   * @memberof CustomerGuestHistory
   */
  guestId: string
  /**
   *
   * @type {boolean}
   * @memberof CustomerGuestHistory
   */
  attendance: boolean
  /**
   *
   * @type {string}
   * @memberof CustomerGuestHistory
   */
  lastName: string
  /**
   *
   * @type {string}
   * @memberof CustomerGuestHistory
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof CustomerGuestHistory
   */
  lastNameKana: string
  /**
   *
   * @type {string}
   * @memberof CustomerGuestHistory
   */
  firstNameKana: string
  /**
   *
   * @type {string}
   * @memberof CustomerGuestHistory
   */
  whichGuest?: CustomerGuestHistoryWhichGuest
  /**
   *
   * @type {string}
   * @memberof CustomerGuestHistory
   */
  sex?: CustomerGuestHistorySex
  /**
   *
   * @type {Array<CustomResponseForClient>}
   * @memberof CustomerGuestHistory
   */
  customResponses: Array<CustomResponseForClient>
  /**
   *
   * @type {string}
   * @memberof CustomerGuestHistory
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof CustomerGuestHistory
   */
  tel?: string
  /**
   *
   * @type {string}
   * @memberof CustomerGuestHistory
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof CustomerGuestHistory
   */
  respondedAt: string
  /**
   *
   * @type {boolean}
   * @memberof CustomerGuestHistory
   */
  isUsingPayment: boolean
  /**
   *
   * @type {CustomerGuestPaymentInfo}
   * @memberof CustomerGuestHistory
   */
  paymentInfo?: CustomerGuestPaymentInfo
  /**
   *
   * @type {Array<CustomerGuestCompanion>}
   * @memberof CustomerGuestHistory
   */
  guestCompanions: Array<CustomerGuestCompanion>
}

/**
 * @export
 * @enum {string}
 */
export enum CustomerGuestHistoryWhichGuest {
  shinroGuest = 'SHINRO_GUEST',
  shinpuGuest = 'SHINPU_GUEST',
}
/**
 * @export
 * @enum {string}
 */
export enum CustomerGuestHistorySex {
  male = 'MALE',
  female = 'FEMALE',
  other = 'OTHER',
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CustomResponseItemOption } from './custom-response-item-option'

/**
 *
 * @export
 * @interface CustomResponseItemResponse
 */
export interface CustomResponseItemResponse {
  /**
   *
   * @type {string}
   * @memberof CustomResponseItemResponse
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof CustomResponseItemResponse
   */
  type: CustomResponseItemResponseType
  /**
   *
   * @type {string}
   * @memberof CustomResponseItemResponse
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CustomResponseItemResponse
   */
  supplementaryText?: string
  /**
   *
   * @type {Array<CustomResponseItemOption>}
   * @memberof CustomResponseItemResponse
   */
  options?: Array<CustomResponseItemOption>
}

/**
 * @export
 * @enum {string}
 */
export enum CustomResponseItemResponseType {
  freeText = 'FreeText',
  singleChoice = 'SingleChoice',
  multipleChoice = 'MultipleChoice',
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CoupleAnalyticsDataResponse } from './couple-analytics-data-response'

/**
 *
 * @export
 * @interface GetInvitationSendSettingResponse
 */
export interface GetInvitationSendSettingResponse {
  /**
   *
   * @type {string}
   * @memberof GetInvitationSendSettingResponse
   */
  partyHoldDate: string
  /**
   *
   * @type {string}
   * @memberof GetInvitationSendSettingResponse
   */
  invitationName: string
  /**
   *
   * @type {string}
   * @memberof GetInvitationSendSettingResponse
   */
  designTheme: GetInvitationSendSettingResponseDesignTheme
  /**
   *
   * @type {string}
   * @memberof GetInvitationSendSettingResponse
   */
  publishStatus: GetInvitationSendSettingResponsePublishStatus
  /**
   *
   * @type {boolean}
   * @memberof GetInvitationSendSettingResponse
   */
  usePassword: boolean
  /**
   *
   * @type {string}
   * @memberof GetInvitationSendSettingResponse
   */
  password?: string
  /**
   *
   * @type {string}
   * @memberof GetInvitationSendSettingResponse
   */
  invitationURL?: string
  /**
   *
   * @type {boolean}
   * @memberof GetInvitationSendSettingResponse
   */
  useOnlinePayment: boolean
  /**
   *
   * @type {boolean}
   * @memberof GetInvitationSendSettingResponse
   */
  isAfterResponseDeadline: boolean
  /**
   *
   * @type {boolean}
   * @memberof GetInvitationSendSettingResponse
   */
  isAfterPaymentDeadline: boolean
  /**
   *
   * @type {string}
   * @memberof GetInvitationSendSettingResponse
   */
  paymentStartDate?: string
  /**
   *
   * @type {string}
   * @memberof GetInvitationSendSettingResponse
   */
  paymentDueDate?: string
  /**
   *
   * @type {CoupleAnalyticsDataResponse}
   * @memberof GetInvitationSendSettingResponse
   */
  coupleAnalyticsDataResponse: CoupleAnalyticsDataResponse
  /**
   *
   * @type {string}
   * @memberof GetInvitationSendSettingResponse
   */
  responseDeadline: string
  /**
   *
   * @type {boolean}
   * @memberof GetInvitationSendSettingResponse
   */
  isNeedToUpdateCustomResponseItems: boolean
  /**
   *
   * @type {string}
   * @memberof GetInvitationSendSettingResponse
   */
  partyPaymentType: GetInvitationSendSettingResponsePartyPaymentType
}

/**
 * @export
 * @enum {string}
 */
export enum GetInvitationSendSettingResponseDesignTheme {
  formalAGold = 'FORMAL_A_GOLD',
  formalANavy = 'FORMAL_A_NAVY',
  formalARed = 'FORMAL_A_RED',
  formalAJapanwhite = 'FORMAL_A_JAPANWHITE',
  formalAGoldA = 'FORMAL_A_GOLD_a',
  formalANavyA = 'FORMAL_A_NAVY_a',
  simpleAPink = 'SIMPLE_A_PINK',
  simpleABlue = 'SIMPLE_A_BLUE',
  simpleAGray = 'SIMPLE_A_GRAY',
  naturalBGreen = 'NATURAL_B_GREEN',
  naturalBGreenA = 'NATURAL_B_GREEN_a',
  naturalBPink = 'NATURAL_B_PINK',
  naturalBPinkA = 'NATURAL_B_PINK_a',
  naturalBBrown = 'NATURAL_B_BROWN',
  naturalBBrownA = 'NATURAL_B_BROWN_a',
  eSimplenavygold = 'E_SIMPLENAVYGOLD',
  eChicblack = 'E_CHICBLACK',
  eFreshblue = 'E_FRESHBLUE',
  fSimplewhite = 'F_SIMPLEWHITE',
  fFemininebeige = 'F_FEMININEBEIGE',
  fClassicGreen = 'F_CLASSIC_GREEN',
  gArtisticbeige = 'G_ARTISTICBEIGE',
  gLadyblack = 'G_LADYBLACK',
  gChicGray = 'G_CHIC_GRAY',
  hGorgeousgold = 'H_GORGEOUSGOLD',
  hGorgeousgoldA = 'H_GORGEOUSGOLD_a',
  iFlowerwhite = 'I_FLOWERWHITE',
  iCoolmonotone = 'I_COOLMONOTONE',
  jFlowerBlue = 'J_FLOWER_BLUE',
  jFlowerOrange = 'J_FLOWER_ORANGE',
  kFreshgreen = 'K_FRESHGREEN',
  mMinimalYellow = 'M_MINIMAL_YELLOW',
  mMinimalRed = 'M_MINIMAL_RED',
  nJapanDaidai = 'N_JAPAN_DAIDAI',
  nJapanKurenai = 'N_JAPAN_KURENAI',
  nJapanShirotae = 'N_JAPAN_SHIROTAE',
  lClassicNavy = 'L_CLASSIC_NAVY',
  lClassicBold = 'L_CLASSIC_BOLD',
  oClassicLightgreen = 'O_CLASSIC_LIGHTGREEN',
  oClassicBlue = 'O_CLASSIC_BLUE',
  pSimpleWhite = 'P_SIMPLE_WHITE',
  qResortBlue = 'Q_RESORT_BLUE',
  rFeminineSoftpink = 'R_FEMININE_SOFTPINK',
  sCasualBrown = 'S_CASUAL_BROWN',
  sCasualNavy = 'S_CASUAL_NAVY',
  tZexyPink = 'T_ZEXY_PINK',
}
/**
 * @export
 * @enum {string}
 */
export enum GetInvitationSendSettingResponsePublishStatus {
  draft = 'DRAFT',
  publishedPublic = 'PUBLISHED_PUBLIC',
  publishedNotPublic = 'PUBLISHED_NOT_PUBLIC',
}
/**
 * @export
 * @enum {string}
 */
export enum GetInvitationSendSettingResponsePartyPaymentType {
  goshugi = 'GOSHUGI',
  kaihi = 'KAIHI',
}

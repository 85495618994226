/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { CreatePartyRequest } from '../model'
// @ts-ignore
import { CreatePartyResponse } from '../model'
// @ts-ignore
import { ErrorResponse } from '../model'
// @ts-ignore
import { GetAMSSharedInfoResponse } from '../model'
// @ts-ignore
import { GetGuestsForCoupleCSVResponse } from '../model'
// @ts-ignore
import { GetGuestsForCoupleWPSCSVResponse } from '../model'
// @ts-ignore
import { GetPartyDeadlineSettingResponse } from '../model'
// @ts-ignore
import { GetPartyResponse } from '../model'
// @ts-ignore
import { GetPartyToUpdateHoldDateRequest } from '../model'
// @ts-ignore
import { GetPartyToUpdateHoldDateResponse } from '../model'
// @ts-ignore
import { GetPreSharedImageRequest } from '../model'
// @ts-ignore
import { GetPreSharedImageResponse } from '../model'
// @ts-ignore
import { PartyGuestResponsesRequest } from '../model'
// @ts-ignore
import { PartyGuestResponsesResponse } from '../model'
// @ts-ignore
import { UpdateGuestAttendanceRequest } from '../model'
// @ts-ignore
import { UpdateGuestAttendanceResponse } from '../model'
// @ts-ignore
import { UpdateGuestCompanionAttendanceRequest } from '../model'
// @ts-ignore
import { UpdateGuestCompanionAttendanceResponse } from '../model'
// @ts-ignore
import { UpdatePartyHoldDateRequest } from '../model'
// @ts-ignore
import { UpdatePartyHoldDateResponse } from '../model'
// @ts-ignore
import { UpdateResponseDeadlineRequest } from '../model'
// @ts-ignore
import { UpdateResponseDeadlineResponse } from '../model'
/**
 * CouplePartyControllerApi - axios parameter creator
 * @export
 */
export const CouplePartyControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreatePartyRequest} createPartyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createParty: async (
      createPartyRequest: CreatePartyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPartyRequest' is not null or undefined
      assertParamExists('createParty', 'createPartyRequest', createPartyRequest)
      const localVarPath = `/cs/api/createParty`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPartyRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAMSSharedInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cs/api/getAMSSharedInfo`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParty: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cs/api/getParty`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartyDeadlineSetting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cs/api/deadline`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {PartyGuestResponsesRequest} partyGuestResponsesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartyGuestResponses: async (
      partyGuestResponsesRequest: PartyGuestResponsesRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'partyGuestResponsesRequest' is not null or undefined
      assertParamExists(
        'getPartyGuestResponses',
        'partyGuestResponsesRequest',
        partyGuestResponsesRequest,
      )
      const localVarPath = `/cs/api/getPartyGuestResponses`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        partyGuestResponsesRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartyGuestResponsesForCSV: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cs/api/getPartyGuestResponsesForCSV`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartyGuestResponsesForWPSCSV: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cs/api/getPartyGuestResponsesForWPSCSV`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetPartyToUpdateHoldDateRequest} getPartyToUpdateHoldDateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartyToUpdateHoldDate: async (
      getPartyToUpdateHoldDateRequest: GetPartyToUpdateHoldDateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getPartyToUpdateHoldDateRequest' is not null or undefined
      assertParamExists(
        'getPartyToUpdateHoldDate',
        'getPartyToUpdateHoldDateRequest',
        getPartyToUpdateHoldDateRequest,
      )
      const localVarPath = `/cs/api/getPartyToUpdateHoldDate`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getPartyToUpdateHoldDateRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetPreSharedImageRequest} getPreSharedImageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPreSharedImages: async (
      getPreSharedImageRequest: GetPreSharedImageRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getPreSharedImageRequest' is not null or undefined
      assertParamExists('getPreSharedImages', 'getPreSharedImageRequest', getPreSharedImageRequest)
      const localVarPath = `/cs/api/getPreSharedImages`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getPreSharedImageRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateGuestAttendanceRequest} updateGuestAttendanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGuestAttendance: async (
      updateGuestAttendanceRequest: UpdateGuestAttendanceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateGuestAttendanceRequest' is not null or undefined
      assertParamExists(
        'updateGuestAttendance',
        'updateGuestAttendanceRequest',
        updateGuestAttendanceRequest,
      )
      const localVarPath = `/cs/api/updateGuestAttendance`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateGuestAttendanceRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateGuestCompanionAttendanceRequest} updateGuestCompanionAttendanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGuestCompanionAttendance: async (
      updateGuestCompanionAttendanceRequest: UpdateGuestCompanionAttendanceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateGuestCompanionAttendanceRequest' is not null or undefined
      assertParamExists(
        'updateGuestCompanionAttendance',
        'updateGuestCompanionAttendanceRequest',
        updateGuestCompanionAttendanceRequest,
      )
      const localVarPath = `/cs/api/updateGuestCompanionAttendance`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateGuestCompanionAttendanceRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdatePartyHoldDateRequest} updatePartyHoldDateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePartyHoldDate: async (
      updatePartyHoldDateRequest: UpdatePartyHoldDateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updatePartyHoldDateRequest' is not null or undefined
      assertParamExists(
        'updatePartyHoldDate',
        'updatePartyHoldDateRequest',
        updatePartyHoldDateRequest,
      )
      const localVarPath = `/cs/api/updatePartyHoldDate`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePartyHoldDateRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateResponseDeadlineRequest} updateResponseDeadlineRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateResponseDeadline: async (
      updateResponseDeadlineRequest: UpdateResponseDeadlineRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateResponseDeadlineRequest' is not null or undefined
      assertParamExists(
        'updateResponseDeadline',
        'updateResponseDeadlineRequest',
        updateResponseDeadlineRequest,
      )
      const localVarPath = `/cs/api/updateResponseDeadline`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateResponseDeadlineRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CouplePartyControllerApi - functional programming interface
 * @export
 */
export const CouplePartyControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CouplePartyControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {CreatePartyRequest} createPartyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createParty(
      createPartyRequest: CreatePartyRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePartyResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createParty(
        createPartyRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAMSSharedInfo(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAMSSharedInfoResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAMSSharedInfo(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getParty(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPartyResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getParty(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPartyDeadlineSetting(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPartyDeadlineSettingResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPartyDeadlineSetting(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {PartyGuestResponsesRequest} partyGuestResponsesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPartyGuestResponses(
      partyGuestResponsesRequest: PartyGuestResponsesRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartyGuestResponsesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPartyGuestResponses(
        partyGuestResponsesRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPartyGuestResponsesForCSV(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestsForCoupleCSVResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPartyGuestResponsesForCSV(
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPartyGuestResponsesForWPSCSV(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestsForCoupleWPSCSVResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPartyGuestResponsesForWPSCSV(
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetPartyToUpdateHoldDateRequest} getPartyToUpdateHoldDateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPartyToUpdateHoldDate(
      getPartyToUpdateHoldDateRequest: GetPartyToUpdateHoldDateRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPartyToUpdateHoldDateResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPartyToUpdateHoldDate(
        getPartyToUpdateHoldDateRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetPreSharedImageRequest} getPreSharedImageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPreSharedImages(
      getPreSharedImageRequest: GetPreSharedImageRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPreSharedImageResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPreSharedImages(
        getPreSharedImageRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateGuestAttendanceRequest} updateGuestAttendanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateGuestAttendance(
      updateGuestAttendanceRequest: UpdateGuestAttendanceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateGuestAttendanceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateGuestAttendance(
        updateGuestAttendanceRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateGuestCompanionAttendanceRequest} updateGuestCompanionAttendanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateGuestCompanionAttendance(
      updateGuestCompanionAttendanceRequest: UpdateGuestCompanionAttendanceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateGuestCompanionAttendanceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateGuestCompanionAttendance(
        updateGuestCompanionAttendanceRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdatePartyHoldDateRequest} updatePartyHoldDateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePartyHoldDate(
      updatePartyHoldDateRequest: UpdatePartyHoldDateRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePartyHoldDateResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartyHoldDate(
        updatePartyHoldDateRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateResponseDeadlineRequest} updateResponseDeadlineRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateResponseDeadline(
      updateResponseDeadlineRequest: UpdateResponseDeadlineRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateResponseDeadlineResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateResponseDeadline(
        updateResponseDeadlineRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CouplePartyControllerApi - factory interface
 * @export
 */
export const CouplePartyControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CouplePartyControllerApiFp(configuration)
  return {
    /**
     *
     * @param {CreatePartyRequest} createPartyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createParty(
      createPartyRequest: CreatePartyRequest,
      options?: any,
    ): AxiosPromise<CreatePartyResponse> {
      return localVarFp
        .createParty(createPartyRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAMSSharedInfo(options?: any): AxiosPromise<GetAMSSharedInfoResponse> {
      return localVarFp.getAMSSharedInfo(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParty(options?: any): AxiosPromise<GetPartyResponse> {
      return localVarFp.getParty(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartyDeadlineSetting(options?: any): AxiosPromise<GetPartyDeadlineSettingResponse> {
      return localVarFp.getPartyDeadlineSetting(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {PartyGuestResponsesRequest} partyGuestResponsesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartyGuestResponses(
      partyGuestResponsesRequest: PartyGuestResponsesRequest,
      options?: any,
    ): AxiosPromise<PartyGuestResponsesResponse> {
      return localVarFp
        .getPartyGuestResponses(partyGuestResponsesRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartyGuestResponsesForCSV(options?: any): AxiosPromise<GetGuestsForCoupleCSVResponse> {
      return localVarFp
        .getPartyGuestResponsesForCSV(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartyGuestResponsesForWPSCSV(options?: any): AxiosPromise<GetGuestsForCoupleWPSCSVResponse> {
      return localVarFp
        .getPartyGuestResponsesForWPSCSV(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetPartyToUpdateHoldDateRequest} getPartyToUpdateHoldDateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartyToUpdateHoldDate(
      getPartyToUpdateHoldDateRequest: GetPartyToUpdateHoldDateRequest,
      options?: any,
    ): AxiosPromise<GetPartyToUpdateHoldDateResponse> {
      return localVarFp
        .getPartyToUpdateHoldDate(getPartyToUpdateHoldDateRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetPreSharedImageRequest} getPreSharedImageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPreSharedImages(
      getPreSharedImageRequest: GetPreSharedImageRequest,
      options?: any,
    ): AxiosPromise<GetPreSharedImageResponse> {
      return localVarFp
        .getPreSharedImages(getPreSharedImageRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateGuestAttendanceRequest} updateGuestAttendanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGuestAttendance(
      updateGuestAttendanceRequest: UpdateGuestAttendanceRequest,
      options?: any,
    ): AxiosPromise<UpdateGuestAttendanceResponse> {
      return localVarFp
        .updateGuestAttendance(updateGuestAttendanceRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateGuestCompanionAttendanceRequest} updateGuestCompanionAttendanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGuestCompanionAttendance(
      updateGuestCompanionAttendanceRequest: UpdateGuestCompanionAttendanceRequest,
      options?: any,
    ): AxiosPromise<UpdateGuestCompanionAttendanceResponse> {
      return localVarFp
        .updateGuestCompanionAttendance(updateGuestCompanionAttendanceRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdatePartyHoldDateRequest} updatePartyHoldDateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePartyHoldDate(
      updatePartyHoldDateRequest: UpdatePartyHoldDateRequest,
      options?: any,
    ): AxiosPromise<UpdatePartyHoldDateResponse> {
      return localVarFp
        .updatePartyHoldDate(updatePartyHoldDateRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateResponseDeadlineRequest} updateResponseDeadlineRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateResponseDeadline(
      updateResponseDeadlineRequest: UpdateResponseDeadlineRequest,
      options?: any,
    ): AxiosPromise<UpdateResponseDeadlineResponse> {
      return localVarFp
        .updateResponseDeadline(updateResponseDeadlineRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * CouplePartyControllerApi - object-oriented interface
 * @export
 * @class CouplePartyControllerApi
 * @extends {BaseAPI}
 */
export class CouplePartyControllerApi extends BaseAPI {
  /**
   *
   * @param {CreatePartyRequest} createPartyRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouplePartyControllerApi
   */
  public createParty(createPartyRequest: CreatePartyRequest, options?: AxiosRequestConfig) {
    return CouplePartyControllerApiFp(this.configuration)
      .createParty(createPartyRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouplePartyControllerApi
   */
  public getAMSSharedInfo(options?: AxiosRequestConfig) {
    return CouplePartyControllerApiFp(this.configuration)
      .getAMSSharedInfo(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouplePartyControllerApi
   */
  public getParty(options?: AxiosRequestConfig) {
    return CouplePartyControllerApiFp(this.configuration)
      .getParty(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouplePartyControllerApi
   */
  public getPartyDeadlineSetting(options?: AxiosRequestConfig) {
    return CouplePartyControllerApiFp(this.configuration)
      .getPartyDeadlineSetting(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {PartyGuestResponsesRequest} partyGuestResponsesRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouplePartyControllerApi
   */
  public getPartyGuestResponses(
    partyGuestResponsesRequest: PartyGuestResponsesRequest,
    options?: AxiosRequestConfig,
  ) {
    return CouplePartyControllerApiFp(this.configuration)
      .getPartyGuestResponses(partyGuestResponsesRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouplePartyControllerApi
   */
  public getPartyGuestResponsesForCSV(options?: AxiosRequestConfig) {
    return CouplePartyControllerApiFp(this.configuration)
      .getPartyGuestResponsesForCSV(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouplePartyControllerApi
   */
  public getPartyGuestResponsesForWPSCSV(options?: AxiosRequestConfig) {
    return CouplePartyControllerApiFp(this.configuration)
      .getPartyGuestResponsesForWPSCSV(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetPartyToUpdateHoldDateRequest} getPartyToUpdateHoldDateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouplePartyControllerApi
   */
  public getPartyToUpdateHoldDate(
    getPartyToUpdateHoldDateRequest: GetPartyToUpdateHoldDateRequest,
    options?: AxiosRequestConfig,
  ) {
    return CouplePartyControllerApiFp(this.configuration)
      .getPartyToUpdateHoldDate(getPartyToUpdateHoldDateRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetPreSharedImageRequest} getPreSharedImageRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouplePartyControllerApi
   */
  public getPreSharedImages(
    getPreSharedImageRequest: GetPreSharedImageRequest,
    options?: AxiosRequestConfig,
  ) {
    return CouplePartyControllerApiFp(this.configuration)
      .getPreSharedImages(getPreSharedImageRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateGuestAttendanceRequest} updateGuestAttendanceRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouplePartyControllerApi
   */
  public updateGuestAttendance(
    updateGuestAttendanceRequest: UpdateGuestAttendanceRequest,
    options?: AxiosRequestConfig,
  ) {
    return CouplePartyControllerApiFp(this.configuration)
      .updateGuestAttendance(updateGuestAttendanceRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateGuestCompanionAttendanceRequest} updateGuestCompanionAttendanceRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouplePartyControllerApi
   */
  public updateGuestCompanionAttendance(
    updateGuestCompanionAttendanceRequest: UpdateGuestCompanionAttendanceRequest,
    options?: AxiosRequestConfig,
  ) {
    return CouplePartyControllerApiFp(this.configuration)
      .updateGuestCompanionAttendance(updateGuestCompanionAttendanceRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdatePartyHoldDateRequest} updatePartyHoldDateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouplePartyControllerApi
   */
  public updatePartyHoldDate(
    updatePartyHoldDateRequest: UpdatePartyHoldDateRequest,
    options?: AxiosRequestConfig,
  ) {
    return CouplePartyControllerApiFp(this.configuration)
      .updatePartyHoldDate(updatePartyHoldDateRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateResponseDeadlineRequest} updateResponseDeadlineRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouplePartyControllerApi
   */
  public updateResponseDeadline(
    updateResponseDeadlineRequest: UpdateResponseDeadlineRequest,
    options?: AxiosRequestConfig,
  ) {
    return CouplePartyControllerApiFp(this.configuration)
      .updateResponseDeadline(updateResponseDeadlineRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

import { NavLink } from '@/components/cl/link'
import { useConfig } from '@/context/config'
import { colors } from '@/styles/colors'
import { ENTRY_EXTERNAL_WEB_URL } from '@/utils/common/externalWebURL'
import { css } from '@emotion/react'
import Button from '@mui/material/Button'
import Image from 'next/image'

export default function Footer() {
  const { config } = useConfig()

  return (
    <footer css={styles.footer}>
      <div css={styles.left}>
        <a
          href={ENTRY_EXTERNAL_WEB_URL(config).RECRUIT}
          target="_blank"
          rel="noopener noreferrer"
          css={styles.recruitLink}
        >
          <div css={styles.leftUpper}>
            <Image src="/images/recruit-logo.png" height={36} width={138} layout={'fixed'} />
            <div css={styles.copyright}>(C) Recruit Co., Ltd.</div>
          </div>
        </a>
      </div>
      <div css={styles.right}>
        <div css={styles.rightUpper}>
          <div css={styles.rightUpper1}>
            <p className="zexySupportDesk">ゼクシィサポートデスク</p>
            <p className="notice">※指定日は休業となります</p>
          </div>
          <div css={styles.rightUpper2}>
            <div css={styles.rightUpper2Inner}>
              <div css={styles.rightUpper2Item}>
                <p className="label">平日&emsp;</p>
                <p className="tel">03-3647-1885</p>
                <p className="receptionLabel">受付</p>
                <p className="receptionHours">&nbsp;&nbsp;9:00〜12:00、13:00〜19:00</p>
              </div>
              <div css={styles.rightUpper2Item}>
                <p className="label">土日祝</p>
                <p className="tel">03-4216-8660</p>
                <p className="receptionLabel">受付</p>
                <p className="receptionHours">10:00〜12:00、13:00〜18:00</p>
              </div>
            </div>
          </div>
          <div css={styles.button}>
            <NavLink
              href="https://www.zexysupport.net/inquiry/s/"
              target="_blank"
              className="navLink"
            >
              <Button
                variant="outlined"
                endIcon={
                  <Image src="/images/blank.svg" height={12.1} width={12.1} layout={'fixed'} />
                }
              >
                お問い合わせ
              </Button>
            </NavLink>
          </div>
        </div>
      </div>
    </footer>
  )
}

const styles = {
  footer: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 113px;
    padding: 24px 32px;
    margin-top: auto;

    .navLink {
      text-decoration: none;
    }
  `,
  left: css`
    display: flex;
    flex-direction: column;
  `,
  leftUpper: css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 36px;
  `,
  copyright: css`
    margin-left: 10px;
    font-size: 11px;
    font-weight: 700;
    line-height: 1;
    color: ${colors.COLOR_435B67};
  `,
  recruitLink: css`
    display: flex;
    align-items: flex-end;
    text-decoration-line: none;
  `,
  right: css`
    display: flex;
    flex-direction: column;
  `,
  rightUpper: css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 36px;
  `,
  rightUpper1: css`
    display: flex;
    flex-direction: column;
    margin-top: auto;
    font-style: normal;
    line-height: 100%;
    .zexySupportDesk {
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
      color: ${colors.COLOR_8D8D8D};
    }
    .notice {
      align-self: flex-end;
      margin-top: 4px;
      font-size: 10px;
      font-weight: 300;
      color: ${colors.COLOR_595959};
    }
  `,
  rightUpper2: css`
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 100%;
    color: ${colors.COLOR_595959};
    &::before {
      align-self: stretch;
      content: '';
      border: 1px solid ${colors.COLOR_CDCDCD};
    }
  `,
  rightUpper2Inner: css`
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: normal;
    line-height: 100%;
    color: ${colors.COLOR_595959};
  `,
  rightUpper2Item: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 16px;
    font-style: normal;
    line-height: 100%;
    color: ${colors.COLOR_595959};
    .label {
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
    }
    .tel {
      margin-left: 8px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 1px;
    }
    .receptionLabel {
      margin-left: 10px;
      font-size: 12px;
      font-weight: normal;
    }
    .receptionHours {
      margin-left: 4px;
      font-size: 12px;
      font-weight: 400;
    }
  `,
  button: css`
    align-self: center;
    margin-left: 16px;
    .MuiButton-root {
      width: 134px;
      height: 36px;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      color: ${colors.COLOR_595959};
      background-color: ${colors.COLOR_FFFFFF};
      border: 1px solid ${colors.COLOR_CFD2D9};
      border-radius: 4px;

      &:hover {
        background-color: ${colors.COLOR_FFFFFF};
        border-color: ${colors.COLOR_D8D8D8};
        opacity: 0.7;
      }
    }
  `,
}

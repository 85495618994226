/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface UpdatePaymentSettingRequest
 */
export interface UpdatePaymentSettingRequest {
  /**
   *
   * @type {string}
   * @memberof UpdatePaymentSettingRequest
   */
  invitationUUID: string
  /**
   *
   * @type {string}
   * @memberof UpdatePaymentSettingRequest
   */
  paymentType: UpdatePaymentSettingRequestPaymentType
  /**
   *
   * @type {string}
   * @memberof UpdatePaymentSettingRequest
   */
  partyPaymentType: UpdatePaymentSettingRequestPartyPaymentType
  /**
   *
   * @type {number}
   * @memberof UpdatePaymentSettingRequest
   */
  kaihiAmount?: number
  /**
   *
   * @type {string}
   * @memberof UpdatePaymentSettingRequest
   */
  kaihiSupplementaryText: string
  /**
   *
   * @type {string}
   * @memberof UpdatePaymentSettingRequest
   */
  guidanceText?: string
}

/**
 * @export
 * @enum {string}
 */
export enum UpdatePaymentSettingRequestPaymentType {
  notUsing = 'NOT_USING',
  using = 'USING',
}
/**
 * @export
 * @enum {string}
 */
export enum UpdatePaymentSettingRequestPartyPaymentType {
  goshugi = 'GOSHUGI',
  kaihi = 'KAIHI',
}

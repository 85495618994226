/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface UpdateInvitationContentsRequest
 */
export interface UpdateInvitationContentsRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationContentsRequest
   */
  invitationUUID: string
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationContentsRequest
   */
  invitationType: UpdateInvitationContentsRequestInvitationType
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationContentsRequest
   */
  mainImageUUID?: string
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationContentsRequest
   */
  middleImageUUID?: string
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationContentsRequest
   */
  guidanceText: string
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationContentsRequest
   */
  sender?: string
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationContentsRequest
   */
  acceptStartAt?: string
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationContentsRequest
   */
  weddingStartAt?: string
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationContentsRequest
   */
  receptionStartAt?: string
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationContentsRequest
   */
  customText?: string
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationContentsRequest
   */
  clientName: string
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationContentsRequest
   */
  clientDetail?: string
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationContentsRequest
   */
  clientURL?: string
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationContentsRequest
   */
  clientTel?: string
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationContentsRequest
   */
  clientPostCode: string
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationContentsRequest
   */
  clientAddress: string
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationContentsRequest
   */
  clientAccess?: string
  /**
   *
   * @type {string}
   * @memberof UpdateInvitationContentsRequest
   */
  message?: string
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateInvitationContentsRequestInvitationType {
  weddingReception = 'WEDDING_RECEPTION',
  wedding = 'WEDDING',
  reception = 'RECEPTION',
  casual = 'CASUAL',
  weddingPartyMeal = 'WEDDING_PARTY_MEAL',
  mealParty = 'MEAL_PARTY',
  custom = 'CUSTOM',
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface UpdateResponseDeadlineAfterDeadlineByClientResponse
 */
export interface UpdateResponseDeadlineAfterDeadlineByClientResponse {
  /**
   *
   * @type {string}
   * @memberof UpdateResponseDeadlineAfterDeadlineByClientResponse
   */
  responseDeadlineEditingID: string
  /**
   *
   * @type {string}
   * @memberof UpdateResponseDeadlineAfterDeadlineByClientResponse
   */
  updatedResponseDeadlineDate: string
  /**
   *
   * @type {string}
   * @memberof UpdateResponseDeadlineAfterDeadlineByClientResponse
   */
  guestPaymentSettingStatus: UpdateResponseDeadlineAfterDeadlineByClientResponseGuestPaymentSettingStatus
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateResponseDeadlineAfterDeadlineByClientResponseGuestPaymentSettingStatus {
  using = 'USING',
  notUsing = 'NOT_USING',
  toBeSet = 'TO_BE_SET',
}

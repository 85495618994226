/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface InvitePartnerResponse
 */
export interface InvitePartnerResponse {
  /**
   *
   * @type {string}
   * @memberof InvitePartnerResponse
   */
  partyState: InvitePartnerResponsePartyState
}

/**
 * @export
 * @enum {string}
 */
export enum InvitePartnerResponsePartyState {
  noParty = 'NO_PARTY',
  noInvitation = 'NO_INVITATION',
  onlyDraftInvitationOnlyUncompletedInvitation = 'ONLY_DRAFT_INVITATION_ONLY_UNCOMPLETED_INVITATION',
  onlyDraftInvitationHasCompletedInvitation = 'ONLY_DRAFT_INVITATION_HAS_COMPLETED_INVITATION',
  hasNotPublishedInvitationAndHasSuspendedInvitation = 'HAS_NOT_PUBLISHED_INVITATION_AND_HAS_SUSPENDED_INVITATION',
  hasPublishedInvitationBeforeDeadline = 'HAS_PUBLISHED_INVITATION_BEFORE_DEADLINE',
  hasPublishedInvitationAfterDeadline = 'HAS_PUBLISHED_INVITATION_AFTER_DEADLINE',
  hasPublishedInvitationEndOfPublication = 'HAS_PUBLISHED_INVITATION_END_OF_PUBLICATION',
}

import { colors } from '@/styles/colors'
import { css } from '@emotion/react'
import Image from 'next/image'

export function AllClientEntryCompleted() {
  return (
    <div css={styles.complete}>
      <Image src={'/images/entry/complete.svg'} width={72} height={72} />
      <div css={styles.completeText}>申込受付はすべて完了しています</div>
    </div>
  )
}

const styles = {
  complete: css`
    margin-bottom: 38px;
    text-align: center;
  `,
  completeText: css`
    margin-top: 16px;
    font-size: 24px;
    font-weight: 600;
    color: ${colors.COLOR_595959};
  `,
}

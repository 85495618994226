import { useSessionStorage } from 'react-use'

import { EntryPath, ENTRY_PATH } from './path'

export const ACCOUNT_TYPES = {
  ORDINARY: 'ORDINARY',
  CHECKING: 'CHECKING',
  SAVINGS: 'SAVINGS',
} as const

/**
 * 口座種別はリクエストの型と合わせる
 * @type {EntryRegisterRequestPaymentToClientInfoAccountType}
 */
export type ACCOUNT_TYPE = typeof ACCOUNT_TYPES[keyof typeof ACCOUNT_TYPES]

export type EntryFormSessionStorageType = {
  entryNumber?: string
  userId?: string
  bankAccount?: Array<{
    accountName: string
    accountNameKana: string
    accountNumber: string
    accountType: ACCOUNT_TYPE
    bankCode: string
    bankName: string
    branchCode: string
    branchName: string
  }>
  entryTargetClient?: {
    clientCd?: string
    madoguchiName?: string
    sponsorCd?: string
  }
  clientInfo?: {
    clientAddress?: string
    clientAddressKana?: string
    clientAddressPostCode?: string
    clientUrl?: string
    clientName?: string
    clientNameKana?: string
    clientNameRoman?: string
    clientResponsiblePersonRole?: string
    clientResponsiblePersonEmailAddress?: string
    clientResponsiblePersonFirstName?: string
    clientResponsiblePersonFirstNameKana?: string
    clientResponsiblePersonLastName?: string
    clientResponsiblePersonLastNameKana?: string
    clientTellNumber?: string
    entryBankAccountIndex?: string
    merchantName?: string
    merchantAddress?: string
    merchantAddressPostCode?: string
    merchantTellNumber?: string
    merchantEmailAddress?: string
    merchantResponsiblePersonName?: string
    cancelPolicy?: string
    requiredPaymentOtherThanSellingPrice?: string
    aboutDefects?: string
    aboutSpecialSalesConditions?: string
  }
  houjinInfo?: {
    entryActorEmailAddress?: string
    entryActorFirstName?: string
    entryActorFirstNameKana?: string
    entryActorLastName?: string
    entryActorLastNameKana?: string
    houjinCd?: string
    houjinAddress?: string
    houjinAddressKana?: string
    houjinAddressPostCode?: string
    houjinEstablishmentDate?: string
    houjinName?: string
    houjinNameKana?: string
    houjinNumber?: string
    houjinTellNumber?: string
    houijnUrl?: string
    ownerBirthDay?: string
    ownerName?: string
    ownerNameKana?: string
  }
  notificationInfo?: {
    notifyResponsiblePerson?: {
      name?: string
      emailAddress?: string
      shouldNotifyRegisterEventChecked?: boolean
      shouldNotifyServiceStartEventChecked?: boolean
      shouldNotifyPaymentEventChecked?: boolean
    }
    notifyPerson1?: {
      name?: string
      emailAddress?: string
      shouldNotifyRegisterEventChecked?: boolean
      shouldNotifyServiceStartEventChecked?: boolean
      shouldNotifyPaymentEventChecked?: boolean
    }
    notifyPerson2?: {
      name?: string
      emailAddress?: string
      shouldNotifyRegisterEventChecked?: boolean
      shouldNotifyServiceStartEventChecked?: boolean
      shouldNotifyPaymentEventChecked?: boolean
    }
    notifyPerson3?: {
      name?: string
      emailAddress?: string
      shouldNotifyRegisterEventChecked?: boolean
      shouldNotifyServiceStartEventChecked?: boolean
      shouldNotifyPaymentEventChecked?: boolean
    }
  }
}

const STORAGE_KEYS = {
  USER_ID: 'userId',
  HOUJIN_INFO: 'houjinInfo',
  ENTRY_TARGET_CLIENT: 'entryTargetClient',
  CLIENT_INFO: 'clientInfo',
  NOTIFICATION_INFO: 'notificationInfo',
  ENTRY_NUMBER: 'entryNumber',
}

/** 画面を表示する際に必須となるストレージ（前のフローまでの入力情報）を画面毎に定義する */
const requiredStorageForPages = {
  houjinInfo: [STORAGE_KEYS.USER_ID],
  houjinInfoConfirm: [STORAGE_KEYS.USER_ID, STORAGE_KEYS.HOUJIN_INFO],
  selectClient: [STORAGE_KEYS.USER_ID, STORAGE_KEYS.HOUJIN_INFO],
  clientInfo: [STORAGE_KEYS.USER_ID, STORAGE_KEYS.HOUJIN_INFO, STORAGE_KEYS.ENTRY_TARGET_CLIENT],
  clientInfoNotification: [
    STORAGE_KEYS.USER_ID,
    STORAGE_KEYS.HOUJIN_INFO,
    STORAGE_KEYS.ENTRY_TARGET_CLIENT,
    STORAGE_KEYS.CLIENT_INFO,
  ],
  clientInfoConfirm: [
    STORAGE_KEYS.USER_ID,
    STORAGE_KEYS.HOUJIN_INFO,
    STORAGE_KEYS.ENTRY_TARGET_CLIENT,
    STORAGE_KEYS.CLIENT_INFO,
    STORAGE_KEYS.NOTIFICATION_INFO,
  ],
  complete: [
    STORAGE_KEYS.USER_ID,
    STORAGE_KEYS.HOUJIN_INFO,
    STORAGE_KEYS.ENTRY_TARGET_CLIENT,
    STORAGE_KEYS.CLIENT_INFO,
    STORAGE_KEYS.NOTIFICATION_INFO,
    STORAGE_KEYS.ENTRY_NUMBER,
  ],
}

/**
 * 現在のストレージの値を受け取り、画面毎に必要なストレージデータが揃っているのかを判定する
 * 前の画面までの全ての入力データを保持している場合、正常フローと判断して true をリターンする
 * 前の画面までのいずれかの入力データを保持していない場合、正常フローではないと判定して false をリターンする
 */
export const hasValidStorageKeys = (pathname: EntryPath, storage: EntryFormSessionStorageType) => {
  if (!storage) {
    return false
  }

  const storageKeys = Object.keys(storage)

  /** 各画面毎に定義した「前の画面までの入力情報」が現在のストレージに全て存在するかを確認する */
  switch (pathname) {
    case ENTRY_PATH.HOUJIN_INFO:
      return requiredStorageForPages.houjinInfo.every((key) => storageKeys.includes(key))
    case ENTRY_PATH.HOUJIN_INFO_CONFIRM:
      return requiredStorageForPages.houjinInfoConfirm.every((key) => storageKeys.includes(key))
    case ENTRY_PATH.SELECT_CLIENT:
      return requiredStorageForPages.selectClient.every((key) => storageKeys.includes(key))
    case ENTRY_PATH.CLIENT_INFO:
      return requiredStorageForPages.clientInfo.every((key) => storageKeys.includes(key))
    case ENTRY_PATH.NOTIFICATION_INFO:
      return requiredStorageForPages.clientInfoNotification.every((key) =>
        storageKeys.includes(key),
      )
    case ENTRY_PATH.CLIENT_INFO_CONFIRM:
      return requiredStorageForPages.clientInfoConfirm.every((key) => storageKeys.includes(key))
    case ENTRY_PATH.COMPLETE:
      return requiredStorageForPages.complete.every((key) => storageKeys.includes(key))
    default:
      return false
  }
}

export const useEntryFormInputValuesOnStorage = () => {
  const [entryFormInputValuesOnStorage, setEntryFormInputValuesOnStorage] =
    useSessionStorage<EntryFormSessionStorageType>('entry_form_input_values', {})

  const removeEntryFormInputValuesOnStorage = () => {
    setEntryFormInputValuesOnStorage({})
  }

  return {
    entryFormInputValuesOnStorage,
    setEntryFormInputValuesOnStorage,
    removeEntryFormInputValuesOnStorage,
  }
}

/**
 * ストレージの entry_form_input_values キーに対して各画面の入力値をプッシュしているが、
 * sessionStorage.removeItem()はネストされたオブジェクトを削除できないので、
 * entry_form_input_values キーの削除は出来るが、ネストされた houjinInfo や clientInfo などのキー単位で削除はできない
 * これを行う場合は houjinInfo や clientInfo などのデータを親キーを持たずにストレージで直接管理する方針に切り替える必要がある
 * 現段階では、ストレージを引数で受け取り、delete で加工したものをリターンし、呼び出し元でストレージの再代入を行なっている
 */
export const deleteClientInfoInputValuesOnStorage = (
  inputValuesOnStorage: EntryFormSessionStorageType,
) => {
  delete inputValuesOnStorage.entryTargetClient
  delete inputValuesOnStorage.clientInfo
  delete inputValuesOnStorage.notificationInfo
  delete inputValuesOnStorage.entryNumber

  return inputValuesOnStorage
}

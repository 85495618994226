import { ErrorHead } from '@/components/common/commonHead'
import { Layout } from '@/components/entry/layout'
import { useConfig } from '@/context/config'
import { colors } from '@/styles/colors'
import { ENTRY_EXTERNAL_WEB_URL } from '@/utils/common/externalWebURL'
import { css } from '@emotion/react'
import Image from 'next/image'

export default function EntryTargetHoujinError() {
  const { config } = useConfig()

  return (
    <Layout showEntryCloseModalOnLogout={false}>
      <ErrorHead />
      <div css={styles.container}>
        <Image src={'/images/entry/error.svg'} width={89} height={88} />
        <h2 css={styles.title}>ログイン中のzebra IDで申込できる会場がありません</h2>
        <div css={styles.textBox}>
          <p css={styles.text}>
            詳細は、
            <a
              href={ENTRY_EXTERNAL_WEB_URL(config).SUPPORT_DESK}
              css={styles.anchor}
              target="_blank"
              rel="noopener noreferrer"
            >
              ゼクシィサポートデスク
            </a>
            までお問い合わせください。
          </p>
        </div>
      </div>
    </Layout>
  )
}

const styles = {
  container: css`
    width: 960px;
    margin: 85px auto 0;
    text-align: center;
  `,
  title: css`
    margin-top: 20px;
    font-size: 24px;
    font-weight: 600;
    color: ${colors.COLOR_595959};
  `,
  textBox: css`
    width: 100%;
    padding: 56px 80px;
    margin-top: 40px;
    text-align: center;
    background: ${colors.COLOR_FFFFFF};
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  `,
  text: css`
    font-size: 16px;
    font-weight: 300;
    color: ${colors.COLOR_595959};
  `,
  anchor: css`
    color: ${colors.COLOR_3985D0};
    text-decoration: none;
  `,
}

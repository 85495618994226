/**
 * switch の分岐などであり得ないパターンなどが呼ばれた際に実行する関数
 * 引数を never型 でとることで分岐パターンの追加などの際に考慮漏れがあった場合はコンパイルエラーとなる
 * 実行時にエラーが起こった際はランタイムエラーとなる
 * see: https://typescript-jp.gitbook.io/deep-dive/type-system/discriminated-unions#chekkunodewogeru
 */
export const assertNever = (n: never) => {
  throw new AssertNeverError(n)
}

export class AssertNeverError extends Error {
  constructor(n: never) {
    super(`Unexpected value ${n}. Should have been never.`)
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { EntryRegisterRequest } from '../model'
// @ts-ignore
import { EntryRegisterResponse } from '../model'
// @ts-ignore
import { ErrorResponse } from '../model'
// @ts-ignore
import { GetBankBranchesRequest } from '../model'
// @ts-ignore
import { GetBankBranchesResponse } from '../model'
// @ts-ignore
import { GetBanksRequest } from '../model'
// @ts-ignore
import { GetBanksResponse } from '../model'
// @ts-ignore
import { GetClientInfoRequest } from '../model'
// @ts-ignore
import { GetClientInfoResponse } from '../model'
// @ts-ignore
import { GetEntryTargetClientsResponse } from '../model'
// @ts-ignore
import { GetHoujinInfoResponse } from '../model'
// @ts-ignore
import { GetMajorBanksResponse } from '../model'
// @ts-ignore
import { ValidateClientEntryRequest } from '../model'
// @ts-ignore
import { ValidateHoujinEntryRequest } from '../model'
// @ts-ignore
import { ValidateNotificationEntryRequest } from '../model'
/**
 * EntryControllerApi - axios parameter creator
 * @export
 */
export const EntryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {EntryRegisterRequest} entryRegisterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entryRegister: async (
      entryRegisterRequest: EntryRegisterRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'entryRegisterRequest' is not null or undefined
      assertParamExists('entryRegister', 'entryRegisterRequest', entryRegisterRequest)
      const localVarPath = `/entry/api/entryRegister`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        entryRegisterRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetBankBranchesRequest} getBankBranchesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBankBranches: async (
      getBankBranchesRequest: GetBankBranchesRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getBankBranchesRequest' is not null or undefined
      assertParamExists('getBankBranches', 'getBankBranchesRequest', getBankBranchesRequest)
      const localVarPath = `/entry/api/getBankBranches`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getBankBranchesRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetBanksRequest} getBanksRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBanks: async (
      getBanksRequest: GetBanksRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getBanksRequest' is not null or undefined
      assertParamExists('getBanks', 'getBanksRequest', getBanksRequest)
      const localVarPath = `/entry/api/getBanks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getBanksRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetClientInfoRequest} getClientInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientInfo: async (
      getClientInfoRequest: GetClientInfoRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getClientInfoRequest' is not null or undefined
      assertParamExists('getClientInfo', 'getClientInfoRequest', getClientInfoRequest)
      const localVarPath = `/entry/api/getClientInfo`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getClientInfoRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntryTargetClientsResponse: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/entry/api/getEntryTargetClients`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHoujinInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/entry/api/getHoujinInfo`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMajorBanks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/entry/api/getMajorBanks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ValidateClientEntryRequest} validateClientEntryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateClientEntry: async (
      validateClientEntryRequest: ValidateClientEntryRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'validateClientEntryRequest' is not null or undefined
      assertParamExists(
        'validateClientEntry',
        'validateClientEntryRequest',
        validateClientEntryRequest,
      )
      const localVarPath = `/entry/api/validateClientEntry`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        validateClientEntryRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ValidateHoujinEntryRequest} validateHoujinEntryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateHoujinEntry: async (
      validateHoujinEntryRequest: ValidateHoujinEntryRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'validateHoujinEntryRequest' is not null or undefined
      assertParamExists(
        'validateHoujinEntry',
        'validateHoujinEntryRequest',
        validateHoujinEntryRequest,
      )
      const localVarPath = `/entry/api/validateHoujinEntry`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        validateHoujinEntryRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ValidateNotificationEntryRequest} validateNotificationEntryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateNotificationEntry: async (
      validateNotificationEntryRequest: ValidateNotificationEntryRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'validateNotificationEntryRequest' is not null or undefined
      assertParamExists(
        'validateNotificationEntry',
        'validateNotificationEntryRequest',
        validateNotificationEntryRequest,
      )
      const localVarPath = `/entry/api/validateNotificationEntry`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        validateNotificationEntryRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * EntryControllerApi - functional programming interface
 * @export
 */
export const EntryControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EntryControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {EntryRegisterRequest} entryRegisterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async entryRegister(
      entryRegisterRequest: EntryRegisterRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntryRegisterResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.entryRegister(
        entryRegisterRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetBankBranchesRequest} getBankBranchesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBankBranches(
      getBankBranchesRequest: GetBankBranchesRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBankBranchesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBankBranches(
        getBankBranchesRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetBanksRequest} getBanksRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBanks(
      getBanksRequest: GetBanksRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBanksResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBanks(getBanksRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetClientInfoRequest} getClientInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientInfo(
      getClientInfoRequest: GetClientInfoRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientInfoResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClientInfo(
        getClientInfoRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEntryTargetClientsResponse(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEntryTargetClientsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEntryTargetClientsResponse(
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHoujinInfo(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHoujinInfoResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHoujinInfo(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMajorBanks(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMajorBanksResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMajorBanks(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {ValidateClientEntryRequest} validateClientEntryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateClientEntry(
      validateClientEntryRequest: ValidateClientEntryRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validateClientEntry(
        validateClientEntryRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {ValidateHoujinEntryRequest} validateHoujinEntryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateHoujinEntry(
      validateHoujinEntryRequest: ValidateHoujinEntryRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validateHoujinEntry(
        validateHoujinEntryRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {ValidateNotificationEntryRequest} validateNotificationEntryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateNotificationEntry(
      validateNotificationEntryRequest: ValidateNotificationEntryRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validateNotificationEntry(
        validateNotificationEntryRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * EntryControllerApi - factory interface
 * @export
 */
export const EntryControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EntryControllerApiFp(configuration)
  return {
    /**
     *
     * @param {EntryRegisterRequest} entryRegisterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    entryRegister(
      entryRegisterRequest: EntryRegisterRequest,
      options?: any,
    ): AxiosPromise<EntryRegisterResponse> {
      return localVarFp
        .entryRegister(entryRegisterRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetBankBranchesRequest} getBankBranchesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBankBranches(
      getBankBranchesRequest: GetBankBranchesRequest,
      options?: any,
    ): AxiosPromise<GetBankBranchesResponse> {
      return localVarFp
        .getBankBranches(getBankBranchesRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetBanksRequest} getBanksRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBanks(getBanksRequest: GetBanksRequest, options?: any): AxiosPromise<GetBanksResponse> {
      return localVarFp
        .getBanks(getBanksRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetClientInfoRequest} getClientInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientInfo(
      getClientInfoRequest: GetClientInfoRequest,
      options?: any,
    ): AxiosPromise<GetClientInfoResponse> {
      return localVarFp
        .getClientInfo(getClientInfoRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntryTargetClientsResponse(options?: any): AxiosPromise<GetEntryTargetClientsResponse> {
      return localVarFp
        .getEntryTargetClientsResponse(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHoujinInfo(options?: any): AxiosPromise<GetHoujinInfoResponse> {
      return localVarFp.getHoujinInfo(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMajorBanks(options?: any): AxiosPromise<GetMajorBanksResponse> {
      return localVarFp.getMajorBanks(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {ValidateClientEntryRequest} validateClientEntryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateClientEntry(
      validateClientEntryRequest: ValidateClientEntryRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .validateClientEntry(validateClientEntryRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {ValidateHoujinEntryRequest} validateHoujinEntryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateHoujinEntry(
      validateHoujinEntryRequest: ValidateHoujinEntryRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .validateHoujinEntry(validateHoujinEntryRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {ValidateNotificationEntryRequest} validateNotificationEntryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateNotificationEntry(
      validateNotificationEntryRequest: ValidateNotificationEntryRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .validateNotificationEntry(validateNotificationEntryRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * EntryControllerApi - object-oriented interface
 * @export
 * @class EntryControllerApi
 * @extends {BaseAPI}
 */
export class EntryControllerApi extends BaseAPI {
  /**
   *
   * @param {EntryRegisterRequest} entryRegisterRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntryControllerApi
   */
  public entryRegister(entryRegisterRequest: EntryRegisterRequest, options?: AxiosRequestConfig) {
    return EntryControllerApiFp(this.configuration)
      .entryRegister(entryRegisterRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetBankBranchesRequest} getBankBranchesRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntryControllerApi
   */
  public getBankBranches(
    getBankBranchesRequest: GetBankBranchesRequest,
    options?: AxiosRequestConfig,
  ) {
    return EntryControllerApiFp(this.configuration)
      .getBankBranches(getBankBranchesRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetBanksRequest} getBanksRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntryControllerApi
   */
  public getBanks(getBanksRequest: GetBanksRequest, options?: AxiosRequestConfig) {
    return EntryControllerApiFp(this.configuration)
      .getBanks(getBanksRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetClientInfoRequest} getClientInfoRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntryControllerApi
   */
  public getClientInfo(getClientInfoRequest: GetClientInfoRequest, options?: AxiosRequestConfig) {
    return EntryControllerApiFp(this.configuration)
      .getClientInfo(getClientInfoRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntryControllerApi
   */
  public getEntryTargetClientsResponse(options?: AxiosRequestConfig) {
    return EntryControllerApiFp(this.configuration)
      .getEntryTargetClientsResponse(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntryControllerApi
   */
  public getHoujinInfo(options?: AxiosRequestConfig) {
    return EntryControllerApiFp(this.configuration)
      .getHoujinInfo(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntryControllerApi
   */
  public getMajorBanks(options?: AxiosRequestConfig) {
    return EntryControllerApiFp(this.configuration)
      .getMajorBanks(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ValidateClientEntryRequest} validateClientEntryRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntryControllerApi
   */
  public validateClientEntry(
    validateClientEntryRequest: ValidateClientEntryRequest,
    options?: AxiosRequestConfig,
  ) {
    return EntryControllerApiFp(this.configuration)
      .validateClientEntry(validateClientEntryRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ValidateHoujinEntryRequest} validateHoujinEntryRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntryControllerApi
   */
  public validateHoujinEntry(
    validateHoujinEntryRequest: ValidateHoujinEntryRequest,
    options?: AxiosRequestConfig,
  ) {
    return EntryControllerApiFp(this.configuration)
      .validateHoujinEntry(validateHoujinEntryRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ValidateNotificationEntryRequest} validateNotificationEntryRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntryControllerApi
   */
  public validateNotificationEntry(
    validateNotificationEntryRequest: ValidateNotificationEntryRequest,
    options?: AxiosRequestConfig,
  ) {
    return EntryControllerApiFp(this.configuration)
      .validateNotificationEntry(validateNotificationEntryRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { CheckClientAuthResponse } from '../model'
// @ts-ignore
import { ClientLoginRequest } from '../model'
// @ts-ignore
import { ErrorResponse } from '../model'
// @ts-ignore
import { GetClientAuthUserResponse } from '../model'
/**
 * ClientAuthControllerApi - axios parameter creator
 * @export
 */
export const ClientAuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkClientAuth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cl/api/auth/check`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ClientLoginRequest} clientLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientLogin: async (
      clientLoginRequest: ClientLoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientLoginRequest' is not null or undefined
      assertParamExists('clientLogin', 'clientLoginRequest', clientLoginRequest)
      const localVarPath = `/cl/api/auth/login`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        clientLoginRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cl/api/auth/logout`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientAuthUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cl/api/auth/getClientAuthUser`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ClientAuthControllerApi - functional programming interface
 * @export
 */
export const ClientAuthControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ClientAuthControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkClientAuth(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckClientAuthResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkClientAuth(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {ClientLoginRequest} clientLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientLogin(
      clientLoginRequest: ClientLoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientLogin(
        clientLoginRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientLogout(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientLogout(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientAuthUser(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientAuthUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClientAuthUser(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ClientAuthControllerApi - factory interface
 * @export
 */
export const ClientAuthControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ClientAuthControllerApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkClientAuth(options?: any): AxiosPromise<CheckClientAuthResponse> {
      return localVarFp.checkClientAuth(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {ClientLoginRequest} clientLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientLogin(clientLoginRequest: ClientLoginRequest, options?: any): AxiosPromise<object> {
      return localVarFp
        .clientLogin(clientLoginRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientLogout(options?: any): AxiosPromise<object> {
      return localVarFp.clientLogout(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientAuthUser(options?: any): AxiosPromise<GetClientAuthUserResponse> {
      return localVarFp.getClientAuthUser(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * ClientAuthControllerApi - object-oriented interface
 * @export
 * @class ClientAuthControllerApi
 * @extends {BaseAPI}
 */
export class ClientAuthControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientAuthControllerApi
   */
  public checkClientAuth(options?: AxiosRequestConfig) {
    return ClientAuthControllerApiFp(this.configuration)
      .checkClientAuth(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ClientLoginRequest} clientLoginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientAuthControllerApi
   */
  public clientLogin(clientLoginRequest: ClientLoginRequest, options?: AxiosRequestConfig) {
    return ClientAuthControllerApiFp(this.configuration)
      .clientLogin(clientLoginRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientAuthControllerApi
   */
  public clientLogout(options?: AxiosRequestConfig) {
    return ClientAuthControllerApiFp(this.configuration)
      .clientLogout(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientAuthControllerApi
   */
  public getClientAuthUser(options?: AxiosRequestConfig) {
    return ClientAuthControllerApiFp(this.configuration)
      .getClientAuthUser(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

import { Authentication } from '@/components/cs/auth/authentication'
import ForceAuthentication from '@/components/cs/auth/forceAuthentication'
import CreateInvitationHeader from '@/components/cs/createInvitationHeader'
import Footer from '@/components/cs/footer'
import Header from '@/components/cs/header'
import LoadingOverlay from '@/components/cs/loadingOverlay'
import { fontStyle } from '@/styles/cs/styleConstants'
import { css, Global, SerializedStyles } from '@emotion/react'
import { createTheme, ThemeProvider } from '@mui/material/styles'

export type HeaderCondition =
  | {
      headerType: 'DefaultHeader'
      addHeaderStyle?: SerializedStyles
    }
  | {
      headerType: 'CreateInvitationHeader'
      skipHref?: string
      skipAs?: string
      skipEventName?: string
    }
  | {
      headerType: 'NoneHeader'
    }

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
const CsHeader = ({ headerCondition }) => {
  if (headerCondition.headerType === 'DefaultHeader') {
    return <Header addHeaderStyle={headerCondition.addHeaderStyle} />
  }
  if (headerCondition.headerType === 'CreateInvitationHeader') {
    return (
      <CreateInvitationHeader
        skipHref={headerCondition.skipHref}
        skipAs={headerCondition.skipAs}
        skipEventName={headerCondition.skipEventName}
      />
    )
  }
  return <></>
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
function AuthController({ children, shouldAuthentication }) {
  if (shouldAuthentication) {
    return <ForceAuthentication>{children}</ForceAuthentication>
  }
  return <>{children}</>
}

type LayoutType = {
  children?: React.ReactNode
  page?: React.ReactNode
  headerCondition?: HeaderCondition
  shouldAuthentication?: boolean
}
export default function Layout({
  children,
  page,
  headerCondition = { headerType: 'NoneHeader' },
  shouldAuthentication = true,
}: LayoutType) {
  const theme = createTheme({
    typography: fontStyle.ja,
  })

  return (
    <Authentication>
      <AuthController shouldAuthentication={shouldAuthentication}>
        <Global styles={[styles.font]} />
        <ThemeProvider theme={theme}>
          <div css={styles.wrapper}>
            <CsHeader headerCondition={headerCondition} />
            <main css={styles.main}>{page || children}</main>
            <Footer />
            <LoadingOverlay />
          </div>
        </ThemeProvider>
      </AuthController>
    </Authentication>
  )
}

const styles = {
  font: css`
    html,
    body,
    button,
    textarea,
    input,
    label,
    select,
    span,
    a {
      font-family: ${fontStyle.ja.fontFamily};
    }
  `,
  wrapper: css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  `,
  main: css`
    display: flex;
    flex: 1;
    flex-direction: column;
  `,
}

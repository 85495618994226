/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { CheckGuestListUpCoupleSessionResponse } from '../model'
// @ts-ignore
import { ErrorResponse } from '../model'
/**
 * GuestListUpCoupleSessionControllerApi - axios parameter creator
 * @export
 */
export const GuestListUpCoupleSessionControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkGuestListUpCoupleSession: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cs_guest_list_up/api/session/checkGuestListUpCoupleSession`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * GuestListUpCoupleSessionControllerApi - functional programming interface
 * @export
 */
export const GuestListUpCoupleSessionControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    GuestListUpCoupleSessionControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkGuestListUpCoupleSession(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CheckGuestListUpCoupleSessionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkGuestListUpCoupleSession(
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * GuestListUpCoupleSessionControllerApi - factory interface
 * @export
 */
export const GuestListUpCoupleSessionControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GuestListUpCoupleSessionControllerApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkGuestListUpCoupleSession(
      options?: any,
    ): AxiosPromise<CheckGuestListUpCoupleSessionResponse> {
      return localVarFp
        .checkGuestListUpCoupleSession(options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * GuestListUpCoupleSessionControllerApi - object-oriented interface
 * @export
 * @class GuestListUpCoupleSessionControllerApi
 * @extends {BaseAPI}
 */
export class GuestListUpCoupleSessionControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestListUpCoupleSessionControllerApi
   */
  public checkGuestListUpCoupleSession(options?: AxiosRequestConfig) {
    return GuestListUpCoupleSessionControllerApiFp(this.configuration)
      .checkGuestListUpCoupleSession(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

import { SerializedStyles } from '@emotion/react'

type IconProps = {
  css?: SerializedStyles | Array<SerializedStyles>
  onClick?: () => void
}

type BlindIconProps = IconProps & {
  reveal: boolean
}

export const CheckboxCheckIcon = (props: IconProps) => (
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 4.43478L4.93939 9L11 1"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const RadioOffIcon = (props: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="8" cy="8" r="7" fill="#3C3C43" fillOpacity="0.06" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9423 6.73345C12.1915 6.48425 12.1915 6.08021 11.9423 5.831C11.6931 5.5818 11.2891 5.5818 11.0399 5.831L7.22113 9.64975L4.96055 7.38918C4.71134 7.13997 4.3073 7.13997 4.0581 7.38918C3.80889 7.63838 3.80889 8.04242 4.0581 8.29163L6.76786 11.0014C6.97141 11.2049 7.27825 11.2422 7.51965 11.1133C7.57701 11.0837 7.63083 11.0449 7.6789 10.9969L11.9423 6.73345Z"
      fill="white"
    />
  </svg>
)

export const RadioOnIcon = (props: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="8" cy="8" r="7" fill="url(#paint0_linear_328_5900)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9423 6.73345C12.1915 6.48425 12.1915 6.08021 11.9423 5.831C11.6931 5.5818 11.2891 5.5818 11.0399 5.831L7.22113 9.64975L4.96055 7.38918C4.71134 7.13997 4.3073 7.13997 4.0581 7.38918C3.80889 7.63838 3.80889 8.04242 4.0581 8.29163L6.76786 11.0014C6.97141 11.2049 7.27825 11.2422 7.51965 11.1133C7.57701 11.0837 7.63083 11.0449 7.6789 10.9969L11.9423 6.73345Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_328_5900"
        x1="1.525"
        y1="1.00001"
        x2="16.359"
        y2="3.73087"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EA6077" />
        <stop offset="1" stopColor="#F67A7F" />
      </linearGradient>
    </defs>
  </svg>
)

export const RadioOffDisabledIcon = (props: IconProps) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="8.5" cy="8" r="7" fill="#3C3C43" fillOpacity="0.06" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4423 6.73345C12.6915 6.48425 12.6915 6.08021 12.4423 5.831C12.1931 5.5818 11.7891 5.5818 11.5399 5.831L7.72144 9.64944L5.4607 7.38871C5.2115 7.13951 4.80746 7.13951 4.55825 7.38871C4.30905 7.63792 4.30905 8.04196 4.55825 8.29116L7.26801 11.0009C7.46579 11.1987 7.76111 11.2395 7.99914 11.1234C8.06419 11.0928 8.12514 11.0506 8.1789 10.9969L12.4423 6.73345Z"
      fill="white"
    />
  </svg>
)

export const RadioOnDisabledIcon = (props: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="8" cy="8" r="7" fill="#3C3C43" fillOpacity="0.06" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9423 6.73345C12.1915 6.48425 12.1915 6.08021 11.9423 5.831C11.6931 5.5818 11.2891 5.5818 11.0399 5.831L7.22144 9.64944L4.9607 7.38871C4.7115 7.13951 4.30746 7.13951 4.05825 7.38871C3.80905 7.63792 3.80905 8.04196 4.05825 8.29116L6.76801 11.0009C6.96579 11.1987 7.26111 11.2395 7.49914 11.1234C7.56419 11.0928 7.62514 11.0506 7.6789 10.9969L11.9423 6.73345Z"
      fill="#EA6077"
    />
  </svg>
)

export const ArrowDownIcon = (props: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_97_2710)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9127 5.77093C14.2128 5.47083 14.2128 4.98425 13.9127 4.68414C13.6125 4.38403 13.126 4.38403 12.8259 4.68414L8.22776 9.28225L3.63036 4.68485C3.33025 4.38474 2.84368 4.38474 2.54357 4.68485C2.24346 4.98496 2.24346 5.47153 2.54357 5.77164L7.68263 10.9107C7.94009 11.1682 8.33479 11.2048 8.63126 11.0205C8.68367 10.9889 8.73313 10.9505 8.77835 10.9052L13.9127 5.77093Z"
        fill="#4B4B4B"
      />
    </g>
    <defs>
      <clipPath id="clip0_97_2710">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const ArrowFillDownIcon = (props: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.73455 11.508C8.33863 11.9366 7.66137 11.9366 7.26544 11.508L2.63368 6.49402C2.33786 6.17378 2.56499 5.65474 3.00096 5.65474H12.999C13.435 5.65474 13.6621 6.17378 13.3663 6.49402L8.73455 11.508Z"
      fill="#4B4B4B"
    />
  </svg>
)

export const CalenderIcon = (props: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.6666 2.66683H11.3333V2.00016C11.3333 1.82335 11.263 1.65378 11.138 1.52876C11.013 1.40373 10.8434 1.3335 10.6666 1.3335C10.4898 1.3335 10.3202 1.40373 10.1952 1.52876C10.0702 1.65378 9.99992 1.82335 9.99992 2.00016V2.66683H5.99992V2.00016C5.99992 1.82335 5.92968 1.65378 5.80466 1.52876C5.67963 1.40373 5.51006 1.3335 5.33325 1.3335C5.15644 1.3335 4.98687 1.40373 4.86185 1.52876C4.73682 1.65378 4.66659 1.82335 4.66659 2.00016V2.66683H3.33325C2.80282 2.66683 2.29411 2.87754 1.91904 3.25262C1.54397 3.62769 1.33325 4.1364 1.33325 4.66683V12.6668C1.33325 13.1973 1.54397 13.706 1.91904 14.081C2.29411 14.4561 2.80282 14.6668 3.33325 14.6668H12.6666C13.197 14.6668 13.7057 14.4561 14.0808 14.081C14.4559 13.706 14.6666 13.1973 14.6666 12.6668V4.66683C14.6666 4.1364 14.4559 3.62769 14.0808 3.25262C13.7057 2.87754 13.197 2.66683 12.6666 2.66683ZM13.3333 12.6668C13.3333 12.8436 13.263 13.0132 13.138 13.1382C13.013 13.2633 12.8434 13.3335 12.6666 13.3335H3.33325C3.15644 13.3335 2.98687 13.2633 2.86185 13.1382C2.73682 13.0132 2.66659 12.8436 2.66659 12.6668V8.00016H13.3333V12.6668ZM13.3333 6.66683H2.66659V4.66683C2.66659 4.49002 2.73682 4.32045 2.86185 4.19543C2.98687 4.0704 3.15644 4.00016 3.33325 4.00016H4.66659V4.66683C4.66659 4.84364 4.73682 5.01321 4.86185 5.13823C4.98687 5.26326 5.15644 5.3335 5.33325 5.3335C5.51006 5.3335 5.67963 5.26326 5.80466 5.13823C5.92968 5.01321 5.99992 4.84364 5.99992 4.66683V4.00016H9.99992V4.66683C9.99992 4.84364 10.0702 5.01321 10.1952 5.13823C10.3202 5.26326 10.4898 5.3335 10.6666 5.3335C10.8434 5.3335 11.013 5.26326 11.138 5.13823C11.263 5.01321 11.3333 4.84364 11.3333 4.66683V4.00016H12.6666C12.8434 4.00016 13.013 4.0704 13.138 4.19543C13.263 4.32045 13.3333 4.49002 13.3333 4.66683V6.66683Z"
      fill="#4B4B4B"
    />
  </svg>
)

export const ClockIcon = (props: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.75 8C2.75 5.10051 5.10051 2.75 8 2.75C10.8995 2.75 13.25 5.10051 13.25 8C13.25 10.8995 10.8995 13.25 8 13.25C5.10051 13.25 2.75 10.8995 2.75 8ZM8 1.25C4.27208 1.25 1.25 4.27208 1.25 8C1.25 11.7279 4.27208 14.75 8 14.75C11.7279 14.75 14.75 11.7279 14.75 8C14.75 4.27208 11.7279 1.25 8 1.25ZM8.75 4.3999C8.75 3.98569 8.41421 3.6499 8 3.6499C7.58579 3.6499 7.25 3.98569 7.25 4.3999V8.10326C7.25011 8.62036 7.45568 9.11631 7.82135 9.48191L9.26967 10.9302C9.56256 11.2231 10.0374 11.2231 10.3303 10.9302C10.6232 10.6373 10.6232 10.1625 10.3303 9.86957L8.88185 8.42109C8.79749 8.33676 8.75007 8.22222 8.75 8.10294V4.3999Z"
      fill="#4B4B4B"
    />
  </svg>
)

export const BlindOnIcon = (props: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.9685 8.0003C13.8954 10.8818 10.9445 12.8079 7.99998 12.8079C5.05546 12.8079 2.10454 10.8818 1.03147 8.0003C2.10454 5.11877 5.05546 3.19268 7.99998 3.19268C10.9445 3.19268 13.8954 5.11877 14.9685 8.0003Z"
      stroke="#4B4B4B"
      strokeWidth="1.4"
    />
    <circle cx="7.99986" cy="8.00035" r="2.43121" stroke="#4B4B4B" strokeWidth="1.4" />
  </svg>
)

export const BlindOffIcon = (props: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8242 1.17574C15.078 1.42958 15.078 1.84114 14.8242 2.09497L2.09448 14.8245C1.84064 15.0783 1.42908 15.0783 1.17524 14.8245C0.921401 14.5706 0.921405 14.1591 1.17525 13.9052L13.905 1.17573C14.1588 0.92189 14.5704 0.921893 14.8242 1.17574Z"
      fill="#4B4B4B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8085 4.09762C11.4068 3.07763 9.70325 2.49219 7.99974 2.49219C4.69516 2.49219 1.39059 4.69524 0.289062 7.99981C0.96704 10.0337 2.47959 11.6504 4.31305 12.5929L5.36309 11.5429C3.79902 10.8609 2.46159 9.60284 1.7843 7.99981C2.82369 5.53978 5.41771 3.89219 7.99974 3.89219C9.33713 3.89219 10.6777 4.33421 11.8039 5.10221L12.8085 4.09762ZM13.8007 4.94392L12.8103 5.93423C13.4013 6.52367 13.8861 7.22103 14.2152 7.99981C13.1758 10.4598 10.5818 12.1074 7.99974 12.1074C7.585 12.1074 7.16995 12.0649 6.76109 11.9834L5.61407 13.1304C6.38799 13.3764 7.19386 13.5074 7.99974 13.5074C11.3043 13.5074 14.6089 11.3044 15.7104 7.99981C15.319 6.82564 14.6495 5.79054 13.8007 4.94392ZM7.63455 11.1099C7.75454 11.1239 7.87661 11.1311 8.00035 11.1311C9.72966 11.1311 11.1316 9.72917 11.1316 7.99986C11.1316 7.87614 11.1244 7.75409 11.1104 7.63411L7.63455 11.1099ZM10.6208 6.28526C10.0615 5.43212 9.0967 4.86865 8.00035 4.86865C6.27103 4.86865 4.86914 6.27054 4.86914 7.99986C4.86914 9.09619 5.43259 10.0609 6.2857 10.6203L7.31563 9.59038C6.70005 9.32502 6.26914 8.71276 6.26914 7.99986C6.26914 7.04374 7.04423 6.26865 8.00035 6.26865C8.71326 6.26865 9.32553 6.69958 9.59088 7.31517L10.6208 6.28526Z"
      fill="#4B4B4B"
    />
  </svg>
)

export const BlindIcon = ({ reveal, ...props }: BlindIconProps) =>
  reveal ? <BlindOnIcon {...props} /> : <BlindOffIcon {...props} />

import { ErrorHead } from '@/components/common/commonHead'
import { loggingCoupleGuestListUpPageView } from '@/components/common/googleTagManager'
import { ErrorLayout } from '@/components/couple/cs_guest_list_up/ErrorLayout'
import { styles } from '@/components/cs/errorLayout'
import { useGuestListUpCoupleUserContext } from '@/context/cs_guest_list_up/session'
import React, { useEffect } from 'react'

type OtherErrorProps = {
  title: string
  sub_title: string
  original_screen_name: string
  action?: Action
}

type Action = {
  action_label: string
  action_ref: string
}

export default function OtherError({
  title,
  sub_title,
  original_screen_name,
  action,
}: OtherErrorProps) {
  const { user } = useGuestListUpCoupleUserContext()
  useEffect(() => {
    loggingCoupleGuestListUpPageView({
      original_screen_name,
      guestlist_cs_userid: user?.coupleUUID,
    })
  }, [])
  return (
    <ErrorLayout>
      <ErrorHead />
      <h1 css={[styles.head, styles.breakable_text]}>{title}</h1>
      <p css={[styles.text, styles.breakable_text]}>{sub_title}</p>
      {action && (
        // ErrorContextなどの状態破棄のためwindow.location.assignを利用
        <button css={styles.button} onClick={() => window.location.assign(action.action_ref)}>
          {action.action_label}
        </button>
      )}
    </ErrorLayout>
  )
}

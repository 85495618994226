/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ClientAnalyticsDataResponse } from './client-analytics-data-response'

/**
 *
 * @export
 * @interface CustomerSummary
 */
export interface CustomerSummary {
  /**
   *
   * @type {number}
   * @memberof CustomerSummary
   */
  amsId: number
  /**
   *
   * @type {string}
   * @memberof CustomerSummary
   */
  partyHoldDate: string
  /**
   *
   * @type {string}
   * @memberof CustomerSummary
   */
  shinroLastName?: string
  /**
   *
   * @type {string}
   * @memberof CustomerSummary
   */
  shinroFirstName?: string
  /**
   *
   * @type {string}
   * @memberof CustomerSummary
   */
  shinpuLastName?: string
  /**
   *
   * @type {string}
   * @memberof CustomerSummary
   */
  shinpuFirstName?: string
  /**
   *
   * @type {ClientAnalyticsDataResponse}
   * @memberof CustomerSummary
   */
  analytics: ClientAnalyticsDataResponse
  /**
   *
   * @type {string}
   * @memberof CustomerSummary
   */
  guestPaymentSettingStatus: CustomerSummaryGuestPaymentSettingStatus
  /**
   *
   * @type {string}
   * @memberof CustomerSummary
   */
  partyPaymentSetting: CustomerSummaryPartyPaymentSetting
}

/**
 * @export
 * @enum {string}
 */
export enum CustomerSummaryGuestPaymentSettingStatus {
  noSettings = 'NO_SETTINGS',
  notUsing = 'NOT_USING',
  using = 'USING',
}
/**
 * @export
 * @enum {string}
 */
export enum CustomerSummaryPartyPaymentSetting {
  goshugi = 'GOSHUGI',
  kaihi = 'KAIHI',
  noSetting = 'NO_SETTING',
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface InvitationForDisplayResponse
 */
export interface InvitationForDisplayResponse {
  /**
   *
   * @type {string}
   * @memberof InvitationForDisplayResponse
   */
  invitationUUID: string
  /**
   *
   * @type {string}
   * @memberof InvitationForDisplayResponse
   */
  invitationName: string
  /**
   *
   * @type {string}
   * @memberof InvitationForDisplayResponse
   */
  invitationStatus: InvitationForDisplayResponseInvitationStatus
}

/**
 * @export
 * @enum {string}
 */
export enum InvitationForDisplayResponseInvitationStatus {
  draft = 'DRAFT',
  publishedPublic = 'PUBLISHED_PUBLIC',
  publishedNotPublic = 'PUBLISHED_NOT_PUBLIC',
  publishedEndOfPublication = 'PUBLISHED_END_OF_PUBLICATION',
  suspended = 'SUSPENDED',
}

import { useLoading } from '@/context/loading'
import { CoupleAuthControllerApi, LogoutResponse } from '@/data/gen'
import { defaultConfiguration, RPCError, invoke } from '@/data/rpc'
import { useCallback, useState } from 'react'

export const useLogout = () => {
  const [success, setSuccess] = useState<LogoutResponse | null>()
  const [error, setError] = useState<RPCError | null>()
  const { loading, setLoading } = useLoading()

  const logout = useCallback(async () => {
    setLoading(true)
    const coupleAuthApi = new CoupleAuthControllerApi(defaultConfiguration())
    await invoke(() => coupleAuthApi.coupleLogout()).then(async (response) => {
      if (response.success) {
        setSuccess(response.success)
      } else {
        setError(response.error)
      }
      setLoading(false)
    })
  }, [])

  return {
    logout,
    success,
    error,
    loading,
  }
}

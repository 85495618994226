/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CoupleAnalyticsDataResponse } from './couple-analytics-data-response'
import { InvitationCustomResponseSetting } from './invitation-custom-response-setting'

/**
 *
 * @export
 * @interface GetInvitationResponseSettingResponse
 */
export interface GetInvitationResponseSettingResponse {
  /**
   *
   * @type {string}
   * @memberof GetInvitationResponseSettingResponse
   */
  whichGuest: GetInvitationResponseSettingResponseWhichGuest
  /**
   *
   * @type {string}
   * @memberof GetInvitationResponseSettingResponse
   */
  sex: GetInvitationResponseSettingResponseSex
  /**
   *
   * @type {string}
   * @memberof GetInvitationResponseSettingResponse
   */
  address: GetInvitationResponseSettingResponseAddress
  /**
   *
   * @type {string}
   * @memberof GetInvitationResponseSettingResponse
   */
  tel: GetInvitationResponseSettingResponseTel
  /**
   *
   * @type {string}
   * @memberof GetInvitationResponseSettingResponse
   */
  message: GetInvitationResponseSettingResponseMessage
  /**
   *
   * @type {boolean}
   * @memberof GetInvitationResponseSettingResponse
   */
  canAddGuestCompanion: boolean
  /**
   *
   * @type {string}
   * @memberof GetInvitationResponseSettingResponse
   */
  partyHoldDate: string
  /**
   *
   * @type {boolean}
   * @memberof GetInvitationResponseSettingResponse
   */
  isPublished: boolean
  /**
   *
   * @type {boolean}
   * @memberof GetInvitationResponseSettingResponse
   */
  isAfterDeadline: boolean
  /**
   *
   * @type {boolean}
   * @memberof GetInvitationResponseSettingResponse
   */
  useOnlinePayment: boolean
  /**
   *
   * @type {CoupleAnalyticsDataResponse}
   * @memberof GetInvitationResponseSettingResponse
   */
  coupleAnalyticsDataResponse: CoupleAnalyticsDataResponse
  /**
   *
   * @type {InvitationCustomResponseSetting}
   * @memberof GetInvitationResponseSettingResponse
   */
  invitationCustomResponseSetting: InvitationCustomResponseSetting
  /**
   *
   * @type {string}
   * @memberof GetInvitationResponseSettingResponse
   */
  prePartyImageShareSetting: GetInvitationResponseSettingResponsePrePartyImageShareSetting
  /**
   *
   * @type {string}
   * @memberof GetInvitationResponseSettingResponse
   */
  prePartyImageShareGuidance?: string
}

/**
 * @export
 * @enum {string}
 */
export enum GetInvitationResponseSettingResponseWhichGuest {
  hidden = 'HIDDEN',
  required = 'REQUIRED',
}
/**
 * @export
 * @enum {string}
 */
export enum GetInvitationResponseSettingResponseSex {
  hidden = 'HIDDEN',
  required = 'REQUIRED',
}
/**
 * @export
 * @enum {string}
 */
export enum GetInvitationResponseSettingResponseAddress {
  hidden = 'HIDDEN',
  required = 'REQUIRED',
}
/**
 * @export
 * @enum {string}
 */
export enum GetInvitationResponseSettingResponseTel {
  hidden = 'HIDDEN',
  required = 'REQUIRED',
}
/**
 * @export
 * @enum {string}
 */
export enum GetInvitationResponseSettingResponseMessage {
  hidden = 'HIDDEN',
  required = 'REQUIRED',
}
/**
 * @export
 * @enum {string}
 */
export enum GetInvitationResponseSettingResponsePrePartyImageShareSetting {
  using = 'USING',
  notUsing = 'NOT_USING',
  toBeSet = 'TO_BE_SET',
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CustomResponseItemResponse } from './custom-response-item-response'
import { ImageResponse } from './image-response'
import { InvitationResponseSettingResponse } from './invitation-response-setting-response'
import { TimeInformationResponse } from './time-information-response'

/**
 *
 * @export
 * @interface GetInvitationDataResponse
 */
export interface GetInvitationDataResponse {
  /**
   *
   * @type {string}
   * @memberof GetInvitationDataResponse
   */
  designTheme: GetInvitationDataResponseDesignTheme
  /**
   *
   * @type {string}
   * @memberof GetInvitationDataResponse
   */
  invitationType: GetInvitationDataResponseInvitationType
  /**
   *
   * @type {boolean}
   * @memberof GetInvitationDataResponse
   */
  isResponsible: boolean
  /**
   *
   * @type {boolean}
   * @memberof GetInvitationDataResponse
   */
  isUsingPayment: boolean
  /**
   *
   * @type {ImageResponse}
   * @memberof GetInvitationDataResponse
   */
  mainImage?: ImageResponse
  /**
   *
   * @type {ImageResponse}
   * @memberof GetInvitationDataResponse
   */
  middleImage?: ImageResponse
  /**
   *
   * @type {string}
   * @memberof GetInvitationDataResponse
   */
  guidanceText: string
  /**
   *
   * @type {string}
   * @memberof GetInvitationDataResponse
   */
  sender?: string
  /**
   *
   * @type {string}
   * @memberof GetInvitationDataResponse
   */
  holdDate: string
  /**
   *
   * @type {string}
   * @memberof GetInvitationDataResponse
   */
  responseDeadline?: string
  /**
   *
   * @type {Array<TimeInformationResponse>}
   * @memberof GetInvitationDataResponse
   */
  timeInformation?: Array<TimeInformationResponse>
  /**
   *
   * @type {string}
   * @memberof GetInvitationDataResponse
   */
  customTimeInformation?: string
  /**
   *
   * @type {string}
   * @memberof GetInvitationDataResponse
   */
  clientName: string
  /**
   *
   * @type {string}
   * @memberof GetInvitationDataResponse
   */
  clientDetail?: string
  /**
   *
   * @type {string}
   * @memberof GetInvitationDataResponse
   */
  clientURL?: string
  /**
   *
   * @type {string}
   * @memberof GetInvitationDataResponse
   */
  clientTel?: string
  /**
   *
   * @type {string}
   * @memberof GetInvitationDataResponse
   */
  clientPostCode: string
  /**
   *
   * @type {string}
   * @memberof GetInvitationDataResponse
   */
  clientAddress: string
  /**
   *
   * @type {string}
   * @memberof GetInvitationDataResponse
   */
  clientAccessInformation?: string
  /**
   *
   * @type {string}
   * @memberof GetInvitationDataResponse
   */
  message?: string
  /**
   *
   * @type {InvitationResponseSettingResponse}
   * @memberof GetInvitationDataResponse
   */
  responseSetting: InvitationResponseSettingResponse
  /**
   *
   * @type {Array<CustomResponseItemResponse>}
   * @memberof GetInvitationDataResponse
   */
  customResponseItems: Array<CustomResponseItemResponse>
  /**
   *
   * @type {string}
   * @memberof GetInvitationDataResponse
   */
  partyPaymentType: GetInvitationDataResponsePartyPaymentType
  /**
   *
   * @type {number}
   * @memberof GetInvitationDataResponse
   */
  kaihiAmount?: number
  /**
   *
   * @type {string}
   * @memberof GetInvitationDataResponse
   */
  kaihiSupplementaryText?: string
  /**
   *
   * @type {object}
   * @memberof GetInvitationDataResponse
   */
  analytics: object
}

/**
 * @export
 * @enum {string}
 */
export enum GetInvitationDataResponseDesignTheme {
  formalAGold = 'FORMAL_A_GOLD',
  formalANavy = 'FORMAL_A_NAVY',
  formalARed = 'FORMAL_A_RED',
  formalAJapanwhite = 'FORMAL_A_JAPANWHITE',
  formalAGoldA = 'FORMAL_A_GOLD_a',
  formalANavyA = 'FORMAL_A_NAVY_a',
  simpleAPink = 'SIMPLE_A_PINK',
  simpleABlue = 'SIMPLE_A_BLUE',
  simpleAGray = 'SIMPLE_A_GRAY',
  naturalBGreen = 'NATURAL_B_GREEN',
  naturalBGreenA = 'NATURAL_B_GREEN_a',
  naturalBPink = 'NATURAL_B_PINK',
  naturalBPinkA = 'NATURAL_B_PINK_a',
  naturalBBrown = 'NATURAL_B_BROWN',
  naturalBBrownA = 'NATURAL_B_BROWN_a',
  eSimplenavygold = 'E_SIMPLENAVYGOLD',
  eChicblack = 'E_CHICBLACK',
  eFreshblue = 'E_FRESHBLUE',
  fSimplewhite = 'F_SIMPLEWHITE',
  fFemininebeige = 'F_FEMININEBEIGE',
  fClassicGreen = 'F_CLASSIC_GREEN',
  gArtisticbeige = 'G_ARTISTICBEIGE',
  gLadyblack = 'G_LADYBLACK',
  gChicGray = 'G_CHIC_GRAY',
  hGorgeousgold = 'H_GORGEOUSGOLD',
  hGorgeousgoldA = 'H_GORGEOUSGOLD_a',
  iFlowerwhite = 'I_FLOWERWHITE',
  iCoolmonotone = 'I_COOLMONOTONE',
  jFlowerBlue = 'J_FLOWER_BLUE',
  jFlowerOrange = 'J_FLOWER_ORANGE',
  kFreshgreen = 'K_FRESHGREEN',
  mMinimalYellow = 'M_MINIMAL_YELLOW',
  mMinimalRed = 'M_MINIMAL_RED',
  nJapanDaidai = 'N_JAPAN_DAIDAI',
  nJapanKurenai = 'N_JAPAN_KURENAI',
  nJapanShirotae = 'N_JAPAN_SHIROTAE',
  lClassicNavy = 'L_CLASSIC_NAVY',
  lClassicBold = 'L_CLASSIC_BOLD',
  oClassicLightgreen = 'O_CLASSIC_LIGHTGREEN',
  oClassicBlue = 'O_CLASSIC_BLUE',
  pSimpleWhite = 'P_SIMPLE_WHITE',
  qResortBlue = 'Q_RESORT_BLUE',
  rFeminineSoftpink = 'R_FEMININE_SOFTPINK',
  sCasualBrown = 'S_CASUAL_BROWN',
  sCasualNavy = 'S_CASUAL_NAVY',
  tZexyPink = 'T_ZEXY_PINK',
}
/**
 * @export
 * @enum {string}
 */
export enum GetInvitationDataResponseInvitationType {
  weddingReception = 'WEDDING_RECEPTION',
  wedding = 'WEDDING',
  reception = 'RECEPTION',
  casual = 'CASUAL',
  weddingPartyMeal = 'WEDDING_PARTY_MEAL',
  mealParty = 'MEAL_PARTY',
  custom = 'CUSTOM',
}
/**
 * @export
 * @enum {string}
 */
export enum GetInvitationDataResponsePartyPaymentType {
  goshugi = 'GOSHUGI',
  kaihi = 'KAIHI',
}

import { colors } from '@/styles/colors'
import { css } from '@emotion/react'
import CircularProgress from '@mui/material/CircularProgress'

export default function Loading() {
  return (
    <div css={styles.loadingWrap}>
      <CircularProgress
        variant="determinate"
        sx={{ color: '#E5E5E5' }}
        size={58}
        thickness={6}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: colors.COLOR_EA6077,
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
        }}
        size={58}
        thickness={6}
      />
    </div>
  )
}

const styles = {
  loadingWrap: css`
    position: relative;
    display: flex;
    height: fit-content;
  `,
}

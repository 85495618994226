/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { GetGuestPaymentTokushohouRequestForGuest } from '../model'
// @ts-ignore
import { GuestPaymentTokushohou } from '../model'
/**
 * GuestTokushohouControllerApi - axios parameter creator
 * @export
 */
export const GuestTokushohouControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {GetGuestPaymentTokushohouRequestForGuest} getGuestPaymentTokushohouRequestForGuest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGuestPaymentTokushohouForGuest: async (
      getGuestPaymentTokushohouRequestForGuest: GetGuestPaymentTokushohouRequestForGuest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getGuestPaymentTokushohouRequestForGuest' is not null or undefined
      assertParamExists(
        'getGuestPaymentTokushohouForGuest',
        'getGuestPaymentTokushohouRequestForGuest',
        getGuestPaymentTokushohouRequestForGuest,
      )
      const localVarPath = `/guest/api/getGuestPaymentTokushohouForGuest`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getGuestPaymentTokushohouRequestForGuest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * GuestTokushohouControllerApi - functional programming interface
 * @export
 */
export const GuestTokushohouControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GuestTokushohouControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {GetGuestPaymentTokushohouRequestForGuest} getGuestPaymentTokushohouRequestForGuest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGuestPaymentTokushohouForGuest(
      getGuestPaymentTokushohouRequestForGuest: GetGuestPaymentTokushohouRequestForGuest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuestPaymentTokushohou>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGuestPaymentTokushohouForGuest(
        getGuestPaymentTokushohouRequestForGuest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * GuestTokushohouControllerApi - factory interface
 * @export
 */
export const GuestTokushohouControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GuestTokushohouControllerApiFp(configuration)
  return {
    /**
     *
     * @param {GetGuestPaymentTokushohouRequestForGuest} getGuestPaymentTokushohouRequestForGuest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGuestPaymentTokushohouForGuest(
      getGuestPaymentTokushohouRequestForGuest: GetGuestPaymentTokushohouRequestForGuest,
      options?: any,
    ): AxiosPromise<GuestPaymentTokushohou> {
      return localVarFp
        .getGuestPaymentTokushohouForGuest(getGuestPaymentTokushohouRequestForGuest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * GuestTokushohouControllerApi - object-oriented interface
 * @export
 * @class GuestTokushohouControllerApi
 * @extends {BaseAPI}
 */
export class GuestTokushohouControllerApi extends BaseAPI {
  /**
   *
   * @param {GetGuestPaymentTokushohouRequestForGuest} getGuestPaymentTokushohouRequestForGuest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestTokushohouControllerApi
   */
  public getGuestPaymentTokushohouForGuest(
    getGuestPaymentTokushohouRequestForGuest: GetGuestPaymentTokushohouRequestForGuest,
    options?: AxiosRequestConfig,
  ) {
    return GuestTokushohouControllerApiFp(this.configuration)
      .getGuestPaymentTokushohouForGuest(getGuestPaymentTokushohouRequestForGuest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

import { css, SerializedStyles } from '@emotion/react'

export const breakpoints: { [device: string]: number } = {
  sp: 959,
  /**
   * 最小デザイン(320px)向けの設定。
   * iPhoneSE が375pxなので370を breakpoint に指定
   * */
  smallest: 370,
}

export const fontStyle = {
  ja: {
    fontFamily: [
      '"Hiragino Kaku Gothic ProN"', // for Mac/iOS
      '"Hiragino Sans"',
      '"Noto sans JP"', // for Android
      '"BIZ UDPGothic"', // for Win
      '"メイリオ"',
      '"Meiryo"',
      '"游ゴシック"',
      '"Yu Gothic"',
      'sans-serif',
    ].join(','),
  },
  alphanumeric: {
    fontFamily: ['"Roboto"', 'sans-serif'].join(','),
  },
}

/**
 * カップルの z-index は mui で使用されている値を基準に設定する。 <br>
 * see https://mui.com/material-ui/customization/z-index/ <br>
 * <br>
 * 各変数名は mui の名称を流用している。 <br>
 * どのコンポーネントでどの値が使われているかは mui で検索のこと <br>
 * 例： <br>
 * https://mui.com/material-ui/react-floating-action-button/#basic-fab <br>
 * https://mui.com/material-ui/react-app-bar/ <br>
 */
export const zIndex = {
  /** メインコンテンツ内で手前に出したいものなど */
  mobileStepper: 1000,
  /** submitボタンなど */
  fab: 1050,
  /** submitボタンなど */
  speedDial: 1050,
  /** ヘッダーなど */
  appBar: 1100,
  /** サイドメニューなど */
  drawer: 1200,
  /** モーダルやダイアログなど */
  modal: 1300,
  /** 通知用トーストなど */
  snackbar: 1400,
  /** hover時のdescriptionなど */
  tooltip: 1500,
}

export const sp = (style: SerializedStyles | Array<SerializedStyles>) =>
  css`
    @media (max-width: ${breakpoints.sp}px) {
      ${style}
    }
  `

export const smallest = (style: SerializedStyles) =>
  css`
    @media (max-width: ${breakpoints.smallest}px) {
      ${style}
    }
  `

import { colors } from '@/styles/colors'
import { css } from '@emotion/react'
import { Modal } from '@mui/material'

type EntryModalWrapperProps = {
  open: boolean
  onClose: () => void
  children: React.ReactNode
}

// この wrapper は page から直接 children を渡して利用せずにモーダルコンポーネントを作成する際のベース実装として利用することを想定している
export function EntryModalWrapper({ open, onClose, children }: EntryModalWrapperProps) {
  return (
    <Modal open={open} onClose={onClose}>
      <div css={styles.modalContainer}>
        <div css={styles.modalCard}>{children}</div>
      </div>
    </Modal>
  )
}

const styles = {
  modalContainer: css`
    display: grid;
    place-items: center;
    height: 100vh;
    pointer-events: none;
  `,
  modalCard: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 440px;
    padding: 48px;
    text-align: center;
    white-space: pre-wrap;
    pointer-events: auto;
    background-color: ${colors.COLOR_FFFFFF};
    border-radius: 10px;
  `,
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface CustomerGuestPaymentInfo
 */
export interface CustomerGuestPaymentInfo {
  /**
   *
   * @type {string}
   * @memberof CustomerGuestPaymentInfo
   */
  responseId: string
  /**
   *
   * @type {string}
   * @memberof CustomerGuestPaymentInfo
   */
  trackingId: string
  /**
   *
   * @type {string}
   * @memberof CustomerGuestPaymentInfo
   */
  paymentReceivedAt: string
  /**
   *
   * @type {number}
   * @memberof CustomerGuestPaymentInfo
   */
  paymentAmount: number
  /**
   *
   * @type {string}
   * @memberof CustomerGuestPaymentInfo
   */
  status: CustomerGuestPaymentInfoStatus
  /**
   *
   * @type {string}
   * @memberof CustomerGuestPaymentInfo
   */
  cancelRequestedAt?: string
}

/**
 * @export
 * @enum {string}
 */
export enum CustomerGuestPaymentInfoStatus {
  complete = 'COMPLETE',
  cancelled = 'CANCELLED',
}

import { Config } from '@/context/config'
import { isDevelopment } from '@/utils/common/featureToggle'

/** クライアント環境と申込フォームで使用する規約URL */
const CL_TERMS_URL = 'https://cdn.p.recruit.co.jp/terms/xys-t-1003/index.html'
/** リクルートHP */
const RECRUIT_URL = 'https://www.recruit.co.jp/'
/** クライアント向け プライバシーポリシー */
const CL_PRIVACY_POLICY = 'https://cdn.p.recruit.co.jp/terms/clu-t-1001/index.html'

export const CS_EXTERNAL_WEB_URL = (config: Config) => ({
  /**
   * よくある質問・最新のお知らせ
   * (このリンクが変更になる場合は operation/maintenance/index.html のリンクも変更してください。)
   * */
  FAQ: 'https://help.zexy.net/online',
  /** 利用規約 */
  SERVICE_TERMS: 'https://cdn.p.recruit.co.jp/terms/xys-t-1002/index.html',
  /** 会員規約 */
  MEMBERSHIP_AGREEMENT: 'https://cdn.p.recruit.co.jp/terms/cap-t-1001/index.html',
  /** プライバシーポリシー */
  PRIVACY_POLICY: 'https://cdn.p.recruit.co.jp/terms/cmn-t-1001/index.html',
  /** 特定商取引法に基づく表示 */
  SPECIFIED_COMMERCIAL_TRANSACTION: 'https://cdn.p.recruit.co.jp/terms/xys-t-1005/index.html',
  /** 使い方ガイド */
  USER_GUIDE: 'https://introduction.online.zexy.net/guide',
  /** ゲストリストアップ使い方ガイド */
  GUEST_LIST_UP_USER_GUIDE: 'https://introduction.online.zexy.net/guide/guestlistup',
  /** リクルートHP */
  RECRUIT: RECRUIT_URL,
  /** ゲスト一覧の使い方ガイド */
  GUEST_LIST_GUIDE_URL: config.csGuestListGuideUrl,
})

export const CL_EXTERNAL_WEB_URL = (config: Config) => ({
  /** 利用規約 */
  TERMS_URL: CL_TERMS_URL,
  /** プライバシーポリシー */
  PRIVACY_POLICY: CL_PRIVACY_POLICY,
  /** Zebraへのリンク */
  ZEBRA_URL: config.clZebraUrl,
  /** 利用マニュアルへのリンク */
  USER_MANUAL: config.clUserManual,
  /** 2023年6月16日以降から施行される電気通信事業法の改定に基づき Cookie などの情報の外部送信先一覧やオプトアウトリンクを表示するリンク * */
  EXTERNAL_DATA_DESTINATION_URL: 'https://www.recruit.co.jp/privacy/settings/cookie/#sec02',
})

export const GUEST_EXTERNAL_WEB_URL = {
  /** 利用規約 */
  TERMS_URL: 'https://cdn.p.recruit.co.jp/terms/xys-t-1001/index.html',
  /** プライバシーポリシー */
  PRIVACY_POLICY: 'https://cdn.p.recruit.co.jp/terms/cmn-t-1001/index.html',
  /** よくある質問・お問い合わせ */
  FAQ: 'https://help.zexy.net/online',
  /** オンライン招待状へのお問合せ方法 */
  INQUIRY: 'https://help.zexy.net/online/s/article/000024742',
}

export const ENTRY_EXTERNAL_WEB_URL = (config: Config) => ({
  /** 利用規約 */
  TERMS_URL: CL_TERMS_URL,
  /** Airペイオンライン加盟店規約 */
  AIR_PAY_MERCHANT_AGREEMENT: 'https://cdn.p.recruit.co.jp/terms/xys-t-1010/index.html',
  /** 結婚領域特約 */
  MARRIAGE_AND_FAMILY_CONTRACT: 'https://cdn.p.recruit.co.jp/terms/xys-t-1011/index.html',
  /** JCB加盟店規約 */
  JCB_MERCHANT_AGREEMENT: 'https://cdn.p.recruit.co.jp/terms/xys-t-1008/index.html',
  /** JCB本人認証サービス特約 */
  JCB_SERVICE_AGREEMENT: 'https://cdn.p.recruit.co.jp/terms/xys-t-1009/index.html',
  /** プライバシーポリシー */
  PRIVACY_POLICY: CL_PRIVACY_POLICY,
  /** リクルートHP */
  RECRUIT: RECRUIT_URL,
  /** サポートデスク */
  SUPPORT_DESK: 'https://www.zexysupport.net/inquiry/s/',
  /** 申込ガイドの「お申し込みに必要な情報詳細」 */
  ENTRY_LP_GUIDE_URL: isDevelopment(config.featureToggle)
    ? 'https://preview.studio.site/live/moWvd9oJq6/entry/guide'
    : 'https://partner.online.zexy.net/entry/guide',
})

export const COUPLE_GUEST_LIST_UP_EXTERNAL_WEB_URL = (config: Config) => ({
  /** 利用規約 */
  SERVICE_TERMS: config.coupleGuestListUpServiceTermUrl,
})

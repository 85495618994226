import {
  KarteCoupleActionOption,
  KarteCoupleAction,
} from '@/components/common/karte/karteCoupleConst'
import { useConfig } from '@/context/config'
import { AssertNeverError } from '@/utils/common/assertions'
import { Feature } from '@/utils/common/featureToggle'

const getKarteAPIKey = (featureToggle: Feature) => {
  switch (featureToggle) {
    case 'development':
    case 'demo':
    case 'staging':
      return '8f7a53da22a7cd636827121ee108946d'
    case 'production':
      return '60e94e9949b474219361f450beabbe8d'
    default:
      throw new AssertNeverError(featureToggle)
  }
}

export const KarteMeasurementTag = () => {
  const { config } = useConfig()
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html:
            '!function(n){var o=window[n]=function(){var n=[].slice.call(arguments);return o.x?o.x.apply(0,n):o.q.push(n)};o.q=[],o.i=Date.now(),o.allow=function(){o.o="allow"},o.deny=function(){o.o="deny"}}("krt")',
        }}
      />
      <script
        async
        src={`https://cdn-edge.karte.io/${getKarteAPIKey(config.featureToggle)}/edge.js`}
      />
    </>
  )
}

/*
 * krt コマンドの仕様書は公開されていないため、
 * KARTE 各機能の仕様書を参考に類推した型定義を実装している。
 * 今後の利用の中で不都合が出てきたら拡張する運用とする。
 */
type krtFunctionType = (action: string, eventName: string, option?: any) => void
/* KarteMeasurementTag の中で導入される krt コマンドを明示的に export する */
export const krt: krtFunctionType =
  typeof window === 'undefined'
    ? (..._) => undefined
    : // TODO: 本番用計測タグが実装された際は、仮置きの undefined を返却するメソッドを削除する
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.krt || ((..._) => undefined)

type krtPageViewProp = {
  screen_name: string
  options?: { [param_name: string]: any }
}
/**
 * KARTE のページ閲覧イベントを発火させるための関数
 * @param screen_name 閲覧した画面名を送付する
 */
export const krtPageView = ({ screen_name, options = {} }: krtPageViewProp) =>
  krt('send', 'view', {
    screen_name,
    ...options,
  })

type krtIdentifyUserProp = {
  user_id: string
}
/**
 * KARTE のユーザ情報登録イベントを発火させる関数
 * @param user_id ユーザを特定する ID ( ex: カップル ID, ゲスト回答 ID, zebraID )
 */
export const krtIdentifyUser = ({ user_id }: krtIdentifyUserProp) =>
  krt('send', 'identify', { user_id })

type krtGuestActionProp = {
  action_name: string
  options?: { [option_name: string]: any }
}
/**
 * KARTE に対してゲストのアクション情報を送付する関数
 */
export const krtGuestAction = ({ action_name, options = {} }: krtGuestActionProp) =>
  krt('send', 'guest_action', {
    action_name,
    ...options,
  })

type krtCoupleActionProp = {
  action_name: KarteCoupleAction
  options?: KarteCoupleActionOption
}
/**
 * KARTE に対してカップルのアクション情報を送付する関数
 */
export const krtCoupleAction = ({ action_name, options }: krtCoupleActionProp) =>
  krt('send', 'couple_action', {
    action_name,
    ...options,
  })

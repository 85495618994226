import Layout from '@/components/cs/layout'
import { cs_colors } from '@/styles/cs/colors'
import { breakpoints, sp } from '@/styles/cs/styleConstants'
import { CsTextStyles } from '@/styles/cs/textStyles'
import { css } from '@emotion/react'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
export function ErrorLayout({ children, ...props }) {
  return (
    <Layout
      headerCondition={{ headerType: 'DefaultHeader' }}
      shouldAuthentication={false}
      {...props}
    >
      <div css={styles.main}>
        <div css={styles.card}>{children}</div>
      </div>
    </Layout>
  )
}

// TODO: XYSAAS-6217 この styles を couple common なものとして扱えるようにする( components/couple/common に移動する)
export const styles = {
  main: css`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
  `,
  card: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 742px;
    min-height: 240px;
    padding: 40px 15px;
    background-color: ${cs_colors.common.white};
    border: 3px solid ${cs_colors.border.lightGray_line};
    border-radius: 10px;
    @media (max-width: ${breakpoints.sp}px) {
      width: 87vw;
      min-width: 300px;
      max-width: 707px;
    }
  `,
  head: [
    css`
      text-align: center;
    `,
    CsTextStyles.heading.bold.s20px.h150(cs_colors.textAndIcon.black_text),
    sp(CsTextStyles.heading.bold.s18px.h150(cs_colors.textAndIcon.black_text)),
  ],
  text: [
    css`
      margin-top: 16px;
      text-align: center;
    `,
    CsTextStyles.body.regular.s14px.h150(cs_colors.textAndIcon.black_text),
    sp(CsTextStyles.body.regular.s12px.h150(cs_colors.textAndIcon.black_text)),
  ],
  button: [
    css`
      width: 285px;
      height: 44px;
      padding: 15px;
      margin: 32px auto 0;
      cursor: pointer;
      background-color: ${cs_colors.common.white};
      border: 1px solid ${cs_colors.common.zexyPink};
      border-radius: 50px;
      backdrop-filter: blur(50px);
    `,
    CsTextStyles.bodyWide.bold.s14px.h100(cs_colors.common.zexyPink),
  ],
  link: [
    CsTextStyles.body.regular.s14px.h150_link(cs_colors.common.zexyPink),
    sp(CsTextStyles.body.regular.s12px.h150_link(cs_colors.common.zexyPink)),
  ],
  breakable_text: css`
    word-wrap: break-word;
    white-space: pre-wrap;
  `,
}

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { CheckCoupleSessionResponse } from '../model'
// @ts-ignore
import { ErrorResponse } from '../model'
/**
 * CoupleSessionControllerApi - axios parameter creator
 * @export
 */
export const CoupleSessionControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkCoupleSession: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cs/api/session/checkCoupleSession`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CoupleSessionControllerApi - functional programming interface
 * @export
 */
export const CoupleSessionControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CoupleSessionControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkCoupleSession(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckCoupleSessionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkCoupleSession(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CoupleSessionControllerApi - factory interface
 * @export
 */
export const CoupleSessionControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CoupleSessionControllerApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkCoupleSession(options?: any): AxiosPromise<CheckCoupleSessionResponse> {
      return localVarFp.checkCoupleSession(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * CoupleSessionControllerApi - object-oriented interface
 * @export
 * @class CoupleSessionControllerApi
 * @extends {BaseAPI}
 */
export class CoupleSessionControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CoupleSessionControllerApi
   */
  public checkCoupleSession(options?: AxiosRequestConfig) {
    return CoupleSessionControllerApiFp(this.configuration)
      .checkCoupleSession(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

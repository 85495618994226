import { SimpleButton } from '@/components/cl/button'
import { EntryHead } from '@/components/common/commonHead'
import { Layout } from '@/components/entry/layout'
import { AllClientEntryCompleted } from '@/components/entry/selectClient/allClientEntryCompleted'
import { useConfig } from '@/context/config'
import { colors } from '@/styles/colors'
import { ENTRY_EXTERNAL_WEB_URL } from '@/utils/common/externalWebURL'
import { css } from '@emotion/react'

export const EntryAllClientAlreadyRegisteredError = () => {
  const { config } = useConfig()

  const onClickClose = () => {
    window.location.href = '/entry'
  }

  return (
    <>
      <EntryHead title="会場選択画面（全申込済）" />
      <Layout showEntryCloseModalOnLogout={false}>
        <div css={styles.main}>
          <div css={styles.container}>
            <AllClientEntryCompleted />
            <div css={styles.textBox}>
              <p css={styles.text}>
                AMS利用会場のみ本サービスをご利用いただけます。
                <br />
                申込をご希望の際は、
                <a
                  href={ENTRY_EXTERNAL_WEB_URL(config).SUPPORT_DESK}
                  css={styles.anchor}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ゼクシィサポートデスク
                </a>
                までお問い合わせください。
              </p>
            </div>
            <div css={styles.closeButtonWraper}>
              <SimpleButton css={styles.closeButton} onClick={onClickClose}>
                画面を閉じる
              </SimpleButton>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

const styles = {
  main: css`
    width: 100%;
  `,
  container: css`
    max-width: 960px;
    padding-top: 79px;
    margin: 0 auto;
  `,
  textBox: css`
    width: 100%;
    padding: 56px 80px;
    margin-top: 40px;
    text-align: center;
    background: ${colors.COLOR_FFFFFF};
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  `,
  text: css`
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: ${colors.COLOR_595959};
  `,
  anchor: css`
    color: ${colors.COLOR_3985D0};
    text-decoration: none;
  `,
  closeButtonWraper: css`
    margin-top: 80px;
    text-align: center;
  `,
  closeButton: css`
    width: 408px;
    height: 56px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 30px;
  `,
}

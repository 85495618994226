export const theme = {
  white: '#fff',
  baseWhite: '#fdfdfd',
  gray: '#c0c0c0',
  baseBlue: '#1687a7',
  baseRed: '#dc143c',
}

export const colors = {
  COLOR_0077E0: '#0077e0',
  COLOR_103ECD: '#103ecd',
  COLOR_248908: '#248908',
  COLOR_2591F0: '#2591f0',
  COLOR_2650AE: '#2650ae',
  COLOR_2B5C9F: '#2b5c9f',
  COLOR_2C2C2C: '#2c2c2c',
  COLOR_3066B0: '#3066b0',
  COLOR_3180C6: '#3180c6',
  COLOR_344671: '#344671',
  COLOR_3985D0: '#3985d0',
  COLOR_3A3C45: '#3a3c45',
  COLOR_3D72B9: '#3d72b9',
  COLOR_435B67: '#435b67',
  COLOR_4B4B4B: '#4b4b4b',
  COLOR_4F5365: '#4f5365',
  COLOR_555555: '#555555',
  COLOR_595959: '#595959',
  COLOR_5E73A2: '#5e73a2',
  COLOR_638EC9: '#638ec9',
  COLOR_66A6F0: '#66a6f0',
  COLOR_676A75: '#676a75',
  COLOR_6C605A: '#6c605a',
  COLOR_6C7EA8: '#6c7ea8',
  COLOR_6D6D6D: '#6d6d6d',
  COLOR_6F6F6F: '#6f6f6f',
  COLOR_798ECA: '#798eca',
  COLOR_7AADE8: '#7aade8',
  COLOR_838692: '#838692',
  COLOR_8D8D8D: '#8d8d8d',
  COLOR_979797: '#979797',
  COLOR_97999F: '#97999f',
  COLOR_999999: '#999999',
  COLOR_9C9C9C: '#9c9c9c',
  COLOR_A6A6A6: '#a6a6a6',
  COLOR_ABA675: '#aba675',
  COLOR_BBC1E1: '#bbc1e1',
  COLOR_C09C5E: '#c09c5e',
  COLOR_C0DAF1: '#c0daf1',
  COLOR_C21742: '#c21742',
  COLOR_C3C7D7: '#c3c7d7',
  COLOR_C5C5C7: '#c5c5c7',
  COLOR_CCCCCC: '#cccccc',
  COLOR_CDCDCD: '#cdcdcd',
  COLOR_CED2E3: '#ced2e3',
  COLOR_CFD2D9: '#cfd2d9',
  COLOR_D12853: '#d12853',
  COLOR_D2B88A: '#d2b88a',
  COLOR_D50000: '#d50000',
  COLOR_D60000: '#d60000',
  COLOR_D8D8D8: '#d8d8d8',
  COLOR_DDDDDD: '#dddddd',
  COLOR_E1E6F9: '#e1e6f9',
  COLOR_E32847: '#e32847',
  COLOR_E37709: '#e37709',
  COLOR_E37F00: '#e37f00',
  COLOR_E3E5EE: '#e3e5ee',
  COLOR_E4610C: '#e4610c',
  COLOR_E5E5E5: '#e5e5e5',
  COLOR_E64A45: '#e64a45',
  COLOR_E6EDF8: '#e6edf8',
  COLOR_E7E0E0: '#e7e0e0',
  COLOR_E7E9F2: '#e7e9f2',
  COLOR_E7EEFA: '#e7eefa',
  COLOR_E9586C: '#e9586c',
  COLOR_EA6077: '#ea6077',
  COLOR_EB5B57: '#eb5b57',
  COLOR_EB6D7B: '#eb6d7b',
  COLOR_EB740C: '#eb740c',
  COLOR_EBECED: '#ebeced',
  COLOR_ECB535: '#ecb535',
  COLOR_ECECEC: '#ececec',
  COLOR_EDF2F9: '#edf2f9',
  COLOR_EEEEEE: '#eeeeee',
  COLOR_EFEFEF: '#efefef',
  COLOR_F0F1F3: '#f0f1f3',
  COLOR_F0635E: '#f0635e',
  COLOR_F0F5FC: '#f0f5fc',
  COLOR_F2F2F2: '#f2f2f2',
  COLOR_F2F3F7: '#f2f3f7',
  COLOR_F39B4E: '#f39b4e',
  COLOR_F3F3F3: '#f3f3f3',
  COLOR_F4F4F4: '#f4f4f4',
  COLOR_F4F7FB: '#f4f7fb',
  COLOR_F58CA1: '#f58ca1',
  COLOR_F59CAE: '#f59cae',
  COLOR_F5E5E9: '#f5e5e9',
  COLOR_F66E73: '#f66e73',
  COLOR_F67A7F: '#f67a7f',
  COLOR_F6F1E8: '#f6f1e8',
  COLOR_F6F8FF: '#f6f8ff',
  COLOR_F7F8FA: '#f7f8fa',
  COLOR_F8AFBD: '#f8afbd',
  COLOR_F8BAC6: '#f8bac6',
  COLOR_F8F7F3: '#f8f7f3',
  COLOR_F8F8F8: '#f8f8f8',
  COLOR_F9D0D6: '#f9d0d6',
  COLOR_F9D6B9: '#f9d6b9',
  COLOR_F9F9F9: '#f9f9f9',
  COLOR_FAF0F0: '#faf0f0',
  COLOR_FBEBEB: '#fbebeb',
  COLOR_FCEAC3: '#fceac3',
  COLOR_FCECF0: '#fcecf0',
  COLOR_FCFCFC: '#fcfcfc',
  COLOR_FDECEE: '#fdecee',
  COLOR_FEF4EC: '#fef4ec',
  COLOR_FF6077: '#ff6077',
  COLOR_FFADAF: '#ffadaf',
  COLOR_FFE2D9: '#ffe2d9',
  COLOR_FFEEE2: '#ffeee2',
  COLOR_FFF2F5: '#fff2f5',
  COLOR_FFF8F9: '#fff8f9',
  COLOR_FFF9F9: '#fff9f9',
  COLOR_FFFDED: '#fffdfd',
  COLOR_FFFFFF: '#ffffff',
} as const

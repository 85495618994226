import { krtIdentifyUser } from '@/components/common/karte/karteTagProvider'
import { CoupleUserContextProvider, useCoupleUserFactory } from '@/context/cs/session'
import { CoupleSessionControllerApi } from '@/data/gen'
import { defaultConfiguration, invoke } from '@/data/rpc'
import React, { useEffect } from 'react'

type Props = {
  children: React.ReactNode
}
/**
 * Coupleの認証状態を確認して、認証状態をContextに保存します。
 * 配下のComponentでログイン状態による制御を行う際はContextから取得してださい。
 *
 * ※Contextがないことによるエラーを防ぐため、
 * CS配下のページ/レイアウトを作成の際はルートに本コンポーネントを設定してください。
 */
export const Authentication = (props: Props) => {
  const { children } = props
  const ctx = useCoupleUserFactory()
  useEffect(() => {
    if (ctx.user) {
      // 再来訪時にもユーザーが識別されるよう明示的にIDを送付する
      krtIdentifyUser({ user_id: ctx.user.coupleUUID })
      return
    }
    ;(async () => {
      const api = new CoupleSessionControllerApi(defaultConfiguration())
      const response = await invoke(() => api.checkCoupleSession())
      if (response.success) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2345 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
        ctx.setAuthState({ coupleUUID: response.success.coupleUUID }, null)
        krtIdentifyUser({ user_id: response.success.coupleUUID })
        return
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TS2345 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
      ctx.setAuthState(null, response.error)
    })()
  }, [])
  // 結果に関係なくセッションの確認処理が終わってから子コンポーネントの処理を行うためuser,errorの成否チェックを行う
  return (
    <CoupleUserContextProvider {...ctx}>
      {(ctx.user || ctx.error) && children}
    </CoupleUserContextProvider>
  )
}
